import React, { useState, useEffect } from "react";
import { useParams, useNavigate } from "react-router-dom";

const EditSanPham = () => {
  const { id } = useParams(); // Lấy id từ URL
  const navigate = useNavigate(); // Dùng để chuyển hướng sau khi cập nhật

  const [formData, setFormData] = useState({
    ten_sp: "",
    gia_sp: "",
    giaSale: "",
    moTa: "",
    id_loaisp: "",
    nsx: "", // Giữ nguyên name="nsx"
    anHien: "",
    soLuong: "",
    loai_go: "",
    kich_thuoc: "",
    mau_sac: "",
    bao_hanh: "",
    luotXem: 0, // Lượt xem không cho chỉnh sửa
    danhgia: "",
    binhluan: "",
    luot_mua: 0, // Lượt mua không cho chỉnh sửa
    thong_tin: "",
    hot: "",
    hinh: null, // Hình ảnh chính
    hinh_1: null, // Hình ảnh 1
    hinh_2: null, // Hình ảnh 2
    hinhcu: "",
    hinhcu_1: "",
    hinhcu_2: "",
  });

  const [loading, setLoading] = useState(true);
  const [categories, setCategories] = useState([]); // Danh sách loại sản phẩm
  const [nhasanxuat, setNsx] = useState([]); // Danh sách nhà sản xuất
  const [product, setProduct] = useState(null); // Dữ liệu sản phẩm

  // Lấy thông tin sản phẩm và loại sản phẩm từ API khi load trang
  useEffect(() => {
    const fetchProductDetails = async () => {
      const authToken = sessionStorage.getItem("authToken"); // Lấy token từ sessionStorage
      if (!authToken) {
        console.error("No auth token found");
        return;
      }

      try {
        const productResponse = await fetch(
          `https://ducps34770.id.vn/api/admin/sanpham/${id}`,
          {
            method: "GET",
            headers: {
              "Content-Type": "application/json",
              Authorization: `Bearer ${authToken}`, // Thêm token vào header
            },
          }
        );
        const productData = await productResponse.json();

        const categoriesResponse = await fetch(
          "https://ducps34770.id.vn/api/admin/danhmuc",
          {
            method: "GET",
            headers: {
              "Content-Type": "application/json",
              Authorization: `Bearer ${authToken}`, // Thêm token vào header
            },
          }
        );
        const categoriesData = await categoriesResponse.json();

        const nsxResponse = await fetch(
          "https://ducps34770.id.vn/api/admin/nhasanxuat",
          {
            method: "GET",
            headers: {
              "Content-Type": "application/json",
              Authorization: `Bearer ${authToken}`, // Thêm token vào header
            },
          }
        );
        const nsxData = await nsxResponse.json();

        if (productData) {
          setProduct(productData);
          setFormData({
            ...productData,
            hinh: productData.hinh || "",
            hinh_1: productData.hinh_1 || "",
            hinh_2: productData.hinh_2 || "",
            nsx: productData.id_nsx || "", // Đảm bảo id_nsx có giá trị mặc định
            anHien: productData.anHien !== null ? productData.anHien : 1, // Gán giá trị mặc định nếu anHien là null
          });
        } else {
          console.error("Dữ liệu sản phẩm không hợp lệ");
        }

        setCategories(categoriesData);
        setNsx(nsxData);
        setLoading(false);
      } catch (error) {
        console.error("Error fetching product details:", error);
        setLoading(false);
      }
    };

    fetchProductDetails();
  }, [id]);

  const handleChange = (e) => {
    const { name, value, type, files } = e.target;

    if (type === "file") {
      const file = files[0];
      if (file) {
        setFormData((prevData) => ({
          ...prevData,
          [name]: file, // Nếu có file mới, lưu trữ file
        }));
      }
    } else {
      setFormData((prevData) => ({
        ...prevData,
        [name]: value, // Cập nhật các trường dữ liệu khác
      }));
    }
  };

  const handleSubmit = async (e) => {
    e.preventDefault();

    const authToken = sessionStorage.getItem("authToken"); // Lấy token từ sessionStorage

    // Khởi tạo FormData
    const formDataToSend = new FormData();

    // Duyệt qua formData và thêm các trường vào FormData
    Object.keys(formData).forEach((key) => {
      if (key === "hot") {
        if (formData.hot === "") {
          // Nếu giá trị rỗng, giữ lại giá trị cũ từ product.hot
          formDataToSend.append("hot", product.hot || 0);
        } else {
          formDataToSend.append("hot", formData.hot);
        }
      } else {
        formDataToSend.append(key, formData[key]);
      }
    });

    Object.keys(formData).forEach((key) => {
      if (key === "luotXem" || key === "luot_mua") {
        formDataToSend.append(key, formData[key] !== null ? formData[key] : 0); // Đảm bảo giá trị là số
      } else {
        formDataToSend.append(key, formData[key] || "");
      }
    });

    // Thêm _method để giả lập PUT
    formDataToSend.append("_method", "PUT");

    // Gửi dữ liệu lên API
    try {
      const response = await fetch(
        `https://ducps34770.id.vn/api/admin/sanpham/${id}`,
        {
          method: "PUT",
          headers: {
            Authorization: `Bearer ${authToken}`, // Thêm token vào header
          },
          body: formDataToSend,
        }
      );

      if (response.ok) {
        alert("Sản phẩm đã được cập nhật!");
        navigate(`/chitietadmin/${id}`);
      } else {
        alert("Cập nhật sản phẩm thất bại!");
      }
    } catch (error) {
      console.error("Error updating product:", error);
      alert("Có lỗi xảy ra!");
    }
  };

  if (loading) {
    return <div>Loading...</div>;
  }

  return (
    <form className="col-md-10 p-4" onSubmit={handleSubmit}>
      <div className="comment">
        <div className="row mb-4">
          <div className="container mt-4">
            <div className="card shadow" style={{ borderRadius: "10px" }}>
              <div
                className="card-header text-white"
                style={{
                  backgroundColor: "#ffcc00",
                  borderTopLeftRadius: "10px",
                  borderTopRightRadius: "10px",
                }}
              >
                <h4 className="font-weight-bold mb-0">Chỉnh sửa sản phẩm</h4>
              </div>
              <div className="card-body">
                <div className="row">
                  {/* Left Column */}
                  <div className="col-md-6">
                    <div className="mb-3">
                      <label htmlFor="productName" className="form-label">
                        Tên sản phẩm
                      </label>
                      <input
                        type="text"
                        className="form-control"
                        id="productName"
                        name="ten_sp"
                        value={formData.ten_sp}
                        required
                        onChange={handleChange}
                      />
                    </div>
                    <div className="mb-3">
                      <label htmlFor="price" className="form-label">
                        Giá
                      </label>
                      <input
                        type="text"
                        className="form-control"
                        id="price"
                        name="gia_sp"
                        value={formData.gia_sp}
                        required
                        onChange={handleChange}
                      />
                    </div>
                    <div className="mb-3">
                      <label htmlFor="woodType" className="form-label">
                        Loại gỗ
                      </label>
                      <input
                        type="text"
                        className="form-control"
                        id="woodType"
                        name="loai_go"
                        value={formData.loai_go}
                        required
                        onChange={handleChange}
                      />
                    </div>
                    <div className="mb-3">
                      <label htmlFor="color" className="form-label">
                        Màu sắc
                      </label>
                      <input
                        type="text"
                        className="form-control"
                        id="color"
                        name="mau_sac"
                        value={formData.mau_sac}
                        required
                        onChange={handleChange}
                      />
                    </div>
                    <div className="mb-3">
                      <label htmlFor="description" className="form-label">
                        Mô tả
                      </label>
                      <textarea
                        className="form-control"
                        id="description"
                        name="moTa"
                        rows="3"
                        value={formData.moTa}
                        required
                        onChange={handleChange}
                      ></textarea>
                    </div>
                    <div className="mb-3">
                      <label htmlFor="additionalInfo" className="form-label">
                        Thông tin
                      </label>
                      <textarea
                        className="form-control"
                        id="additionalInfo"
                        name="thong_tin"
                        rows="3"
                        value={formData.thong_tin}
                        onChange={handleChange}
                      ></textarea>
                    </div>

                    <div className="mb-3">
                      <label htmlFor="manufacturer" className="form-label">
                        Chọn nhà sản xuất
                      </label>
                      <select
                        className="form-control"
                        id="manufacturer"
                        name="nsx"
                        value={formData.nsx} // Hiển thị nhà sản xuất đã chọn
                        required
                        onChange={handleChange}
                      >
                        <option value="" disabled>
                          Chọn nhà sản xuất
                        </option>
                        {nhasanxuat.map((nhasanxuat) => (
                          <option
                            key={nhasanxuat.id_nsx}
                            value={nhasanxuat.id_nsx}
                          >
                            {nhasanxuat.ten_nsx}
                          </option>
                        ))}
                      </select>
                    </div>

                    <div className="mb-3">
                      <label htmlFor="status" className="form-label">
                        Trạng thái sản phẩm
                      </label>
                      <select
                        className="form-select"
                        id="status"
                        name="anHien"
                        value={formData.anHien}
                        onChange={handleChange}
                      >
                        <option value="1">Hiện sản phẩm</option>
                        <option value="0">Ẩn sản phẩm</option>
                      </select>
                    </div>
                  </div>

                  {/* Right Column */}
                  <div className="col-md-6">
                    <div className="mb-3">
                      <label htmlFor="stock" className="form-label">
                        Số lượng
                      </label>
                      <input
                        type="number"
                        className="form-control"
                        id="stock"
                        name="soLuong"
                        value={formData.soLuong}
                        required
                        onChange={handleChange}
                      />
                    </div>

                    <div className="mb-3">
                      <label htmlFor="warranty" className="form-label">
                        Bảo hành
                      </label>
                      <input
                        type="text"
                        className="form-control"
                        id="warranty"
                        name="bao_hanh"
                        value={formData.bao_hanh}
                        required
                        onChange={handleChange}
                      />
                    </div>

                    <div className="mb-3">
                      <label htmlFor="size" className="form-label">
                        Kích thước
                      </label>
                      <input
                        type="text"
                        className="form-control"
                        id="size"
                        name="kich_thuoc"
                        value={formData.kich_thuoc}
                        required
                        onChange={handleChange}
                      />
                    </div>

                    <div className="mb-3">
                      <label htmlFor="image" className="form-label">
                        Hình ảnh
                      </label>
                      {formData.hinh && !formData.hinh_url && (
                        <div>
                          <img
                            src={`http://127.0.0.1:8000${formData.hinh}`} // Hiển thị ảnh cũ từ backend
                            alt="Current Image"
                            style={{
                              width: "100px",
                              height: "100px",
                              objectFit: "cover",
                              marginBottom: "10px",
                            }}
                          />
                          <br />
                        </div>
                      )}
                      {formData.hinh_url && (
                        <div>
                          <img
                            src={formData.hinh_url} // Hiển thị ảnh mới với Blob URL tạm thời
                            alt="New Image"
                            style={{
                              width: "100px",
                              height: "100px",
                              objectFit: "cover",
                              marginBottom: "10px",
                            }}
                          />
                          <br />
                        </div>
                      )}
                      <input
                        type="file"
                        className="form-control"
                        id="image"
                        name="hinh"
                        accept="image/*"
                        onChange={handleChange}
                      />
                    </div>

                    <div className="mb-3">
                      <label htmlFor="image1" className="form-label">
                        Hình ảnh 1
                      </label>
                      {formData.hinh_1 && !formData.hinh_1_url && (
                        <div>
                          <img
                            src={`http://127.0.0.1:8000${formData.hinh_1}`} // Hiển thị ảnh cũ từ backend
                            alt="Current Image 1"
                            style={{
                              width: "100px",
                              height: "100px",
                              objectFit: "cover",
                              marginBottom: "10px",
                            }}
                          />
                          <br />
                        </div>
                      )}
                      {formData.hinh_1_url && (
                        <div>
                          <img
                            src={formData.hinh_1_url} // Hiển thị ảnh mới với Blob URL tạm thời
                            alt="New Image 1"
                            style={{
                              width: "100px",
                              height: "100px",
                              objectFit: "cover",
                              marginBottom: "10px",
                            }}
                          />
                          <br />
                        </div>
                      )}
                      <input
                        type="file"
                        className="form-control"
                        id="image1"
                        name="hinh_1"
                        accept="image/*"
                        onChange={handleChange}
                      />
                    </div>

                    <div className="mb-3">
                      <label htmlFor="image2" className="form-label">
                        Hình ảnh 2
                      </label>
                      {formData.hinh_2 && !formData.hinh_2_url && (
                        <div>
                          <img
                            src={`http://127.0.0.1:8000${formData.hinh_2}`} // Hiển thị ảnh cũ từ backend
                            alt="Current Image 2"
                            style={{
                              width: "100px",
                              height: "100px",
                              objectFit: "cover",
                              marginBottom: "10px",
                            }}
                          />
                          <br />
                        </div>
                      )}
                      {formData.hinh_2_url && (
                        <div>
                          <img
                            src={formData.hinh_2_url} // Hiển thị ảnh mới với Blob URL tạm thời
                            alt="New Image 2"
                            style={{
                              width: "100px",
                              height: "100px",
                              objectFit: "cover",
                              marginBottom: "10px",
                            }}
                          />
                          <br />
                        </div>
                      )}
                      <input
                        type="file"
                        className="form-control"
                        id="image2"
                        name="hinh_2"
                        accept="image/*"
                        onChange={handleChange}
                      />
                    </div>

                    <div className="mb-3">
                      <label htmlFor="productType" className="form-label">
                        Chọn loại sản phẩm
                      </label>
                      <select
                        className="form-select"
                        id="productType"
                        name="id_loaisp"
                        value={formData.id_loaisp}
                        required
                        onChange={handleChange}
                      >
                        {categories.map((category) => (
                          <option
                            key={category.id_loaisp}
                            value={category.id_loaisp}
                          >
                            {category.loai}
                          </option>
                        ))}
                      </select>
                    </div>
                    <div className="mb-3">
                      <label htmlFor="productType" className="form-label">
                        Có Hot không ?
                      </label>
                      <select
                        className="form-select"
                        id="productHot"
                        name="hot"
                        value={
                          formData.hot !== "" ? formData.hot : product.hot || 0
                        }
                        required
                        onChange={handleChange}
                      >
                        <option value="0">Hot</option>
                        <option value="1">Không</option>
                      </select>
                    </div>
                  </div>
                </div>

                <button
                  type="submit"
                  className="btn btn-warning text-white"
                  style={{ width: "150px" }}
                >
                  Cập nhật
                </button>
              </div>
            </div>
          </div>
        </div>
      </div>
    </form>
  );
};

export default EditSanPham;

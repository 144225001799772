import React, { useState, useEffect } from "react";
import { useNavigate, useParams } from "react-router-dom";
import "bootstrap/dist/css/bootstrap.min.css";
import "bootstrap-icons/font/bootstrap-icons.css";
import "bootstrap/dist/js/bootstrap.bundle.min.js";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";

const EditBaiViet = () => {
  const { id_bv } = useParams(); // Lấy id từ params
  const [postTitle, setPostTitle] = useState("");
  const [author, setAuthor] = useState("");
  const [postDate, setPostDate] = useState("");
  const [postContent, setPostContent] = useState("");
  const [category, setCategory] = useState("SEO");
  const [postImage, setPostImage] = useState(null);
  const [isLoading, setIsLoading] = useState(true); // Thêm state loading
  const navigate = useNavigate();

  useEffect(() => {
    // Fetch bài viết từ API theo id
    const fetchPostData = async () => {
      try {
        const response = await fetch(
          `https://ducps34770.id.vn/api/admin/baiviet/${id_bv}`
        );
        const data = await response.json();

        if (data) {
          setPostTitle(data.tieu_de);
          setAuthor(data.tac_gia);
          setPostDate(data.ngay_dang);
          setPostContent(data.noi_dung);
          setCategory(data.danh_muc);
          setPostImage(data.hinh); // Lưu tên hình ảnh nếu có
          setIsLoading(false); // Đánh dấu là đã tải xong dữ liệu
        } else {
          toast.error("Không tìm thấy bài viết.");
          setIsLoading(false);
        }
      } catch (error) {
        toast.error(`Lỗi: ${error.message}`);
        setIsLoading(false);
      }
    };

    fetchPostData();
  }, [id_bv]); // Chạy lại khi id thay đổi

  const handleEditPost = async (e) => {
    e.preventDefault();

    const userInfo = JSON.parse(sessionStorage.getItem("userInfo"));
    console.log("User Info:", userInfo); // Kiểm tra đối tượng userInfo

    if (!userInfo || !userInfo.id) {
      toast.error(
        "Không tìm thấy thông tin người dùng. Vui lòng đăng nhập lại."
      );
      return;
    }

    if (postImage) {
      const reader = new FileReader();
      reader.readAsDataURL(postImage);
      reader.onloadend = async () => {
        const postData = {
          tieu_de: postTitle,
          tac_gia: author,
          ngay_dang: postDate,
          noi_dung: postContent,
          id_nd: userInfo.id,
          if(postImage) {
            postData.append("hinh", postImage); // Gửi hình ảnh dưới dạng file
          },
        };

        console.log("Post Data:", postData); // Kiểm tra dữ liệu gửi đi

        try {
          const response = await fetch(
            `https://ducps34770.id.vn/api/admin/baiviet/${id_bv}`,
            {
              method: "PUT",
              headers: {
                "Content-Type": "application/json",
              },
              body: JSON.stringify(postData), // Gửi dữ liệu dưới dạng JSON
            }
          );

          if (response.ok) {
            toast.success("Bài viết đã được cập nhật thành công!");
            navigate("/BaiViet");
          } else {
            throw new Error("Lỗi khi cập nhật bài viết, vui lòng thử lại.");
          }
        } catch (error) {
          toast.error(`Lỗi: ${error.message}`);
        }
      };
    } else {
      // Nếu không có hình ảnh mới, chỉ gửi các trường dữ liệu khác
      const postData = {
        tieu_de: postTitle,
        tac_gia: author,
        ngay_dang: postDate,
        noi_dung: postContent,
        id_nd: userInfo.id,
        // Không gửi trường hinh nếu không có ảnh mới
      };

      try {
        const response = await fetch(
          `https://ducps34770.id.vn/api/admin/baiviet/${id_bv}`,
          {
            method: "PUT",
            headers: {
              "Content-Type": "application/json",
            },
            body: JSON.stringify(postData), // Gửi dữ liệu dưới dạng JSON
          }
        );

        if (response.ok) {
          toast.success("Bài viết đã được cập nhật thành công!");
          navigate("/BaiViet");
        } else {
          throw new Error("Lỗi khi cập nhật bài viết, vui lòng thử lại.");
        }
      } catch (error) {
        toast.error(`Lỗi: ${error.message}`);
      }
    }
  };

  if (isLoading) {
    return <div>Đang tải...</div>; // Hiển thị khi dữ liệu chưa được tải
  }

  return (
    <div className="col-md-10">
      <div className="ol-md-10 p-4">
        <div className="row mb-4">
          <div className="container mt-4">
            <div className="card shadow" style={{ borderRadius: "10px" }}>
              <div
                className="card-header text-white"
                style={{
                  backgroundColor: "#ffcc00",
                  borderTopLeftRadius: "10px",
                  borderTopRightRadius: "10px",
                }}
              >
                <h4 className="font-weight-bold mb-0">Chỉnh Sửa Bài Viết</h4>
              </div>
              <div className="card-body">
                <form onSubmit={handleEditPost}>
                  <div className="mb-3">
                    <label htmlFor="postTitle" className="form-label">
                      Tiêu Đề Bài Viết
                    </label>
                    <input
                      type="text"
                      className="form-control"
                      id="postTitle"
                      value={postTitle}
                      onChange={(e) => setPostTitle(e.target.value)}
                      placeholder="Nhập tiêu đề bài viết"
                      required
                    />
                  </div>

                  <div className="mb-3">
                    <label htmlFor="author" className="form-label">
                      Tác Giả
                    </label>
                    <input
                      type="text"
                      className="form-control"
                      id="author"
                      value={author}
                      onChange={(e) => setAuthor(e.target.value)}
                      placeholder="Nhập tên tác giả"
                      required
                    />
                  </div>

                  <div className="mb-3">
                    <label htmlFor="postDate" className="form-label">
                      Ngày Đăng
                    </label>
                    <input
                      type="date"
                      className="form-control"
                      id="postDate"
                      value={postDate}
                      onChange={(e) => setPostDate(e.target.value)}
                      required
                    />
                  </div>

                  <div className="mb-3">
                    <label htmlFor="postContent" className="form-label">
                      Nội Dung Bài Viết
                    </label>
                    <textarea
                      className="form-control"
                      id="postContent"
                      value={postContent}
                      onChange={(e) => setPostContent(e.target.value)}
                      rows="5"
                      placeholder="Nhập nội dung bài viết"
                      required
                    ></textarea>
                  </div>

                  <div className="mb-3">
                    <label htmlFor="category" className="form-label">
                      Danh Mục
                    </label>
                    <select
                      className="form-select"
                      id="category"
                      value={category}
                      onChange={(e) => setCategory(e.target.value)}
                    >
                      <option value="SEO">SEO</option>
                      <option value="Angular">Angular</option>
                      <option value="React">React</option>
                      <option value="Khác">Khác</option>
                    </select>
                  </div>

                  <div className="mb-3">
                    <label htmlFor="postImage" className="form-label">
                      Hình Ảnh Bài Viết
                    </label>
                    <input
                      type="file"
                      className="form-control"
                      id="postImage"
                      onChange={(e) => setPostImage(e.target.files[0])}
                    />
                    {postImage && <p>{postImage.name}</p>}
                    {!postImage && postImage !== null && (
                      <p>Không có hình ảnh</p>
                    )}
                  </div>

                  <button
                    type="submit"
                    className="btn btn-warning"
                    style={{ width: "100%" }}
                  >
                    Cập Nhật Bài Viết
                  </button>
                </form>
              </div>
            </div>
          </div>
        </div>
      </div>

      <ToastContainer />
    </div>
  );
};

export default EditBaiViet;

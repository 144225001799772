import React, { useState, useEffect } from "react";
import { Link } from "react-router-dom";
import "../../assets/css/style.css";

const QuanlyNSX = () => {
  const [manufacturers, setManufacturers] = useState([]);
  const [loading, setLoading] = useState(true);
  const [searchQuery, setSearchQuery] = useState("");
  const [showDeleteModal, setShowDeleteModal] = useState(false);
  const [manufacturerToDelete, setManufacturerToDelete] = useState(null);

  // Lấy dữ liệu nhà sản xuất từ API
  useEffect(() => {
    const fetchManufacturers = async () => {
      try {
        const response = await fetch(
          "https://ducps34770.id.vn/api/admin/nhasanxuat",
          {
            headers: {
              Authorization: `Bearer ${sessionStorage.getItem("authToken")}`, // Thêm token vào header
            },
          }
        );
        const data = await response.json();
        if (Array.isArray(data)) {
          setManufacturers(data);
        } else if (Array.isArray(data.data)) {
          setManufacturers(data.data);
        } else {
          console.error("Định dạng dữ liệu không mong đợi:", data);
        }
      } catch (error) {
        console.error("Lỗi khi lấy nhà sản xuất:", error);
      } finally {
        setLoading(false);
      }
    };
    fetchManufacturers();
  }, []);

  // Hàm xác nhận xóa nhà sản xuất
  const confirmDelete = async () => {
    const { id_nsx, ten_nsx } = manufacturerToDelete;
    try {
      const response = await fetch(
        `https://ducps34770.id.vn/api/admin/nhasanxuat/${id_nsx}`,
        {
          method: "DELETE",
          headers: {
            "Content-Type": "application/json",
            Authorization: `Bearer ${sessionStorage.getItem("authToken")}`, // Thêm token vào header
          },
        }
      );
      if (response.ok) {
        setManufacturers(manufacturers.filter((m) => m.id_nsx !== id_nsx));
        alert(`${ten_nsx} đã được xóa.`);
        setShowDeleteModal(false);
      } else {
        alert("Xóa không thành công.");
      }
    } catch (error) {
      console.error("Lỗi khi xóa nhà sản xuất:", error);
    }
  };

  // Lọc nhà sản xuất dựa trên truy vấn tìm kiếm
  const filteredManufacturers = manufacturers.filter((manufacturer) =>
    manufacturer.ten_nsx.toLowerCase().includes(searchQuery.toLowerCase())
  );

  if (loading) return <p>Đang tải...</p>;

  return (
    <div className="col-md-10 p-4">
      <div className="card">
        <div className="card-header d-flex justify-content-between align-items-center">
          <h5>Nhà sản xuất</h5>
          <Link to="/AddNhasanxuat">
            <button className="btn btn-primary">Thêm nhà sản xuất mới</button>
          </Link>
        </div>
        <div className="card-body">
          <div className="card-header d-flex justify-content-between align-items-center mb-3">
            <h6>
              Tổng số nhà sản xuất hiện có:{" "}
              <span id="totalManufacturers">
                {filteredManufacturers.length}
              </span>
            </h6>
            <div className="col-lg-4 col-md-6 d-flex">
              <input
                type="text"
                className="form-control"
                placeholder="Tìm kiếm"
                value={searchQuery}
                onChange={(e) => setSearchQuery(e.target.value)}
              />
              <button className="btn btn-primary ms-2">Tìm</button>
            </div>
          </div>
          <div className="comment-container">
            <table className="table table-striped">
              <thead>
                <tr>
                  <th>ID</th>
                  <th>Tên nhà sản xuất</th>
                  <th>Trạng Thái</th>
                  <th>Hành động</th>
                </tr>
              </thead>
              <tbody>
                {filteredManufacturers.map((manufacturer) => (
                  <tr key={manufacturer.id_nsx}>
                    <td>{manufacturer.id_nsx}</td>
                    <td>{manufacturer.ten_nsx}</td>
                    <td>{manufacturer.anHien === 0 ? "Ẩn" : "Hiện"}</td>
                    <td>
                      <Link to={`/EditNSX/${manufacturer.id_nsx}`}>
                        <button className="btn btn-warning btn-sm">
                          Chỉnh sửa
                        </button>
                      </Link>
                      <button
                        className="btn btn-danger btn-sm"
                        onClick={() => {
                          setManufacturerToDelete(manufacturer); // Lưu trữ nhà sản xuất sẽ bị xóa
                          setShowDeleteModal(true); // Hiển thị modal
                        }}
                      >
                        Xóa
                      </button>
                    </td>
                  </tr>
                ))}
              </tbody>
            </table>
          </div>
        </div>
      </div>

      {/* Modal Xác Nhận Xóa */}
      {showDeleteModal && (
        <div className="modal show" tabIndex="-1" style={{ display: "block" }}>
          <div className="modal-dialog modal-dialog-centered">
            <div className="modal-content">
              <div className="modal-header">
                <h5 className="modal-title">Xác nhận xóa</h5>
                <button
                  type="button"
                  className="btn-close"
                  onClick={() => setShowDeleteModal(false)}
                ></button>
              </div>
              <div className="modal-body">
                <p>
                  Bạn có chắc chắn muốn xóa nhà sản xuất{" "}
                  <strong>{manufacturerToDelete?.ten_nsx}</strong>?
                </p>
              </div>
              <div className="modal-footer">
                <button
                  type="button"
                  className="btn btn-secondary"
                  onClick={() => setShowDeleteModal(false)}
                >
                  Hủy
                </button>
                <button
                  type="button"
                  className="btn btn-danger"
                  onClick={confirmDelete}
                >
                  Xóa
                </button>
              </div>
            </div>
          </div>
        </div>
      )}
    </div>
  );
};

export default QuanlyNSX;

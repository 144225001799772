
// File: src/App.js

import React from 'react';
import { BrowserRouter as Router, Routes, Route, useLocation, Link } from 'react-router-dom';
import Navbar from './components/Nabar/Navbar'; // Corrected from Nabar to Navbar
import Sidebar from './components/Sidebar/Sidebar';
import Dashboard from './components/Dashboard/Dashboard';
import Cart from './components/Cart/Cart';
import ProductList from './components/Sanpham/ProductsList';
import UserDashboard from './components/User/UserDashboard';
import Comment from './components/Comment/Comment';
import Voucher from './components/Vouchers/Voucher';
import Baiviet from './components/Baiviet/Baiviet';
import AdminDashboard from './components/Thongke/AdminDashboard';
import UserAdmin from './components/Bestadmin/UserAdmin';
import PhanHoi from "./components/PhanHoi/PhanHoi"; 
// Trang thêm
import AdminLogin from './components/Login/AdminLogin';
import AddUsers from './components/Add/Add_Nguoidung';
import AddDanhMuc from './components/Add/Add_Danhmuc';
import AddVoucher from './components/Add/Add_voucher';
import AddBaiViet from './components/Add/Add_BaiViet';
import AddSanPham from './components/Add/Add_SanPham';
import AddNhasanxuat from './components/Add/Add_Nhasanxuat';
import AddAdmin from './components/Add/Add_Admin';
// Trang chỉnh sửa 
import EditBaiviet from './components/Edit/Edit_baiviet';
import EditUser from './components/Edit/Edit_Best';
import EditVoucher from './components/Edit/Edit_voucher';
import EditDonHang from './components/Edit/Edit_Donhang';
import EditDanhMuc from './components/Edit/Edit_DanhMuc';
import EditNSX from './components/Edit/Edit_NSX'; // Trang chỉnh sửa nhà sản xuất
import EditSanPham from './components/Edit/Edit_sanpham';
import EditND from './components/Edit/Edit_Nguoidung';
// Trang xóa
import DeleteBaiViet from './components/Delete/Delete_baiviet';
import DeleteSanPham from './components/Delete/Delete_sanpham';
import DeleteKhachHang from './components/Delete/Delete_user';
import DeleteVoucher from './components/Delete/delete_voucher';
import DeleteBest from './components/Delete/Detele_Best';

// Xem chi tiết
import ChiTietAdmin from './components/Detail/ChitietAdmin';
import Chitietkhachhang from './components/Detail/Chitietkhachhang';
import Chitietdonhang from './components/Detail/Chitietdonhang';

// Khóa tài khoản
import KeyKhoa from './components/User/Key_Taikhoan';

// Quản lý danh mục 
import QuanlyDM from './components/Quanly_Danhmuc/QuanlyDM';
import QuanlyNSX from './components/Quanly_NSX/QuanlyNSX';

const OffcanvasSidebar = () => (
  <div>
    <button className="btn btn-dark d-md-none" type="button" data-bs-toggle="offcanvas" data-bs-target="#sidebarOffcanvas" aria-controls="sidebarOffcanvas">
      <i className="fa-solid fa-bars"></i> Menu
    </button>

    <div className="offcanvas offcanvas-start bg-dark text-white" tabIndex="-1" id="sidebarOffcanvas">
      <div className="offcanvas-header">
        <h5 className="offcanvas-title">Menu</h5>
        <button type="button" className="btn-close text-reset btn-close-white" data-bs-dismiss="offcanvas" aria-label="Close"></button>
      </div>
      <div className="offcanvas-body">
        <ul className="nav flex-column mb-auto">
          <li>
            <Link to="/dashboard" className="nav-link">
              <i className="fa-brands fa-squarespace"></i> Bảng Điều Khiển
            </Link>
          </li>
          <li>
            <Link to="/cart" className="nav-link">
              <i className="fa-solid fa-truck-fast"></i> Đơn Hàng
            </Link>
          </li>
          <li>
            <Link to="/productslist" className="nav-link">
              <i className="fa-brands fa-shopify"></i> Sản Phẩm
            </Link>
          </li>
          <li>
            <Link to="/userdashboard" className="nav-link">
              <i className="fa-solid fa-user-group"></i> Khách Hàng
            </Link>
          </li>
          <li>
            <Link to="/comment" className="nav-link">
              <i className="fa-solid fa-comment"></i> Bình Luận
            </Link>
          </li>
          <li>
            <Link to="/voucher" className="nav-link">
              <i className="fa-solid fa-ticket"></i> Voucher
            </Link>
          </li>
          <li>
            <Link to="/baiviet" className="nav-link">
              <i className="fa-solid fa-newspaper"></i> Bài viết
            </Link>
          </li>
          <li>
            <Link to="/admindashboard" className="nav-link">
              <i className="fa-brands fa-slack"></i> Thống Kê Doanh Thu
            </Link>
          </li>
          <li>
            <Link to="/quanlydm" className="nav-link">
              <i className="fa-solid fa-window-restore"></i> Quản lý danh mục
            </Link>
          </li>
          <li>
            <Link to="/quanlynsx" className="nav-link">
              <i className="fa-solid fa-city"></i> Quản lý nhà sản xuất
            </Link>
          </li>
          <li>
            <Link to="/PhanHoi" className="nav-link">
              <i className="fa-solid fa-city"></i> Phản Hồi
            </Link>
          </li>
        </ul>
      </div>
    </div>
  </div>
);

function Layout() {
  const location = useLocation();
  const hideLayoutForLogin = location.pathname === '/adminlogin';

  return (
    <>
      {!hideLayoutForLogin && <Navbar />}
      <OffcanvasSidebar />
      <div className='container-fluid'>
        <div className='row'>
          {!hideLayoutForLogin && <Sidebar />}
          
          <Routes>
            <Route path="/dashboard" element={<Dashboard />} />
            <Route path="/cart" element={<Cart />} />
            <Route path="/productslist" element={<ProductList />} />
            <Route path="/userdashboard" element={<UserDashboard />} />
            <Route path="/comment" element={<Comment />} />
            <Route path="/voucher" element={<Voucher />} />
            <Route path="/baiviet" element={<Baiviet />} />
            <Route path="/admindashboard" element={<AdminDashboard />} />
            <Route path="/userAdmin" element={<UserAdmin />} />
            <Route path="/adminlogin" element={<AdminLogin />} />
            <Route path="/PhanHoi" element={<PhanHoi/>} />
            {/* Trang thêm */}
            <Route path="/addusers" element={<AddUsers />} />
            <Route path="/adddanhmuc" element={<AddDanhMuc />} />
            <Route path="/addvoucher" element={<AddVoucher />} />
            <Route path="/addbaiviet" element={<AddBaiViet />} />a
            <Route path="/addnhasanxuat" element={<AddNhasanxuat />} />
            <Route path="/addsanpham" element={<AddSanPham />} />
            <Route path="/addadmin" element={<AddAdmin />} />


            {/* Trang chỉnh sửa */}
            <Route path="/editbaiviet/:id_bv" element={<EditBaiviet />} />
            <Route path="/edituser/:userId" element={<EditUser  />} />
            <Route path="/vouchers/edit/:id_mgg" element={<EditVoucher />} />
            <Route path="/editdonhang/:id" element={<EditDonHang />} />
            <Route path="/editdanhmuc/:id_loaisp" element={<EditDanhMuc />} />
            <Route path="/editnsx/:id" element={<EditNSX />} /> {/* Thêm ID cho trang chỉnh sửa nhà sản xuất */}
            <Route path="/editsp/:id" element={<EditSanPham />} /> {/* Thêm ID cho trang chỉnh sửa nhà sản xuất */}
            <Route path="/Editnd/:id" element={<EditND />} />  {/* Chỉnh sửa người dùng */}


            {/* Trang xóa */}
            <Route path="/deletebaiviet" element={<DeleteBaiViet />} />
            <Route path="/deletesanpham" element={<DeleteSanPham />} />
            <Route path="/deletekhachhang" element={<DeleteKhachHang />} />
            <Route path="/deletevoucher" element={<DeleteVoucher />} />
            <Route path="/deletebest" element={<DeleteBest />} />
            
            {/* Xem chi tiết */}
            {/* <Route path="/chitietadmin" element={<ChiTietAdmin />} /> */}
            <Route path="/chitietkhachhang/:id" element={<Chitietkhachhang />} />
            <Route path="/chitietdonhang/:id_dh" element={<Chitietdonhang />} />

            <Route path="/chitietadmin/:id" element={<ChiTietAdmin />} />
            {/* Khóa tài khoản */}
            <Route path="/keykhoa/:userId" element={<KeyKhoa />} /> {/* Đảm bảo rằng userId được truyền qua URL */}

            
            {/* Quản lý Danh Mục - NSX */}
            <Route path="/quanlydm" element={<QuanlyDM />} />
            <Route path="/quanlynsx" element={<QuanlyNSX />} />
          </Routes>
        </div>
      </div>
    </>
  );
}

function App() {
  return (
    <Router>
      <Layout />
    </Router>
  );
}

export default App;

import React, { useState } from "react";
import { useNavigate } from "react-router-dom"; // Import useNavigate
import "bootstrap/dist/css/bootstrap.min.css";
import "../../assets/css/style.css";

const AddNhasanxuat = () => {
  const [order, setOrder] = useState("");
  const [manufacturerName, setManufacturerName] = useState("");
  const [anHien, setAnHien] = useState("1"); // Default to "Hiện"
  const navigate = useNavigate(); // Khởi tạo useNavigate

  const handleAdd = async (e) => {
    e.preventDefault();

    const newManufacturer = {
      thu_tu: parseInt(order), // Đổi tên thành thu_tu
      ten_nsx: manufacturerName,
      an_hien: parseInt(anHien), // Đổi tên thành an_hien
    };

    try {
      const response = await fetch(
        "https://ducps34770.id.vn/api/admin/nhasanxuat",
        {
          method: "POST",
          headers: {
            "Content-Type": "application/json",
            Authorization: `Bearer ${sessionStorage.getItem("authToken")}`, // Add token to headers
          },
          body: JSON.stringify(newManufacturer),
        }
      );

      if (response.ok) {
        alert("Thêm nhà sản xuất thành công!");

        // Chuyển hướng về trang quản lý nhà sản xuất sau khi thêm thành công
        navigate("/QuanlyNSX");
      } else {
        const errorData = await response.json();
        console.error("Lỗi từ API:", errorData);
        alert(
          `Thêm nhà sản xuất không thành công: ${
            errorData.message || "Có lỗi xảy ra."
          }`
        );
      }
    } catch (error) {
      console.error("Lỗi khi kết nối API:", error);
      alert("Đã xảy ra lỗi khi kết nối đến API. Vui lòng thử lại.");
    }
  };

  // Hàm xử lý khi nhấn nút đóng
  const handleClose = () => {
    navigate("/QuanlyNSX"); // Quay lại trang quản lý nhà sản xuất
  };

  return (
    <form className="col-md-10" onSubmit={handleAdd}>
      <div className="comment">
        <div className="row mb-4">
          <div className="container mt-4">
            <div className="card shadow" style={{ borderRadius: "10px" }}>
              <div
                className="card-header text-white"
                style={{
                  backgroundColor: "#ffcc00",
                  borderTopLeftRadius: "10px",
                  borderTopRightRadius: "10px",
                }}
              >
                <h4 className="font-weight-bold mb-0">Thêm nhà sản xuất</h4>
              </div>
              <div className="card-body">
                <div className="mb-3">
                  <input
                    type="number"
                    className="form-control"
                    placeholder="Nhập thứ tự"
                    value={order}
                    onChange={(e) => setOrder(e.target.value)}
                    required
                  />
                </div>
                <div className="mb-3">
                  <input
                    type="text"
                    className="form-control"
                    placeholder="Nhập tên nhà sản xuất"
                    value={manufacturerName}
                    onChange={(e) => setManufacturerName(e.target.value)}
                    required
                  />
                </div>
                <div className="mb-3">
                  <label className="form-label">Chọn trạng thái</label>
                  <select
                    className="form-select"
                    value={anHien}
                    onChange={(e) => setAnHien(e.target.value)}
                    required
                  >
                    <option value="0">Ẩn</option>
                    <option value="1">Hiện</option>
                  </select>
                </div>
                <div className="modal-footer">
                  <button
                    type="button"
                    className="btn btn-secondary"
                    onClick={handleClose}
                  >
                    Đóng
                  </button>
                  <button type="submit" className="btn btn-success">
                    Thêm
                  </button>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </form>
  );
};

export default AddNhasanxuat;

import React, { useState, useEffect } from "react";
import axios from "axios";

const EditDonHang = () => {
  const [productData, setProductData] = useState({
    productName: "",
    productPrice: "",
    productWoodType: "",
    productColor: "",
    productDescription: "",
    manufacturer: "",
    productStatus: "",
    productStock: "",
    productDiscount: "",
    productSize: "",
    productWarranty: "",
    productImage: null,
    productType: "",
    featuredProduct: "",
  });

  useEffect(() => {
    // Fetch product data here if needed
    // const fetchProductData = async () => {
    //   const response = await axios.get('API_URL', {
    //     headers: {
    //       "Authorization": `Bearer ${sessionStorage.getItem("authToken")}`,
    //     },
    //   });
    //   setProductData(response.data);
    // };
    // fetchProductData();
  }, []);

  const handleInputChange = (e) => {
    const { name, value, files } = e.target;
    if (name === "productImage" && files.length > 0) {
      setProductData({ ...productData, productImage: files[0] });
    } else {
      setProductData({ ...productData, [name]: value });
    }
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    const formData = new FormData();
    Object.keys(productData).forEach((key) => {
      formData.append(key, productData[key]);
    });

    try {
      const response = await axios.put(
        `https://ducps34770.id.vn/api/admin/products/${productData.id}`,
        formData,
        {
          headers: {
            Authorization: `Bearer ${sessionStorage.getItem("authToken")}`,
            "Content-Type": "multipart/form-data", // Nếu bạn gửi file
          },
        }
      );
      console.log("Product updated successfully:", response.data);
      // Navigate or show success message
    } catch (error) {
      console.error("Error updating product:", error.response?.data || error);
    }
  };

  return (
    <form className="col-md-10 p-4" id="editForm" onSubmit={handleSubmit}>
      <div className="comment">
        <div className="row mb-4">
          <div className="container mt-4">
            <div className="card shadow" style={{ borderRadius: "10px" }}>
              <div
                className="card-header text-white"
                style={{
                  backgroundColor: "#ffcc00",
                  borderTopLeftRadius: "10px",
                  borderTopRightRadius: "10px",
                }}
              >
                <h4 className="font-weight-bold mb-0">Chỉnh sửa</h4>
              </div>
              <div className="card-body">
                <div className="row">
                  {/* Left Column */}
                  <div className="col-md-6">
                    <div className="mb-3">
                      <label htmlFor="editProductName" className="form-label">
                        Tên sản phẩm
                      </label>
                      <input
                        type="text"
                        className="form-control"
                        id="editProductName"
                        name="productName"
                        value={productData.productName}
                        onChange={handleInputChange}
                        required
                      />
                    </div>
                    <div className="mb-3">
                      <label htmlFor="editProductPrice" className="form-label">
                        Giá
                      </label>
                      <input
                        type="text"
                        className="form-control"
                        id="editProductPrice"
                        name="productPrice"
                        value={productData.productPrice}
                        onChange={handleInputChange}
                        required
                      />
                    </div>
                    <div className="mb-3">
                      <label
                        htmlFor="editProductWoodType"
                        className="form-label"
                      >
                        Loại gỗ
                      </label>
                      <input
                        type="text"
                        className="form-control"
                        id="editProductWoodType"
                        name="productWoodType"
                        value={productData.productWoodType}
                        onChange={handleInputChange}
                        required
                      />
                    </div>
                    <div className="mb-3">
                      <label htmlFor="editProductColor" className="form-label">
                        Màu sắc
                      </label>
                      <input
                        type="text"
                        className="form-control"
                        id="editProductColor"
                        name="productColor"
                        value={productData.productColor}
                        onChange={handleInputChange}
                        required
                      />
                    </div>
                    <div className="mb-3">
                      <label
                        htmlFor="editProductDescription"
                        className="form-label"
                      >
                        Mô tả
                      </label>
                      <textarea
                        className="form-control"
                        id="editProductDescription"
                        name="productDescription"
                        rows="3"
                        value={productData.productDescription}
                        onChange={handleInputChange}
                        required
                      ></textarea>
                    </div>
                    <div className="mb-3">
                      <label htmlFor="editManufacturer" className="form-label">
                        Chọn nhà sản xuất
                      </label>
                      <select
                        className="form-control"
                        id="editManufacturer"
                        name="manufacturer"
                        value={productData.manufacturer}
                        onChange={handleInputChange}
                        required
                      >
                        <optgroup label="Nhà sản xuất quốc tế">
                          <option value="ikea">IKEA (Thụy Điển)</option>
                          <option value="ashley">Ashley Furniture (Mỹ)</option>
                          {/* Add other manufacturers... */}
                        </optgroup>
                        <optgroup label="Nhà sản xuất Việt Nam">
                          <option value="hoa_phat">Nội thất Hòa Phát</option>
                          {/* Add other manufacturers... */}
                        </optgroup>
                        <optgroup label="Nhà sản xuất khác">
                          <option value="custom">
                            Khác (Sản xuất theo yêu cầu)
                          </option>
                        </optgroup>
                      </select>
                    </div>
                    <div className="mb-3">
                      <label htmlFor="editProductStatus" className="form-label">
                        Trạng thái sản phẩm
                      </label>
                      <select
                        className="form-select"
                        id="editProductStatus"
                        name="productStatus"
                        value={productData.productStatus}
                        onChange={handleInputChange}
                        required
                      >
                        <option value="available">Hiện sản phẩm</option>
                        <option value="out_of_stock">Hết hàng</option>
                        <option value="coming_soon">Sắp ra mắt</option>
                        <option value="discontinued">Ngừng kinh doanh</option>
                        <option value="pre_order">Hàng đặt trước</option>
                      </select>
                    </div>
                  </div>

                  {/* Right Column */}
                  <div className="col-md-6">
                    <div className="mb-3">
                      <label htmlFor="editProductStock" className="form-label">
                        Số lượng trong kho
                      </label>
                      <input
                        type="number"
                        className="form-control"
                        id="editProductStock"
                        name="productStock"
                        value={productData.productStock}
                        onChange={handleInputChange}
                        required
                      />
                    </div>
                    <div className="mb-3">
                      <label
                        htmlFor="editProductDiscount"
                        className="form-label"
                      >
                        Giá khuyến mãi
                      </label>
                      <input
                        type="text"
                        className="form-control"
                        id="editProductDiscount"
                        name="productDiscount"
                        value={productData.productDiscount}
                        onChange={handleInputChange}
                      />
                    </div>
                    <div className="mb-3">
                      <label htmlFor="editProductSize" className="form-label">
                        Kích thước
                      </label>
                      <input
                        type="text"
                        className="form-control"
                        id="editProductSize"
                        name="productSize"
                        value={productData.productSize}
                        onChange={handleInputChange}
                      />
                    </div>
                    <div className="mb-3">
                      <label
                        htmlFor="editProductWarranty"
                        className="form-label"
                      >
                        Bảo hành
                      </label>
                      <input
                        type="text"
                        className="form-control"
                        id="editProductWarranty"
                        name="productWarranty"
                        value={productData.productWarranty}
                        onChange={handleInputChange}
                      />
                    </div>
                    <div className="mb-3">
                      <label htmlFor="editProductImage" className="form-label">
                        Hình ảnh
                      </label>
                      <input
                        type="file"
                        className="form-control"
                        id="editProductImage"
                        name="productImage"
                        onChange={handleInputChange}
                      />
                    </div>
                    <div className="mb-3">
                      <label htmlFor="editProductType" className="form-label">
                        Chọn loại sản phẩm
                      </label>
                      <select
                        className="form-select"
                        id="editProductType"
                        name="productType"
                        value={productData.productType}
                        onChange={handleInputChange}
                        required
                      >
                        <option value="" disabled>
                          Chọn loại sản phẩm
                        </option>
                        <optgroup label="Nội thất phòng ăn">
                          <option value="ban_an">Bàn ăn</option>
                          <option value="ghe_an">Ghế ăn</option>
                          {/* Add other product types... */}
                        </optgroup>
                        <optgroup label="Nội thất phòng ngủ">
                          <option value="giuong">Giường</option>
                          {/* Add other product types... */}
                        </optgroup>
                        {/* Add more categories as in the Add form */}
                      </select>
                    </div>
                    <div className="mb-3">
                      <label
                        htmlFor="editFeaturedProduct"
                        className="form-label"
                      >
                        Sản phẩm nổi bật
                      </label>
                      <select
                        className="form-select"
                        id="editFeaturedProduct"
                        name="featuredProduct"
                        value={productData.featuredProduct}
                        onChange={handleInputChange}
                        required
                      >
                        <option value="regular">Sản phẩm bình thường</option>
                        <option value="featured">Sản phẩm nổi bật</option>
                        <option value="best_seller">Sản phẩm bán chạy</option>
                        <option value="new_arrival">Sản phẩm mới về</option>
                        <option value="limited_edition">
                          Sản phẩm giới hạn
                        </option>
                        <option value="discounted">Sản phẩm khuyến mãi</option>
                      </select>
                    </div>
                  </div>
                </div>

                <div className="d-flex justify-content-between">
                  <button type="submit" className="btn btn-primary">
                    Lưu Thay Đổi
                  </button>
                  <button
                    type="button"
                    className="btn btn-secondary"
                    onClick={() => {
                      /* Handle cancel action */
                    }}
                  >
                    Hủy
                  </button>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </form>
  );
};

export default EditDonHang;

import React, { useEffect, useState } from "react";
import "../../assets/css/style.css";
import "bootstrap/dist/css/bootstrap.min.css";
import "bootstrap-icons/font/bootstrap-icons.css";
import "bootstrap/dist/js/bootstrap.bundle.min.js"; // Bootstrap JavaScript
import { Link } from "react-router-dom";

const ProductList = () => {
  const [searchTerm, setSearchTerm] = useState(""); // Tìm kiếm sản phẩm
  const [selectedManufacturer, setSelectedManufacturer] = useState(""); // Nhà sản xuất
  const [products, setProducts] = useState([]);
  const [manufacturers, setManufacturers] = useState([]); // Lưu danh sách nhà sản xuất
  const [authToken, setAuthToken] = useState(""); // Lưu token
  const [categories, setCategories] = useState([]);
  const [selectedCategory, setSelectedCategory] = useState(null);

  // Lấy token từ sessionStorage khi component mount
  useEffect(() => {
    const token = sessionStorage.getItem("authToken");
    if (token) setAuthToken(token);
  }, []);

  useEffect(() => {
    if (!authToken) return;

    fetch("https://ducps34770.id.vn/api/admin/sanpham", {
      headers: { Authorization: `Bearer ${authToken}` },
    })
      .then((res) => res.json())
      .then((data) => setProducts(data.sanpham_arr))
      .catch((err) => console.error(err));

    fetch("https://ducps34770.id.vn/api/admin/nhasanxuat", {
      headers: { Authorization: `Bearer ${authToken}` },
    })
      .then((res) => res.json())
      .then((data) => setManufacturers(data))
      .catch((err) => console.error(err));

    fetch("https://ducps34770.id.vn/api/admin/danhmuc", {
      headers: { Authorization: `Bearer ${authToken}` },
    })
      .then((res) => res.json())
      .then((data) => setCategories(data))
      .catch((err) => console.error(err));
  }, [authToken]);

  // Lọc sản phẩm theo tên và nhà sản xuất
  const filterProducts = () => {
    return products.filter((product) => {
      const matchesSearch = product.ten_sp
        .toLowerCase()
        .includes(searchTerm.toLowerCase());
      const matchesManufacturer = selectedManufacturer
        ? product.nsx.ten_nsx === selectedManufacturer
        : true;
      const matchesCategory = selectedCategory
        ? product.id_loaisp === selectedCategory
        : true;
      return matchesSearch && matchesManufacturer && matchesCategory;
    });
  };

  // Xử lý sự kiện nhấn vào sản phẩm
  const handleCategoryClick = (event, id_loaisp) => {
    event.preventDefault(); // Chặn reload trang
    console.log(`https://ducps34770.id.vn/api/admin/sp_danhmuc/${id_loaisp}`);

    setSelectedCategory(id_loaisp);
    fetch(`https://ducps34770.id.vn/api/admin/sp_danhmuc/${id_loaisp}`, {
      headers: { Authorization: `Bearer ${authToken}` },
    })
      .then((res) => res.json())
      .then((data) => {
        console.log(data.list_sp); // Log toàn bộ response để kiểm tra
        setProducts(data.list_sp); // Cập nhật danh sách sản phẩm
      }) // Cập nhật danh sách sản phẩm
      .catch((err) => console.error(err));
  };

  const deleteProduct = async (id_sp) => {
    try {
      const response = await fetch(
        `https://ducps34770.id.vn/api/admin/sanpham/${id_sp}`,
        {
          method: "DELETE",
          headers: {
            Authorization: `Bearer ${authToken}`,
          },
        }
      );
      console.log("Response status:", response.status); // Thêm log
      const result = await response.json(); // Thêm log nội dung phản hồi
      console.log("Response data:", result);

      if (response.ok) {
        setProducts((prevProducts) =>
          prevProducts.filter((product) => product.id_sp !== id_sp)
        );
      } else {
        console.error("Không thể xóa sản phẩm");
      }
    } catch (error) {
      console.error("Lỗi khi xóa sản phẩm:", error);
    }
  };

  return (
    <div className="col-md-10 p-4">
      <form>
        <div className="row mb-4">
          {categories.map((category) => (
            <ProductCard
              key={category.id_loaisp} // Dùng id_loaisp làm key
              imgSrc={category.hinh} // Dùng hình ảnh từ category
              title={category.loai} // Dùng tên loại từ category
              productsCount={`10 sản phẩm`} // Số lượng sản phẩm có thể cập nhật nếu có sẵn
              onClick={(e) => handleCategoryClick(e, category.id_loaisp)} // Chuyển id_loaisp
            />
          ))}
        </div>
      </form>

      {/* Bộ lọc sản phẩm */}
      <FilterSection
        searchTerm={searchTerm}
        setSearchTerm={setSearchTerm}
        selectedManufacturer={selectedManufacturer}
        setSelectedManufacturer={setSelectedManufacturer}
        manufacturers={manufacturers}
      />

      {/* Bảng Sản Phẩm */}
      <ProductTable
        products={filterProducts()} // Sử dụng sản phẩm đã lọc ở đây
        deleteProduct={deleteProduct} // Truyền hàm xóa sản phẩm vào
      />
    </div>
  );
};

const ProductCard = ({ imgSrc, title, productsCount, icon, onClick }) => (
  <div className="col-lg-3 col-md-6 mb-4">
    <div className="card text-white bg-warning">
      <img className="product-img" src={imgSrc} alt={title} />
      <div className="card-body d-flex justify-content-between align-items-center">
        <div>
          <h5 className="card-title">{title}</h5>
          <p className="card-text">{productsCount}</p>
        </div>
        {icon && <i className={icon}></i>}
        <button onClick={onClick} className="btn btn-light">
          Lọc
        </button>
      </div>
    </div>
  </div>
);

const FilterSection = ({
  searchTerm,
  setSearchTerm,
  selectedManufacturer,
  setSelectedManufacturer,
  manufacturers,
}) => (
  <div className="row mb-4">
    {/* Tìm kiếm sản phẩm */}
    <div className="col-lg-4 col-md-6 d-flex align-items-center">
      <input
        type="text"
        className="form-control"
        value={searchTerm}
        placeholder="Tìm kiếm sản phẩm..."
        onChange={(e) => setSearchTerm(e.target.value)}
      />
      <button type="submit" className="btn btn-primary ms-2">
        Tìm
      </button>
    </div>

    {/* Lựa chọn nhà sản xuất */}
    <div className="col-lg-4">
      <div className="mt-2 text-end">
        <div className="btn-group w-100">
          <button
            className="btn btn-secondary dropdown-toggle custom-dropdown"
            type="button"
            id="manufacturerDropdown"
            data-bs-toggle="dropdown"
            aria-expanded="false"
          >
            Chọn nhà sản xuất
          </button>
          <ul className="dropdown-menu" aria-labelledby="manufacturerDropdown">
            <li>
              <button
                className="dropdown-item"
                onClick={() => setSelectedManufacturer("")}
              >
                Tất cả
              </button>
            </li>
            {manufacturers.map((manufacturer) => (
              <li key={manufacturer.id_nsx}>
                <button
                  className="dropdown-item"
                  onClick={() => setSelectedManufacturer(manufacturer.ten_nsx)}
                >
                  {manufacturer.ten_nsx}
                </button>
              </li>
            ))}
          </ul>
        </div>
      </div>
    </div>

    {/* Nút thêm sản phẩm mới */}
    <div className="col-lg-4">
      <div className="mt-2 text-end">
        <Link to="/AddSanPham" className="btn btn-primary">
          <i className="bi bi-plus-circle"></i> Thêm Sản Phẩm Mới
        </Link>
      </div>
    </div>
  </div>
);

const ProductTable = ({ products, deleteProduct }) => (
  <div className="comment-container" id="commentContainer">
    <div className="comment">
      <form>
        <div className="row mb-4">
          <div className="container mt-4">
            <div className="card-body">
              <table
                className="table table-hover table-responsive"
                id="productTable"
              >
                <thead className="table-primary">
                  <tr>
                    <th>#</th>
                    <th>Hình ảnh</th>
                    <th>Tên Sản Phẩm</th>
                    <th>Nhà sản xuất</th>
                    <th>Danh Mục</th>
                    <th>Giá Gốc</th>
                    <th>Giá Sale</th>
                    <th>Số Lượng Tồn</th>
                    <th>Ngày Thêm</th>
                    <th>Chi Tiết</th>
                    <th>Thao Tác</th>
                  </tr>
                </thead>
                <tbody>
                  {products.length > 0 ? (
                    products.map((product, index) => (
                      <tr key={product.id_sp}>
                        <td>{index + 1}</td>
                        <td>
                          <img
                            src={product.hinh}
                            // src={product.hinh}
                            alt="Product Image"
                            className="small-product-img"
                          />
                        </td>
                        <td>{product.ten_sp}</td>
                        <td>{product.nsx?.ten_nsx}</td>
                        <td>{product.danhmuc?.loai || "Không rõ"}</td>
                        <td>{product.gia_sp.toLocaleString()} VNĐ</td>
                        <td>{product.giaSale.toLocaleString()} VNĐ</td>
                        <td>{product.soLuong}</td>
                        <td>
                          {new Date(product.ngayDang).toLocaleDateString()}
                        </td>
                        <td>
                          <Link
                            to={`/chitietadmin/${product.id_sp}`}
                            className="btn btn-success"
                          >
                            Xem
                          </Link>
                        </td>
                        <td>
                          <button
                            className="btn btn-sm btn-danger"
                            onClick={(e) => {
                              e.preventDefault(); // Chặn hành vi mặc định
                              if (
                                window.confirm(
                                  "Bạn có chắc chắn muốn xóa sản phẩm này?"
                                )
                              ) {
                                deleteProduct(product.id_sp);
                              }
                            }}
                          >
                            Xóa
                          </button>
                        </td>
                      </tr>
                    ))
                  ) : (
                    <tr>
                      <td colSpan="11" className="text-center">
                        Không có sản phẩm nào phù hợp.
                      </td>
                    </tr>
                  )}
                </tbody>
              </table>
            </div>
          </div>
        </div>
      </form>
    </div>
  </div>
);

export default ProductList;

import React, { useEffect, useState } from "react";
import { Link } from "react-router-dom"; // Nhập Link để điều hướng
import "../../assets/css/style.css";
import "bootstrap/dist/css/bootstrap.min.css";
import "bootstrap-icons/font/bootstrap-icons.css";
import "bootstrap/dist/js/bootstrap.bundle.min.js"; // Bootstrap JavaScript

const Sidebar = () => {
  // Khởi tạo state để lưu trữ thông tin người dùng
  const [user, setUser] = useState(null);

  // Lấy thông tin người dùng từ localStorage khi component được render
  useEffect(() => {
    const userInfo = JSON.parse(sessionStorage.getItem("userInfo"));
    if (userInfo) {
      setUser(userInfo);
    }
  }, []);

  return (
    <div
      className="col-md-2 p-0 sidebar shadow-sm d-none d-md-block"
      id="sidebar"
    >
      <div
        className="d-flex flex-column p-3 bg-dark text-white"
        style={{ minHeight: "100vh" }}
      >
        {/* Thông tin người dùng */}
        {user && (
          <div className="user-info mb-4 text-center">
            <img
              src="assets/images/deal-01.jpg" // Thay thế bằng hình ảnh người dùng nếu có
              alt="User Image"
              className="rounded-circle user-img mb-3"
              width="100"
              height="100"
            />
            <h5 className="mb-0">{user.name}</h5>{" "}
            {/* Hiển thị tên người dùng */}
            <h5 className="mb-0">{user.email}</h5>{" "}
            {/* Hiển thị email người dùng */}
          </div>
        )}
        <hr />
        <ul className="nav flex-column mb-auto">
          <li>
            <Link to="/dashboard" className="nav-link">
              <i className="fa-brands fa-squarespace"></i> Bảng Điều Khiển
            </Link>
          </li>
          <li>
            <Link to="/cart" className="nav-link">
              <i className="fa-solid fa-truck-fast"></i> Đơn Hàng
            </Link>
          </li>
          <li>
            <Link to="/productslist" className="nav-link">
              <i className="fa-brands fa-shopify"></i> Sản Phẩm
            </Link>
          </li>
          <li>
            <Link to="/userdashboard" className="nav-link">
              <i className="fa-solid fa-user-group"></i> Khách Hàng
            </Link>
          </li>
          <li>
            <Link to="/comment" className="nav-link">
              <i className="fa-solid fa-comment"></i> Bình Luận
            </Link>
          </li>
          <li>
            <Link to="/voucher" className="nav-link">
              <i className="fa-solid fa-ticket"></i> Voucher
            </Link>
          </li>
          <li>
            <Link to="/BaiViet" className="nav-link">
              <i className="fa-solid fa-newspaper"></i> Bài viết
            </Link>
          </li>
          <li>
            <Link to="/admindashboard" className="nav-link">
              <i className="fa-brands fa-slack"></i> Thống Kê Doanh Thu
            </Link>
          </li>
          <li>
            <Link to="/quanlydm" className="nav-link">
              <i className="fa-solid fa-window-restore"></i> Quản lý danh mục
            </Link>
          </li>
          <li>
            <Link to="/QuanlyNSX" className="nav-link">
              <i className="fa-solid fa-city"></i> Quản lý nhà sản xuất
            </Link>
          </li>
          <li>
            <Link to="/PhanHoi" className="nav-link">
              <i className="fa-solid fa-city"></i> Phản Hồi
            </Link>
          </li>
        </ul>
      </div>
    </div>
  );
};

export default Sidebar;

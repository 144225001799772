import { useState, useEffect, useRef } from "react";
import { Link } from "react-router-dom";
import "bootstrap/dist/css/bootstrap.min.css";
import "bootstrap-icons/font/bootstrap-icons.css";
import "bootstrap/dist/js/bootstrap.bundle.min.js";
import "../../assets/css/style.css";
import Chart from "chart.js/auto";

const Dashboard = () => {
  const chartRef = useRef(null);
  const chartInstance = useRef(null);
  const [products, setProducts] = useState([]); // eslint-disable-line no-unused-vars
  const [donHangCount, setDonHangCount] = useState(0);
  const [sanPhamCount, setSanPhamCount] = useState(0);
  const [khachHangCount, setKhachHangCount] = useState(0);
  const [binhLuanCount, setBinhLuanCount] = useState(0);

  const [revenueData, setRevenueData] = useState({
    doanhthu_thang_nay: 0,
    doanhthu_thang_truoc: 0,
    ti_le: 0,
    ti_le2: 0,
  });
  const [filterOption, setFilterOption] = useState("month"); // Thêm khai báo filterOption
  const [loading, setLoading] = useState(false); // eslint-disable-line no-unused-vars

  const dummyData = {
    month: {
      doanhthu_thang_nay: 15000000,
      doanhthu_thang_truoc: 12000000,
      ti_le: 25,
      ti_le2: 10,
    },
    quarter: {
      doanhthu_thang_nay: 45000000,
      doanhthu_thang_truoc: 40000000,
      ti_le: 12.5,
      ti_le2: 8,
    },
    year: {
      doanhthu_thang_nay: 500000000,
      doanhthu_thang_truoc: 480000000,
      ti_le: 4.1,
      ti_le2: 2.5,
    },
  };

  const updateRevenueData = (filter) => {
    setRevenueData(dummyData[filter]);
  };

  useEffect(() => {
    updateRevenueData(filterOption);
  }, [filterOption]);

  useEffect(() => {
    const fetchData = async () => {
      try {
        const response = await fetch("https://ducps34770.id.vn/api/admin");
        const data = await response.json();
        setRevenueData({
          doanhthu_thang_nay: data.doanhthu_thang_nay || 0,
          doanhthu_thang_truoc: data.doanhthu_thang_truoc || 0,
          ti_le: data.ti_le || 0,
          ti_le2: data.ti_le2 || 0,
        });
        setLoading(false);
      } catch (error) {
        console.error("Error fetching revenue data:", error);
      }
    };
    fetchData();
  }, []);

  useEffect(() => {
    // Fetch data from API
    const fetchData = async () => {
      try {
        const response = await fetch("https://ducps34770.id.vn/api/admin");
        const data = await response.json();
        setProducts(data.sanpham); // Assuming 'sanpham' is the key in the API response
      } catch (error) {
        console.error("Error fetching product data:", error);
      }
    };

    fetchData();
  }, []);

  useEffect(() => {
    const fetchCounts = async () => {
      try {
        const token = sessionStorage.getItem("authToken");
        if (!token) throw new Error("Token is missing");
        const headers = { Authorization: `Bearer ${token}` };

        const fetchData = async (url) => {
          const response = await fetch(url, { headers });
          return response.json();
        };

        const [orderData, productData, customerData, commentData] =
          await Promise.all([
            fetchData("https://ducps34770.id.vn/api/admin/donhang"),
            fetchData("https://ducps34770.id.vn/api/admin/sanpham"),
            fetchData("https://ducps34770.id.vn/api/admin/user"),
            fetchData("https://ducps34770.id.vn/api/admin/binhluan"),
          ]);

        setDonHangCount(orderData.length || 0);
        setSanPhamCount(productData.sanpham_arr?.length || 0);
        setKhachHangCount(customerData.length || 0);
        setBinhLuanCount(commentData.length || 0);
      } catch (error) {
        console.error("Error fetching counts:", error);
      }
    };

    fetchCounts();
  }, []);

  useEffect(() => {
    if (!chartRef.current) return;

    const ctx = chartRef.current.getContext("2d");

    const fetchChartData = async () => {
      try {
        const response = await fetch("https://ducps34770.id.vn/api/admin");
        const data = await response.json();
        const monthlyRevenue = data.doanhthu_12thang || [];

        if (chartInstance.current) chartInstance.current.destroy();

        chartInstance.current = new Chart(ctx, {
          type: "bar",
          data: {
            labels: [
              "Tháng 1",
              "Tháng 2",
              "Tháng 3",
              "Tháng 4",
              "Tháng 5",
              "Tháng 6",
              "Tháng 7",
              "Tháng 8",
              "Tháng 9",
              "Tháng 10",
              "Tháng 11",
              "Tháng 12",
            ],
            datasets: [
              {
                label: "Doanh Thu (VND)",
                data: monthlyRevenue,
                backgroundColor: "rgba(54, 162, 235, 0.2)",
                borderColor: "rgba(54, 162, 235, 1)",
                borderWidth: 1,
              },
            ],
          },
          options: {
            responsive: true,
            scales: {
              y: { beginAtZero: true },
            },
          },
        });
      } catch (error) {
        console.error("Error fetching chart data:", error);
      }
    };

    fetchChartData();

    return () => {
      if (chartInstance.current) chartInstance.current.destroy();
    };
  }, []);

  return (
    <div className="col-md-10 p-4">
      {/* Cards */}
      <div className="row mb-4">
        <div className="col-lg-3 col-md-6 mb-4">
          <Link to="/Cart" className="custom-link">
            <div className="card text-white btn-orange">
              <div className="card-body d-flex justify-content-between align-items-center">
                <div>
                  <h5 className="card-title">Đơn Hàng</h5>
                  <p className="card-text">{donHangCount} Đơn Hàng Mới</p>
                </div>
                <i className="fa-solid fa-truck"></i>
              </div>
            </div>
          </Link>
        </div>

        <div className="col-lg-3 col-md-6 mb-4">
          <Link to="/productslist" className="custom-link">
            <div className="card text-white bg-success">
              <div className="card-body d-flex justify-content-between align-items-center">
                <div>
                  <h5 className="card-title">Sản Phẩm</h5>
                  <p className="card-text">{sanPhamCount} Sản Phẩm Mới</p>
                </div>
                <i className="fa-brands fa-shopify"></i>
              </div>
            </div>
          </Link>
        </div>

        <div className="col-lg-3 col-md-6 mb-4">
          <Link to="/User  Dashboard" className="custom-link">
            <div className="card text-white bg-warning">
              <div className="card-body d-flex justify-content-between align-items-center">
                <div>
                  <h5 className="card-title">Khách Hàng</h5>
                  <p className="card-text">{khachHangCount} Khách Hàng Mới</p>
                </div>
                <i className="fa-solid fa-user-group"></i>
              </div>
            </div>
          </Link>
        </div>

        <div className="col-lg-3 col-md-6 mb-4">
          <Link to="/Comment" className="custom-link">
            <div className="card text-white bg-primary">
              <div className="card-body d-flex justify-content-between align-items-center">
                <div>
                  <h5 className="card-title">Bình Luận</h5>
                  <p className="card-text">{binhLuanCount} Bình Luận Mới</p>
                </div>
                <i className="fa-solid fa-comments"></i>
              </div>
            </div>
          </Link>
        </div>
      </div>
      <select
        id="filter"
        className="form-select"
        value={filterOption}
        onChange={(e) => setFilterOption(e.target.value)}
      >
        <option value="month">Tháng</option>
        <option value="quarter">Quý</option>
        <option value="year">Năm</option>
      </select>

      {/* User Statistics Section */}
      <div className="container my-4">
        <div className="row">
          {/* Card for Current Month */}
          <div className="col-md-6">
            <div className="card">
              <div className="card-header">Tháng này</div>
              <div className="card-body">
                <div className="revenue-title">Tổng doanh thu tháng này</div>
                <div className="revenue-value">
                  VND {revenueData.doanhthu_thang_nay.toLocaleString()}
                </div>
                <div className="revenue-description">Tháng hiện tại</div>
                <div className="percentage-change percentage-up">
                  <i className="fas fa-arrow-up"></i> Tăng {revenueData.ti_le}%
                  so với tháng trước
                </div>
              </div>
            </div>
          </div>

          {/* Card for Last Month */}
          <div className="col-md-6">
            <div className="card">
              <div className="card-header">Tháng trước</div>
              <div className="card-body">
                <div className="revenue-title">Tổng doanh thu tháng trước</div>
                <div className="revenue-value">
                  VND {revenueData.doanhthu_thang_truoc.toLocaleString()}
                </div>
                <div className="revenue-description">Tháng trước</div>
                <div className="percentage-change percentage-down">
                  <i className="fas fa-arrow-down"></i> Giảm{" "}
                  {revenueData.ti_le2}% so với tháng trước nữa
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>

      {/* Chart */}
      <div className="chart mb-4">
        <canvas id="revenueChart" ref={chartRef}></canvas>
      </div>

      <hr />

      {/* Product Table */}
      <div className="card-header d-flex justify-content-between align-items-center">
        <h4>Sản Phẩm Bán Chạy</h4>
      </div>
      <div className="comment-container">
        <div className="card-body">
          <table
            className="table table-hover table-responsive"
            id="productTable"
          >
            <thead className="table-primary">
              <tr>
                <th>#</th>
                <th>Hình ảnh</th>
                <th>Tên Sản Phẩm</th>
                <th>Danh Mục</th>
                <th>Giá</th>
                <th>Số Lượng</th>
                <th>Lượt mua</th>
                <th>Chi Tiết</th>
              </tr>
            </thead>
            <tbody>
              {products.map((product, index) => (
                <tr key={product.id_sp}>
                  <td>{index + 1}</td>
                  <td>
                    <img
                      src={product.hinh.trim()} // Trim để loại bỏ khoảng trắng
                      alt={product.ten_sp}
                      className="img-fluid"
                      width="100"
                    />
                  </td>
                  <td>{product.ten_sp}</td>
                  <td>{product.loai_go}</td>
                  <td>{product.gia_sp.toLocaleString()} VNĐ</td>
                  <td>{product.soLuong}</td>
                  <td>{product.luot_mua}</td>
                  <td>
                    <Link
                      to={`/chitietadmin/${product.id_sp}`}
                      className="btn btn-primary"
                    >
                      Xem Chi Tiết
                    </Link>
                  </td>
                </tr>
              ))}
            </tbody>
          </table>
        </div>
      </div>
    </div>
  );
};

export default Dashboard;

import React, { useState, useEffect } from "react";
import "../../assets/css/style.css";
import "bootstrap/dist/css/bootstrap.min.css";
import "bootstrap-icons/font/bootstrap-icons.css";
import { Link, useNavigate } from "react-router-dom";

const UserDashboard = () => {
  const [users, setUsers] = useState([]);
  const [filteredUsers, setFilteredUsers] = useState([]);
  const [userCount, setUserCount] = useState(0);
  const [userNew, setUserNew] = useState(0);
  const [userBlocks, setUserBlocks] = useState(0);
  const [searchQuery, setSearchQuery] = useState("");
  const [roleFilter, setRoleFilter] = useState("");
  const navigate = useNavigate();

  useEffect(() => {
    const fetchData = async () => {
      try {
        const token =
          sessionStorage.getItem("authToken") ||
          localStorage.getItem("authToken");

        const response = await fetch("https://ducps34770.id.vn/api/admin/user", {
          method: "GET",
          headers: {
            Authorization: `Bearer ${token}`,
            "Content-Type": "application/json",
          },
        });

        if (!response.ok) {
          throw new Error("Network response was not ok");
        }

        const data = await response.json();
        setUsers(data);
        setFilteredUsers(data);
        setUserCount(data.length);

        // Giả sử có thuộc tính created_at để xác định người dùng mới
        const newUsers = data.filter((user) =>
          user.created_at.includes("2024-11")
        ).length;
        setUserNew(newUsers);

        const blockedUsers = data.filter((user) => user.status === 0).length;
        setUserBlocks(blockedUsers);
      } catch (error) {
        console.error("Error fetching data:", error);
      }
    };

    fetchData();
  }, []);
  const handleRoleChange = (userId, role) => {
    if (role === "admin") {
      // Khi vai trò là admin, điều hướng đến trang quản lý admin
      navigate("/user-admin");
    }
  };
  const handleSearchChange = (event) => {
    const query = event.target.value;
    setSearchQuery(query);
    filterUsers(query, roleFilter);
  };

  const handleRoleFilterChange = (event) => {
    const role = event.target.value;
    setRoleFilter(role);
    filterUsers(searchQuery, role);
  };

  const filterUsers = (search, role) => {
    let filtered = users;

    if (role) {
      filtered = filtered.filter((user) => user.role.toString() === role);
    }

    if (search) {
      filtered = filtered.filter((user) => {
        return (
          user.name.toLowerCase().includes(search.toLowerCase()) ||
          user.email.toLowerCase().includes(search.toLowerCase()) ||
          user.phone.includes(search)
        );
      });
    }

    setFilteredUsers(filtered);
  };

  const getRoleBadgeClass = (role) => {
    switch (role) {
      case 1:
        return "bg-success"; // Khách Hàng
      case 2:
        return "bg-warning"; // Nhân viên
      case 3:
        return "bg-danger"; // Shipper
      default:
        return "bg-secondary"; // Default
    }
  };

  const handleDeleteUser = async (userId) => {
    const token =
      sessionStorage.getItem("authToken") || localStorage.getItem("authToken");

    try {
      const response = await fetch(
        `https://ducps34770.id.vn/api/admin/user/${userId}`,
        {
          method: "DELETE",
          headers: {
            Authorization: `Bearer ${token}`,
            "Content-Type": "application/json",
          },
        }
      );

      if (!response.ok) {
        throw new Error("Failed to delete user");
      }

      setUsers(users.filter((user) => user.id !== userId));
      setFilteredUsers(filteredUsers.filter((user) => user.id !== userId));
      alert("Người dùng đã được xóa thành công!");
    } catch (error) {
      console.error("Error deleting user:", error);
      alert("Có lỗi xảy ra khi xóa người dùng.");
    }
  };

  const handleEditClick = (userId) => {
    navigate(`/EditND/${userId}`); // Redirect to the edit page with the user ID
  };

  return (
    <div className="col-md-10 p-4">
      {/* Số Người Dùng */}
      <div className="row mb-4">
        <div className="col-lg-3 col-md-6 mb-4">
          <form method="GET" action="/user/index?filter=all_users">
            <div
              className="card text-white btn-orange"
              style={{ textDecoration: "none" }}
            >
              <div className="card-body d-flex justify-content-between align-items-center">
                <div>
                  <h5 className="card-title">Số Người Dùng</h5>
                  <p className="card-text">{userCount} Người dùng</p>
                </div>
                <input type="hidden" name="filter" value="all_users" />
              </div>
              <div className="row mb-2">
                <div className="mt-2 text-end">
                  <button type="submit" className="btn btn-light">
                    Xem Tài Khoản
                  </button>
                </div>
              </div>
            </div>
          </form>
        </div>

        {/* Khách Mới Trong Tháng */}
        <div className="col-lg-3 col-md-6 mb-4">
          <form method="GET" action="/user/index?filter=new_users">
            <div
              className="card text-white bg-success"
              style={{ textDecoration: "none" }}
            >
              <div className="card-body d-flex justify-content-between align-items-center">
                <div>
                  <h5 className="card-title">Khách Trong Tháng</h5>
                  <p className="card-text">{userNew} Khách Hàng</p>
                </div>
                <input type="hidden" name="filter" value="new_users" />
              </div>
              <div className="row mb-2">
                <div className="mt-2 text-end">
                  <button type="submit" className="btn btn-light">
                    Xem Tài Khoản
                  </button>
                </div>
              </div>
            </div>
          </form>
        </div>

        {/* Tài Khoản Bị Khóa */}
        <div className="col-lg-3 col-md-6 mb-4">
          <form method="GET" action="/user/index?filter=blocked_users">
            <div
              className="card text-white bg-warning"
              style={{ textDecoration: "none" }}
            >
              <div className="card-body d-flex justify-content-between align-items-center">
                <div>
                  <h5 className="card-title">Tài Khoản Bị Khóa</h5>
                  <p className="card-text">{userBlocks} Tài Khoản</p>
                </div>
                <input type="hidden" name="filter" value="blocked_users" />
              </div>
              <div className="row mb-2">
                <div className="mt-2 text-end">
                  <button type="submit" className="btn btn-light">
                    Xem Tài Khoản
                  </button>
                </div>
              </div>
            </div>
          </form>
        </div>
      </div>

      {/* Nút Thêm Người Dùng */}
      <div className="row mb-4 align-items-end">
        <div className="col-lg-3 col-md-6 mb-4">
          <Link
            to="/AddUsers"
            className="btn btn-primary w-100"
            style={{
              textDecoration: "none",
              fontSize: "18px",
              borderRadius: "8px",
            }}
          >
            <i className="fa-solid fa-plus-circle me-2"></i> Thêm Người Dùng
          </Link>
        </div>

        {/* Form tìm kiếm và lọc */}
        <div className="col-lg-9 col-md-6 d-flex flex-wrap mb-4">
          <form className="d-flex" style={{ flex: 1, maxWidth: "35%" }}>
            <select
              name="role"
              className="form-control"
              onChange={handleRoleFilterChange}
              value={roleFilter}
            >
              <option value="" disabled>
                -- Lọc Theo Vai Trò --
              </option>
              <option value="">-- Tất cả --</option>
              <option value="1">Khách Hàng</option>
              <option value="2">Nhân viên</option>
              <option value="3">Shipper</option>
            </select>
            <button
              type="button"
              className="btn btn-primary ms-2"
              onClick={() => filterUsers(searchQuery, roleFilter)}
            >
              Lọc
            </button>
          </form>
          <form
            className="input-group me-3"
            style={{ flex: 1, maxWidth: "60%" }}
            onSubmit={(e) => e.preventDefault()}
          >
            <input
              type="text"
              className="form-control"
              placeholder="Tìm kiếm người dùng..."
              value={searchQuery}
              onChange={handleSearchChange}
            />
            <button
              className="btn btn-primary"
              type="button"
              onClick={() => filterUsers(searchQuery, roleFilter)}
            >
              <i className="bi bi-search"></i>
            </button>
          </form>
        </div>
      </div>

      {/* Bảng Danh Sách Người Dùng */}
      <div className="table-responsive">
        <table className="table table-striped table-hover">
          <thead>
            <tr>
              <th>#</th>
              <th>Hình ảnh</th>
              <th>Tên</th>
              <th>Email</th>
              <th>SĐT</th>
              <th>Vai Trò</th>
              <th>Trạng Thái</th>
              <th>Xem thông tin</th>
              <th>Thao Tác</th>
              <th>Xóa</th>
            </tr>
          </thead>
          <tbody>
            {filteredUsers.length > 0 ? (
              filteredUsers.map((user, index) => (
                <tr key={user.id}>
                  <td>{index + 1}</td>
                  <td>
                    <img
                      // src={user.image}
                      src={user.image}
                      alt={user.name}
                      width="40"
                      height="40"
                    />
                  </td>
                  <td>{user.name}</td>
                  <td>{user.email}</td>
                  <td>{user.phone}</td>
                  <td>
                    <span className={`badge ${getRoleBadgeClass(user.role)}`}>
                      {user.role === 1
                        ? "Khách Hàng"
                        : user.role === 2
                        ? "Nhân viên"
                        : "Shipper"}
                    </span>
                  </td>
                  <td>{user.status === 0 ? "Hoạt Động" : "Bị Khóa"}</td>
                  <td>
                    <Link
                      to={`/chitietkhachhang/${user.id}`}
                      className="btn btn-info btn-sm me-2"
                    >
                      <i className="fa-solid fa-eye"></i>
                    </Link>
                  </td>
                  <td>
                    <button
                      onClick={() => handleEditClick(user.id)}
                      className="btn btn-warning btn-sm"
                    >
                      Chỉnh sửa
                    </button>
                  </td>
                  <td>
                    <button
                      className="btn btn-danger btn-sm"
                      onClick={() => handleDeleteUser(user.id)}
                    >
                      <i className="fa-solid fa-trash"></i>
                    </button>
                  </td>
                </tr>
              ))
            ) : (
              <tr>
                <td colSpan="10" className="text-center">
                  Không có người dùng nào
                </td>
              </tr>
            )}
          </tbody>
        </table>
      </div>
    </div>
  );
};

export default UserDashboard;

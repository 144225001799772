import React, { useState } from "react";
import axios from "axios";
import { useNavigate } from "react-router-dom";
import "bootstrap/dist/css/bootstrap.min.css";
import "bootstrap-icons/font/bootstrap-icons.css";
import "bootstrap/dist/js/bootstrap.bundle.min.js";
import "../../assets/css/style.css";

const AdminLogin = () => {
  const [email, setEmail] = useState(""); // Trạng thái lưu email
  const [password, setPassword] = useState(""); // Trạng thái lưu mật khẩu
  const [rememberMe, setRememberMe] = useState(false); // Trạng thái ghi nhớ
  const [errorMessage, setErrorMessage] = useState(""); // Thông báo lỗi

  const navigate = useNavigate(); // Điều hướng trang

  const handleSubmit = async (e) => {
    e.preventDefault();
    try {
      const response = await axios.post(
        "https://ducps34770.id.vn/api/admin/dangnhap_ad_check",
        {
          email,
          password,
        },
        {
          headers: {
            "Content-Type": "application/json",
          },
        }
      );

      // Log toàn bộ dữ liệu trả về từ backend
      console.log("Dữ liệu trả về từ backend:", response.data);

      if (response.data.message === "Đăng nhập thành công") {
        const { token, role, user } = response.data; // 'user' chứa thông tin người dùng

        // Lưu token, role và thông tin người dùng vào sessionStorage
        sessionStorage.setItem("authToken", token);
        sessionStorage.setItem("userRole", role);
        sessionStorage.setItem("userInfo", JSON.stringify(user)); // Lưu dưới dạng chuỗi JSON

        // Nếu chọn "Ghi nhớ đăng nhập", lưu vào localStorage thay vì sessionStorage
        if (rememberMe) {
          localStorage.setItem("authToken", token);
          localStorage.setItem("userRole", role);
          localStorage.setItem("userInfo", JSON.stringify(user));
        }

        // Điều hướng đến trang dashboard
        navigate("/dashboard");
      } else {
        setErrorMessage(response.data.message || "Đăng nhập không thành công.");
      }
    } catch (error) {
      if (error.response) {
        // Lỗi từ phía server
        setErrorMessage(
          error.response.data?.message || "Đã xảy ra lỗi từ phía server."
        );
      } else if (error.request) {
        // Không kết nối được đến server
        setErrorMessage("Không thể kết nối đến server. Vui lòng kiểm tra lại.");
      } else {
        // Lỗi không xác định
        setErrorMessage("Lỗi không xác định. Vui lòng thử lại.");
      }
      console.error("Chi tiết lỗi:", error);
    }
  };

  return (
    <div className="container">
      <div className="row justify-content-center">
        <div className="col-md-4">
          <div className="login-container">
            <div className="login-header text-center">
              <h2>Đăng Nhập Admin</h2>
              <p>Vui lòng đăng nhập để tiếp tục.</p>
            </div>
            <form onSubmit={handleSubmit}>
              <div className="mb-3">
                <label htmlFor="email" className="form-label">
                  Email:
                </label>
                <input
                  type="email"
                  className="form-control"
                  id="email"
                  placeholder="Nhập email"
                  value={email}
                  onChange={(e) => setEmail(e.target.value)}
                  required
                />
              </div>
              <div className="mb-3">
                <label htmlFor="password" className="form-label">
                  Mật khẩu:
                </label>
                <input
                  type="password"
                  className="form-control"
                  id="password"
                  placeholder="Nhập mật khẩu"
                  value={password}
                  onChange={(e) => setPassword(e.target.value)}
                  required
                />
              </div>
              <div className="mb-3">
                <div className="form-check">
                  <input
                    type="checkbox"
                    className="form-check-input"
                    id="rememberMe"
                    checked={rememberMe}
                    onChange={(e) => setRememberMe(e.target.checked)}
                  />
                  <label className="form-check-label" htmlFor="rememberMe">
                    Ghi nhớ đăng nhập
                  </label>
                </div>
              </div>
              <div className="d-flex justify-content-center">
                <button type="submit" className="btn btn-primary">
                  Đăng Nhập
                </button>
              </div>
            </form>

            {/* Thông báo lỗi */}
            {errorMessage && (
              <div className="alert alert-danger mt-3" role="alert">
                {errorMessage}
              </div>
            )}
          </div>
        </div>
      </div>
      <footer className="footer text-center">
        <p>© 2024 Công ty XYZ. Bảo mật và điều khoản dịch vụ.</p>
      </footer>
    </div>
  );
};

export default AdminLogin;

import React, { useEffect, useState } from "react";
import "bootstrap/dist/css/bootstrap.min.css";
import "bootstrap-icons/font/bootstrap-icons.css";
import "bootstrap/dist/js/bootstrap.bundle.min.js"; // Bootstrap JavaScript
import "../../assets/css/style.css";
import { useParams, Link } from "react-router-dom";

const Chitietkhachhang = () => {
  const { id } = useParams(); // Lấy ID khách hàng từ URL
  const [customer, setCustomer] = useState(null);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);

  useEffect(() => {
    const fetchCustomer = async () => {
      try {
        const token =
          sessionStorage.getItem("authToken") ||
          localStorage.getItem("authToken");

        const response = await fetch(
          `https://.ducps34770.id.vn/api/admin/user/${id}`,
          {
            method: "GET",
            headers: {
              Authorization: `Bearer ${token}`,
              "Content-Type": "application/json",
            },
          }
        );

        if (!response.ok) {
          throw new Error("Không thể tải thông tin khách hàng.");
        }

        const data = await response.json();
        setCustomer(data);
      } catch (error) {
        setError(error.message);
      } finally {
        setLoading(false);
      }
    };

    fetchCustomer();
  }, [id]);

  if (loading) {
    return <div>Đang tải thông tin khách hàng...</div>;
  }

  if (error) {
    return <div className="alert alert-danger">{error}</div>;
  }

  return (
    <div className="col-md-10">
      <div className="md-10 p-4">
        <div className="card">
          <div className="card shadow">
            <div className="card shadow" style={{ borderRadius: "10px" }}>
              <div
                className="card-header text-white"
                style={{
                  backgroundColor: "#ffcc00",
                  borderTopLeftRadius: "10px",
                  borderTopRightRadius: "10px",
                }}
              >
                <h4>Thông Tin Chi Tiết Khách Hàng</h4>
              </div>
            </div>

            <div className="card-body">
              {customer ? (
                <div>
                  <p>
                    <strong>Tên:</strong> {customer.name}
                  </p>
                  <p>
                    <strong>Email:</strong> {customer.email}
                  </p>
                  <p>
                    <strong>Số Điện Thoại:</strong> {customer.phone}
                  </p>
                  <p>
                    <strong>Địa Chỉ:</strong> {customer.address}
                  </p>
                  <p>
                    <strong>Vai Trò:</strong>{" "}
                    {customer.role === 1
                      ? "Khách Hàng"
                      : customer.role === 2
                      ? "Nhân viên"
                      : "Shipper"}
                  </p>
                  <p>
                    <strong>Trạng Thái:</strong>{" "}
                    {customer.status === 1 ? "Hoạt Động" : "Bị Khóa"}
                  </p>
                  <p>
                    <strong>Email Đã Xác Minh:</strong>{" "}
                    {customer.email_verified_at
                      ? "Đã Xác Minh"
                      : "Chưa Xác Minh"}
                  </p>
                  <p>
                    <strong>Ngày Tạo:</strong>{" "}
                    {new Date(customer.created_at).toLocaleString()}
                  </p>
                  <p>
                    <strong>Ngày Cập Nhật:</strong>{" "}
                    {new Date(customer.updated_at).toLocaleString()}
                  </p>
                </div>
              ) : (
                <div>Không tìm thấy thông tin khách hàng.</div>
              )}

              <Link
                to="/UserDashboard"
                className="btn btn-secondary btn-sm float-end"
              >
                <i className="bi bi-arrow-left"></i> Quay lại
              </Link>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Chitietkhachhang;

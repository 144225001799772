import React, { useState, useEffect } from "react";
import axios from "axios";
import { useParams, useNavigate } from "react-router-dom";

const EditDanhMuc = () => {
  const { id_loaisp } = useParams();
  const [order, setOrder] = useState("");
  const [categoryName, setCategoryName] = useState("");
  const [image, setImage] = useState(null);
  const [imagePreview, setImagePreview] = useState(null);
  const navigate = useNavigate();

  const authToken = sessionStorage.getItem("authToken");

  useEffect(() => {
    axios
      .get(`https://ducps34770.id.vn/api/admin/danhmuc/${id_loaisp}`, {
        headers: { Authorization: `Bearer ${authToken}` },
      })
      .then((response) => {
        const data = response.data;
        setOrder(data.thu_tu);
        setCategoryName(data.loai || "");
        setImagePreview(data.hinh);
      })
      .catch((error) => {
        console.error("Lỗi khi lấy dữ liệu danh mục:", error);
      });
  }, [id_loaisp, authToken]);

  const handleImageChange = (e) => {
    const file = e.target.files[0];
    if (file) {
      setImage(file);
      const reader = new FileReader();
      reader.onloadend = () => setImagePreview(reader.result);
      reader.readAsDataURL(file);
    }
  };

  const handleSave = async (e) => {
    e.preventDefault();

    if (!categoryName.trim()) {
      alert("Tên danh mục không được để trống");
      return;
    }

    if (!order || isNaN(order)) {
      alert("Thứ tự phải là một số hợp lệ");
      return;
    }

    const formData = new FormData();
    formData.append("loai", categoryName.trim());
    formData.append("thu_tu", parseInt(order));
    if (image) formData.append("hinh", image);

    try {
      await axios.post(
        `https://ducps34770.id.vn/api/admin/danhmuc/${id_loaisp}`,
        formData,
        {
          headers: {
            "Content-Type": "multipart/form-data",
            Authorization: `Bearer ${authToken}`,
            "X-HTTP-Method-Override": "PUT",
          },
        }
      );
      alert("Cập nhật thành công!");
      navigate("/QuanlyDM");
    } catch (error) {
      console.error("Lỗi khi cập nhật danh mục:", error);
      alert("Cập nhật không thành công.");
    }
  };

  return (
    <form className="col-md-10 p-4" onSubmit={handleSave}>
      <div className="card shadow" style={{ borderRadius: "10px" }}>
        <div
          className="card-header text-white"
          style={{ backgroundColor: "#ffcc00", borderRadius: "10px" }}
        >
          <h4>Chỉnh sửa danh mục</h4>
        </div>
        <div className="card-body">
          <div className="mb-3">
            <label htmlFor="order">Thứ tự</label>
            <input
              type="text"
              className="form-control"
              value={order}
              onChange={(e) => setOrder(e.target.value)}
            />
          </div>
          <div className="mb-3">
            <label htmlFor="categoryName">Tên danh mục</label>
            <input
              type="text"
              className="form-control"
              value={categoryName}
              onChange={(e) => setCategoryName(e.target.value)}
            />
          </div>
          <div className="mb-3">
            <label htmlFor="categoryImage">Hình ảnh</label>
            <input type="file" className="form-control" onChange={handleImageChange} />
            {imagePreview && (
              <img
                src={imagePreview}
                alt="Preview"
                style={{ maxHeight: "200px", objectFit: "cover" }}
              />
            )}
          </div>
          <button type="submit" className="btn btn-orange">Lưu</button>
          <button
            type="button"
            className="btn btn-secondary"
            onClick={() => navigate("/QuanlyDM")}
          >
            Hủy
          </button>
        </div>
      </div>
    </form>
  );
};

export default EditDanhMuc;

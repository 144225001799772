import React, { useEffect, useState } from "react";
import "bootstrap/dist/css/bootstrap.min.css";
import "bootstrap-icons/font/bootstrap-icons.css";
import "bootstrap/dist/js/bootstrap.bundle.min.js"; // Bootstrap JavaScript
import "../../assets/css/style.css"; // Đường dẫn đến file CSS tùy chỉnh của bạn
import { Link, useParams } from "react-router-dom"; // Import Link từ react-router-dom và useParams để lấy id sản phẩm

const ChiTietAdmin = () => {
  const { id } = useParams(); // Lấy id từ URL
  const [product, setProduct] = useState(null); // Khai báo state để lưu sản phẩm
  const [loading, setLoading] = useState(true); // Trạng thái tải dữ liệu

  useEffect(() => {
    const fetchProductDetails = async () => {
      try {
        const response = await fetch(
          `https://ducps34770.id.vn/api/admin/sanpham/${id}`,
          {
            method: "GET", // Phương thức GET
            headers: {
              "Content-Type": "application/json",
              Authorization: `Bearer ${sessionStorage.getItem("authToken")}`, // Thêm token vào header
            },
          }
        );

        if (!response.ok) {
          throw new Error(`HTTP error! status: ${response.status}`);
        }

        const data = await response.json();
        console.log(data); // Kiểm tra dữ liệu nhận được từ API
        setProduct(data); // Lấy trực tiếp dữ liệu sản phẩm
        setLoading(false);
      } catch (error) {
        console.error("Error fetching product details:", error);
        setLoading(false);
        setProduct(null); // Nếu có lỗi, đặt product là null
      }
    };

    fetchProductDetails();
  }, [id]);

  if (loading) {
    return <div>Loading...</div>; // Hiển thị thông báo khi đang tải dữ liệu
  }

  if (!product) {
    return <div>Product not found</div>; // Hiển thị thông báo nếu không tìm thấy sản phẩm
  }

  return (
    <div className="col-md-10 p-4">
      <div className="comment">
        <div className="row mb-4">
          <div className="container mt-4">
            <div className="card shadow" style={{ borderRadius: "10px" }}>
              <div
                className="card-header text-white"
                style={{
                  backgroundColor: "#ffcc00",
                  borderTopLeftRadius: "10px",
                  borderTopRightRadius: "10px",
                }}
              >
                <h4 className="font-weight-bold mb-0">Sản phẩm</h4>
              </div>
              <div className="card-body">
                <form>
                  <div className="row">
                    <div className="col-md-6">
                      <img
                        src={product.hinh}
                        className="img-fluid rounded"
                        alt={product.ten_sp}
                      />
                    </div>
                    <div className="col-md-6">
                      <h5 className="font-weight-bold text-primary">
                        {product.ten_sp}
                      </h5>
                      <p className="card-text">
                        <strong>Mô Tả:</strong> {product.moTa}
                      </p>
                      <p className="card-text">
                        <strong>Giá:</strong>{" "}
                        <span className="text-danger font-weight-bold">
                          {product.gia_sp.toLocaleString()} VNĐ
                        </span>
                      </p>
                      <p className="card-text">
                        <strong>Danh Mục:</strong>{" "}
                        <span className="font-weight-normal">
                          {product.danhmuc ? product.danhmuc.loai : "Không có"}
                        </span>
                      </p>
                      <p className="card-text">
                        <strong>Loại Gỗ:</strong>{" "}
                        <span className="font-weight-normal">
                          {product.loai_go}
                        </span>
                      </p>
                      <p className="card-text">
                        <strong>Số Lượng Tồn:</strong>{" "}
                        <span className="font-weight-normal">
                          {product.soLuong}
                        </span>
                      </p>
                      <p className="card-text">
                        <strong>Nơi Xuất Xứ:</strong>{" "}
                        <span className="font-weight-normal">
                          {product.nsx ? product.nsx.ten_nsx : "Không có"}
                        </span>
                      </p>
                      <p className="card-text">
                        <strong>Nhà Cung Cấp:</strong>{" "}
                        <span className="font-weight-normal">
                          {product.nsx ? product.nsx.ten_nsx : "Không có"}
                        </span>
                      </p>
                      <p className="card-text">
                        <strong>Trạng Thái:</strong>{" "}
                        <span className="text-success font-weight-bold">
                          {product.anHien ? "Còn hàng" : "Hết hàng"}
                        </span>
                      </p>
                      <p className="card-text">
                        <strong>Ngày Thêm:</strong>{" "}
                        <span className="font-weight-normal">
                          {new Date(product.ngayDang).toLocaleDateString()}
                        </span>
                      </p>
                      <Link to="/productslist">
                        <button
                          className="btn btn-danger mt-2"
                          style={{ borderRadius: "5px" }}
                        >
                          Quay lại
                        </button>
                      </Link>
                      <Link to={`/editsp/${id}`}>
                        <button type="button" className="btn btn-primary mt-2">
                          Chỉnh sửa
                        </button>
                      </Link>
                    </div>
                  </div>
                </form>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default ChiTietAdmin;

import React, { useState, useEffect } from "react";
import axios from "axios";
import { useParams, useNavigate } from "react-router-dom";

const EditNSX = () => {
  const { id } = useParams();
  const navigate = useNavigate();

  const [order, setOrder] = useState("");
  const [manufacturerName, setManufacturerName] = useState("");
  const [visibility, setVisibility] = useState(null); // Giá trị mặc định là null
  const [initialVisibility, setInitialVisibility] = useState(null); // Lưu giá trị anHien ban đầu
  const [error, setError] = useState("");

  // Fetch manufacturer data when the component mounts
  useEffect(() => {
    const fetchManufacturer = async () => {
      try {
        const response = await axios.get(
          `https://ducps34770.id.vn/api/admin/nhasanxuat/${id}`,
          {
            headers: {
              Authorization: `Bearer ${sessionStorage.getItem("authToken")}`,
            },
          }
        );
        const { thuTu, ten_nsx, anHien } = response.data;
        setOrder(thuTu);
        setManufacturerName(ten_nsx);
        setVisibility(anHien);
        setInitialVisibility(anHien); // Lưu giá trị anHien ban đầu
      } catch (error) {
        console.error("Error fetching manufacturer:", error);
        setError("Lỗi khi lấy dữ liệu nhà sản xuất.");
      }
    };

    fetchManufacturer();
  }, [id]);

  // Handle save action
  const handleSave = async (e) => {
    e.preventDefault();

    // Nếu không thay đổi `visibility`, sử dụng giá trị ban đầu
    const updatedVisibility =
      visibility !== null ? visibility : initialVisibility;

    const updatedData = {
      thu_tu: order,
      ten_nsx: manufacturerName,
      an_hien: updatedVisibility,
    };

    try {
      await axios.put(
        `https://ducps34770.id.vn/api/admin/nhasanxuat/${id}`, // Đảm bảo rằng điểm cuối này đúng cho cập nhật
        updatedData,
        {
          headers: {
            Authorization: `Bearer ${sessionStorage.getItem("authToken")}`,
          },
        }
      );
      navigate("/quanlynsx"); // Điều hướng quay lại trang quản lý nhà sản xuất
    } catch (error) {
      console.error("Error updating manufacturer:", error);
      setError("Lỗi khi cập nhật nhà sản xuất.");
    }
  };

  return (
    <form className="col-md-10 p-4" onSubmit={handleSave}>
      <div className="comment">
        <div className="row mb-4">
          <div className="container mt-4">
            <div className="card shadow" style={{ borderRadius: "10px" }}>
              <div
                className="card-header text-white"
                style={{
                  backgroundColor: "#ffcc00",
                  borderTopLeftRadius: "10px",
                  borderTopRightRadius: "10px",
                }}
              >
                <h4 className="font-weight-bold mb-0">
                  Chỉnh sửa nhà sản xuất
                </h4>
              </div>
              <div className="card-body">
                {error && <div className="alert alert-danger">{error}</div>}
                <div className="mb-3">
                  <label htmlFor="order" className="form-label">
                    Thứ tự
                  </label>
                  <input
                    type="number"
                    className="form-control"
                    id="order"
                    placeholder="Chỉnh sửa thứ tự"
                    value={order}
                    onChange={(e) => setOrder(e.target.value)}
                    required
                  />
                </div>
                <div className="mb-3">
                  <label htmlFor="manufacturerName" className="form-label">
                    Tên nhà sản xuất
                  </label>
                  <input
                    type="text"
                    className="form-control"
                    id="manufacturerName"
                    placeholder="Chỉnh sửa tên nhà sản xuất"
                    value={manufacturerName}
                    onChange={(e) => setManufacturerName(e.target.value)}
                    required
                  />
                </div>
                <div className="mb-3">
                  <label htmlFor="manufacturerSelect" className="form-label">
                    Chọn trạng thái hiển thị
                  </label>
                  <select
                    className="form-select"
                    id="manufacturerSelect"
                    value={visibility !== null ? visibility : ""}
                    onChange={(e) => setVisibility(Number(e.target.value))}
                  >
                    <option value="" disabled>
                      Không thay đổi
                    </option>
                    <option value="1">Hiện</option>
                    <option value="0">Ẩn</option>
                  </select>
                </div>
                <div className="modal-footer">
                  <button type="submit" className="btn btn-sm btn-success">
                    Lưu
                  </button>
                  <button
                    type="button"
                    className="btn btn-secondary"
                    onClick={() => navigate("/quanlynsx")} // Điều hướng quay lại trang quản lý nhà sản xuất
                  >
                    Hủy
                  </button>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </form>
  );
};

export default EditNSX;

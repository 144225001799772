import React, { useState, useEffect } from "react";
import "bootstrap/dist/css/bootstrap.min.css";
import "bootstrap-icons/font/bootstrap-icons.css";
import "bootstrap/dist/js/bootstrap.bundle.min.js"; // Bootstrap JavaScript
import "../../assets/css/style.css";
import { useParams } from "react-router-dom"; // Import useParams

const Chitietdonhang = () => {
  const { id_dh } = useParams(); // Lấy id từ URL
  const [orderDetails, setOrderDetails] = useState(null);
  const [orderStatus, setOrderStatus] = useState(null); // Trạng thái đơn hàng
  const [paymentStatus, setPaymentStatus] = useState(null); // Trạng thái thanh toán

  useEffect(() => {
    // Fetch chi tiết đơn hàng từ API sử dụng id động
    const fetchOrderDetails = async () => {
      try {
        const authToken = sessionStorage.getItem("authToken");

        if (!authToken) {
          console.error("Token không tồn tại");
          return;
        }

        // Gửi request với authToken trong header để lấy chi tiết đơn hàng
        const response = await fetch(
          `https://ducps34770.id.vn/api/admin/detail_order/${id_dh}`,
          {
            method: "GET",
            headers: {
              Authorization: `Bearer ${authToken}`,
              "Content-Type": "application/json",
            },
          }
        );

        const data = await response.json();
        if (response.ok) {
          setOrderDetails(data); // Lưu dữ liệu vào state
          // Lấy trạng thái thanh toán từ API chi tiết đơn hàng
          setPaymentStatus(data.detail[0]?.thanhToan ?? null);
          console.log(data);
        } else {
          console.error("Lỗi khi lấy dữ liệu:", data);
        }
      } catch (error) {
        console.error("Lỗi khi lấy thông tin đơn hàng:", error);
      }
    };

    fetchOrderDetails();
  }, [id_dh]);

  // Fetch trạng thái đơn hàng từ API
  useEffect(() => {
    const fetchOrderStatus = async () => {
      try {
        const authToken = sessionStorage.getItem("authToken");

        if (!authToken) {
          console.error("Token không tồn tại");
          return;
        }

        const response = await fetch(
          `https://ducps34770.id.vn/api/admin/donhang/${id_dh}`,
          {
            method: "GET",
            headers: {
              Authorization: `Bearer ${authToken}`,
              "Content-Type": "application/json",
            },
          }
        );

        const data = await response.json();
        if (response.ok) {
          // Lấy trạng thái đơn hàng từ đối tượng con donhangs
          const trangThai = data[0]?.donhangs?.trangThai ?? null;
          setOrderStatus(trangThai);
          console.log("Trạng thái đơn hàng:", trangThai);
        } else {
          console.error("Lỗi khi lấy trạng thái đơn hàng:", data);
        }
      } catch (error) {
        console.error("Lỗi khi gọi API trạng thái đơn hàng:", error);
      }
    };

    fetchOrderStatus();
  }, [id_dh]);

  // Hàm cập nhật trạng thái đơn hàng và thanh toán
  const handleConfirmStatus = async () => {
    // Chuẩn bị dữ liệu cần gửi lên API, chỉ gửi những trường có thay đổi
    const updateData = {};

    if (orderStatus !== null) {
      updateData.trangThai = orderStatus;
    }

    if (paymentStatus !== null) {
      updateData.thanhToan = paymentStatus;
    }

    // Kiểm tra xem có dữ liệu để cập nhật không
    if (Object.keys(updateData).length === 0) {
      alert("Không có thay đổi nào để cập nhật!");
      return;
    }

    console.log("Gửi dữ liệu cập nhật:", updateData);

    try {
      const authToken = sessionStorage.getItem("authToken");

      if (!authToken) {
        console.error("Token không tồn tại");
        return;
      }
      const response = await fetch(
        `https://ducps34770.id.vn/api/admin/order/${id_dh}`,
        {
          method: "PUT",
          headers: {
            Authorization: `Bearer ${authToken}`,
            "Content-Type": "application/json",
          },
          body: JSON.stringify(updateData),
        }
      );

      const data = await response.json();

      if (response.ok) {
        console.log("Cập nhật thành công:", data);
        alert("Cập nhật thành công!");
      } else {
        console.error("Lỗi khi cập nhật:", data);
        alert("Cập nhật thất bại!");
      }
    } catch (error) {
      console.error("Lỗi khi gọi API:", error);
      alert("Đã xảy ra lỗi!");
    }
  };

  if (!orderDetails) {
    return <div>Loading...</div>; // Hiển thị loading khi chưa có dữ liệu
  }

  return (
    <div className="col-md-10 p-4">
      <div className="container mt-5">
        <form className="card mt-3">
          <div className="card-header">
            <h5 className="mb-0">Thông Tin Đơn Hàng</h5>
          </div>
          <div className="card-body">
            <table className="table table-responsive">
              <thead className="table-primary">
                <tr>
                  <th>#</th>
                  <th>Mã Đơn Hàng</th>
                  <th>Hình Sản Phẩm</th>
                  <th>Tên Sản Phẩm</th>
                  <th>Số Lượng</th>
                  <th>Giá</th>
                  <th>Tổng Giá</th>
                  {/* <th>Trạng Thái Thanh Toán</th> */}
                  <th>Ngày Đặt</th>
                </tr>
              </thead>
              <tbody>
                {orderDetails.detail &&
                  Array.isArray(orderDetails.detail) &&
                  orderDetails.detail.map((item, index) => (
                    <tr key={index}>
                      <td>{index + 1}</td>
                      <td>{item.id_ct}</td>
                      <td>
                        <img
                          src={item.hinh}
                          alt="Product Image"
                          className="small-product-img"
                        />
                      </td>
                      <td>{item.ten_sp}</td>
                      <td>{item.soLuong}</td>
                      <td>{item.gia_sp}</td>
                      <td>{item.thanh_tien}</td>
                      {/* <td>
                      <span className={`badge ${item.thanhToan === 1 ? 'bg-success' : 'bg-warning'}`}>
                        {item.thanhToan === 1 ? 'Đã Thanh Toán' : 'Chưa Thanh Toán'}
                      </span>
                    </td> */}
                      <td>{item.ngayNhan}</td>
                    </tr>
                  ))}
              </tbody>

              <tfoot>
                <tr>
                  <td colSpan="0" className="text-end">
                    <strong>Tổng Giá:</strong>
                  </td>
                  <td colSpan="1" className="text-end">
                    <strong>{orderDetails.total} VNĐ</strong>
                  </td>
                </tr>
              </tfoot>
            </table>
          </div>
        </form>

        {/* Trạng thái đơn hàng */}
        <div className="card mt-3">
          <div className="card-header">
            <h5 className="mb-0">Trạng Thái Đơn Hàng</h5>
          </div>
          <div className="card-body">
            <p>
              <strong>Trạng Thái Hiện Tại:</strong>{" "}
              <span
                className={`badge ${
                  orderStatus === 3 ? "bg-success" : "bg-warning"
                }`}
              >
                {orderStatus === 1
                  ? "Chuẩn bị hàng"
                  : orderStatus === 2
                  ? "Đang giao"
                  : orderStatus === 3
                  ? "Hoàn thành"
                  : "Chờ xác nhận"}
              </span>
            </p>

            <p>
              <strong>Chọn Trạng Thái Mới:</strong>
              <select
                value={orderStatus}
                onChange={(e) => setOrderStatus(e.target.value)}
                className="form-select mt-2"
              >
                <option value={0}>Chờ xác nhận</option>
                <option value={1}>Chuẩn bị hàng</option>
                <option value={2}>Đang giao</option>
                <option value={3}>Hoàn thành</option>
              </select>
            </p>
          </div>
        </div>

        {/* Trạng thái thanh toán */}
        <div className="card mt-3">
          <div className="card-header">
            <h5 className="mb-0">Trạng Thái Thanh Toán</h5>
          </div>
          <div className="card-body">
            <p>
              <strong>Trạng Thái Hiện Tại:</strong>{" "}
              <span
                className={`badge ${
                  paymentStatus === 0 ? "bg-warning" : "bg-success"
                }`}
              >
                {paymentStatus === 0 ? "Chưa Thanh Toán" : "Đã Thanh Toán"}
              </span>
            </p>

            <p>
              <strong>Chọn Trạng Thái Mới:</strong>
              <select
                value={paymentStatus}
                onChange={(e) => setPaymentStatus(e.target.value)}
                className="form-select mt-2"
              >
                <option value={0}>Chưa Thanh Toán</option>
                <option value={1}>Đã Thanh Toán</option>
              </select>
            </p>
          </div>
        </div>

        {/* Nút xác nhận */}
        <div className="text-center mt-3">
          <button className="btn btn-success" onClick={handleConfirmStatus}>
            Cập Nhật Trạng Thái
          </button>
        </div>
      </div>
    </div>
  );
};

export default Chitietdonhang;

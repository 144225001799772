import React, { useEffect, useState } from "react";
import { useParams, useNavigate } from "react-router-dom";
import "bootstrap/dist/css/bootstrap.min.css";
import "bootstrap-icons/font/bootstrap-icons.css";
import "bootstrap/dist/js/bootstrap.bundle.min.js";

const EditVoucher = () => {
  const { id_mgg } = useParams(); // Lấy id từ URL
  const navigate = useNavigate();

  const [voucher, setVoucher] = useState({
    id_mgg: "",
    ma_giam_gia: "",
    so_tien_giam: "",
    gioi_han_su_dung: "",
    an_hien: 0,
    ngay_bat_dau: "",
    ngay_het_han: "",
  });

  useEffect(() => {
    if (id_mgg) {
      fetchVoucherDetails();
    } else {
      console.error("Không có id_mgg trong URL");
      navigate("/voucheradmin"); // Điều hướng nếu không có id_mgg
    }
  }, [id_mgg]);

  const fetchVoucherDetails = async () => {
    try {
      const response = await fetch(
        "https://ducps34770.id.vn/api/admin/vouchers",
        {
          headers: {
            Authorization: `Bearer ${sessionStorage.getItem("authToken")}`, // Thêm token vào header
          },
        }
      );
      const data = await response.json();
      const selectedVoucher = data.vouchers.find(
        (v) => v.id_mgg === parseInt(id_mgg)
      );
      if (selectedVoucher) {
        setVoucher(selectedVoucher);
      } else {
        alert("Không tìm thấy voucher này.");
        navigate("/voucheradmin");
      }
    } catch (error) {
      console.error("Error fetching voucher details:", error);
      alert("Không thể tải thông tin voucher. Vui lòng thử lại sau.");
    }
  };

  const handleChange = (e) => {
    const { id, value } = e.target;
    setVoucher((prevVoucher) => ({
      ...prevVoucher,
      [id]: value,
    }));
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    console.log("Thông tin voucher đã được chỉnh sửa:", voucher);
    try {
      const response = await fetch(
        `https://ducps34770.id.vn/api/admin/vouchers/${id_mgg}`,
        {
          method: "PUT",
          headers: {
            "Content-Type": "application/json",
            Authorization: `Bearer ${sessionStorage.getItem("authToken")}`, // Thêm token vào header
          },
          body: JSON.stringify(voucher),
        }
      );
      if (response.ok) {
        alert("Voucher đã được cập nhật thành công"); // Thông báo thành công
        navigate("/voucher"); // Chuyển về trang danh sách voucher
      } else {
        const errorData = await response.json();
        alert(
          `Cập nhật voucher thất bại: ${
            errorData.message || "Vui lòng thử lại."
          }`
        ); // Thông báo thất bại
      }
    } catch (error) {
      console.error("Error updating voucher:", error);
      alert("Cập nhật voucher thất bại. Vui lòng thử lại."); // Thông báo lỗi
    }
  };

  return (
    <form className="col-md-10 p-4" onSubmit={handleSubmit}>
      <div className="container mt-4">
        <div className="card shadow" style={{ borderRadius: "10px" }}>
          <div
            className="card-header text-white"
            style={{
              backgroundColor: "#ffcc00",
              borderTopLeftRadius: "10px",
              borderTopRightRadius: "10px",
            }}
          >
            <h4 className="font-weight-bold mb-0">Chỉnh sửa voucher</h4>
          </div>
          <div className="card-body">
            <input
              type="hidden"
              id="id_mgg"
              value={voucher.id_mgg}
              onChange={handleChange}
            />
            <div className="mb-3">
              <label htmlFor="ma_giam_gia" className="form-label">
                Mã Voucher
              </label>
              <input
                type="text"
                className="form-control"
                id="ma_giam_gia"
                value={voucher.ma_giam_gia}
                onChange={handleChange}
                required
              />
            </div>
            <div className="mb-3">
              <label htmlFor="so_tien_giam" className="form-label">
                Giá Trị Giảm Giá (%)
              </label>
              <input
                type="text"
                className="form-control"
                id="so_tien_giam"
                value={voucher.so_tien_giam}
                onChange={handleChange}
                required
              />
            </div>
            <div className="mb-3">
              <label htmlFor="gioi_han_su_dung" className="form-label">
                Giới Hạn Sử Dụng
              </label>
              <input
                type="number"
                className="form-control"
                id="gioi_han_su_dung"
                value={voucher.gioi_han_su_dung}
                onChange={handleChange}
                required
              />
            </div>
            <div className="mb-3">
              <label htmlFor="ngay_bat_dau" className="form-label">
                Ngày Bắt Đầu
              </label>
              <input
                type="date"
                className="form-control"
                id="ngay_bat_dau"
                value={voucher.ngay_bat_dau}
                onChange={handleChange}
                required
              />
            </div>
            <div className="mb-3">
              <label htmlFor="ngay_het_han" className="form-label">
                Ngày Hết Hạn
              </label>
              <input
                type="date"
                className="form-control"
                id="ngay_het_han"
                value={voucher.ngay_het_han}
                onChange={handleChange}
                required
              />
            </div>
            <div className="mb-3">
              <label className="form-label">Trạng Thái</label>
              <select
                className="form-control"
                id="an_hien"
                value={voucher.an_hien}
                onChange={handleChange}
                required
              >
                <option value="1">Kích Hoạt</option>
                <option value="0">Ẩn</option>
              </select>
            </div>
            <button type="submit" className="btn btn-sm btn-primary">
              Lưu
            </button>
            <button
              type="button"
              className="btn btn-secondary"
              onClick={() => navigate("/voucheradmin")}
            >
              Hủy
            </button>
          </div>
        </div>
      </div>
    </form>
  );
};

export default EditVoucher;

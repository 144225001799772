import React, { useState } from "react";
import axios from "axios";
import "bootstrap/dist/css/bootstrap.min.css";
import "bootstrap-icons/font/bootstrap-icons.css";
import "@fortawesome/fontawesome-free/css/all.min.css";
import "bootstrap/dist/js/bootstrap.bundle.min.js";
import "../../assets/css/style.css";

const AddAdmin = () => {
  const [userData, setUserData] = useState({
    userImage: null,
    userName: "",
    email: "",
    userPhone: "",
    userAddress: "",
    userRole: "1", // Giá trị mặc định là "Người dùng"
    password: "",
  });

  const [message, setMessage] = useState({ type: "", content: "" });
  const [imagePreview, setImagePreview] = useState(null);
  const [loading, setLoading] = useState(false);

  const handleInputChange = (e) => {
    const { name, value, files } = e.target;
    if (name === "userImage") {
      const file = files[0];
      setUserData({ ...userData, userImage: file });
      setImagePreview(URL.createObjectURL(file)); // Tạo xem trước ảnh
    } else {
      setUserData({ ...userData, [name]: value });
    }
  };

  const validateInput = () => {
    const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
    if (!emailRegex.test(userData.email)) {
      setMessage({ type: "error", content: "Vui lòng nhập email hợp lệ." });
      return false;
    }

    if (!/^\d{10,11}$/.test(userData.userPhone)) {
      setMessage({
        type: "error",
        content: "Số điện thoại phải chứa từ 10 đến 11 chữ số.",
      });
      return false;
    }

    if (!userData.password || userData.password.length < 6) {
      setMessage({
        type: "error",
        content: "Mật khẩu phải chứa ít nhất 6 ký tự.",
      });
      return false;
    }

    setMessage({ type: "", content: "" }); // Xóa thông báo lỗi nếu không có lỗi
    return true;
  };

  const handleSubmit = async (e) => {
    e.preventDefault();

    if (!validateInput()) return; // Kiểm tra dữ liệu đầu vào

    setLoading(true); // Hiển thị loading
    const formData = new FormData();
    formData.append("name", userData.userName);
    formData.append("email_part1", userData.email.split("@")[0]); // Phần trước "@"
    formData.append("email_part2", userData.email.split("@")[1] || ""); // Phần sau "@"
    formData.append("phone", userData.userPhone);
    formData.append("address", userData.userAddress);
    formData.append("role", userData.userRole);
    formData.append("status", 0); // Trạng thái mặc định
    formData.append("password", userData.password);
    if (userData.userImage) {
      formData.append("hinh", userData.userImage); // Hình ảnh
    }

    try {
      const token =
        sessionStorage.getItem("authToken") ||
        localStorage.getItem("authToken");

      const response = await axios.post(
        "https://godatviet.ducps34770.id.vn/api/admin/user",
        formData,
        {
          headers: {
            "Content-Type": "multipart/form-data",
            Authorization: `Bearer ${token}`,
          },
        }
      );
      setMessage({ type: "success", content: "Thêm người dùng thành công!" });
      setUserData({
        userImage: null,
        userName: "",
        email: "",
        userPhone: "",
        userAddress: "",
        userRole: "1",
        password: "",
      });
      setImagePreview(null); // Xóa hình ảnh xem trước
    } catch (error) {
      setMessage({
        type: "error",
        content:
          error.response?.data?.message || "Đã xảy ra lỗi khi thêm người dùng.",
      });
    } finally {
      setLoading(false); // Tắt loading
    }
  };

  return (
    <div className="col-md-10 p-4">
      <div className="card shadow" style={{ borderRadius: "10px" }}>
        <div
          className="card-header text-white"
          style={{
            backgroundColor: "#ffcc00",
            borderTopLeftRadius: "10px",
            borderTopRightRadius: "10px",
          }}
        >
          <h4 className="font-weight-bold mb-0">Thêm admin</h4>
        </div>
        <div className="card-body">
          {message.content && (
            <div
              className={`alert alert-${
                message.type === "success" ? "success" : "danger"
              }`}
              role="alert"
            >
              {message.content}
            </div>
          )}
          <form onSubmit={handleSubmit}>
            <div className="mb-3">
              <label htmlFor="userImage" className="form-label">
                Hình Ảnh
              </label>
              <input
                type="file"
                className="form-control"
                name="userImage"
                onChange={handleInputChange}
                accept="image/*"
              />
              {imagePreview && (
                <div className="mt-2">
                  <img
                    src={imagePreview}
                    alt="Preview"
                    style={{ width: "100px", borderRadius: "10px" }}
                  />
                </div>
              )}
            </div>
            <div className="mb-3">
              <label htmlFor="userName" className="form-label">
                Tên Người Dùng
              </label>
              <input
                type="text"
                className="form-control"
                name="userName"
                value={userData.userName}
                onChange={handleInputChange}
                required
              />
            </div>
            <div className="mb-3">
              <label htmlFor="email" className="form-label">
                Email
              </label>
              <input
                type="email"
                className="form-control"
                name="email"
                value={userData.email}
                onChange={handleInputChange}
                required
              />
            </div>
            <div className="mb-3">
              <label htmlFor="userPhone" className="form-label">
                Số Điện Thoại
              </label>
              <input
                type="tel"
                className="form-control"
                name="userPhone"
                value={userData.userPhone}
                onChange={handleInputChange}
                required
              />
            </div>
            <div className="mb-3">
              <label htmlFor="userAddress" className="form-label">
                Địa Chỉ
              </label>
              <input
                type="text"
                className="form-control"
                name="userAddress"
                value={userData.userAddress}
                onChange={handleInputChange}
                required
              />
            </div>
            <div className="mb-3">
              <label htmlFor="userRole" className="form-label">
                Vai Trò
              </label>
              <select
                name="userRole"
                className="form-select"
                value={userData.userRole}
                onChange={handleInputChange}
                required
              >
                <option value="1">Người dùng</option>
                <option value="2">Nhân viên</option>
                <option value="3">Shipper</option>
              </select>
            </div>
            <div className="mb-3">
              <label htmlFor="password" className="form-label">
                Mật khẩu
              </label>
              <input
                type="password"
                className="form-control"
                name="password"
                value={userData.password}
                onChange={handleInputChange}
                required
              />
            </div>
            <button
              type="submit"
              className="btn btn-success"
              disabled={loading}
            >
              {loading ? "Đang xử lý..." : "Thêm Người Dùng"}
            </button>
            <a href="#" className="btn btn-secondary ms-2">
              Hủy
            </a>
          </form>
        </div>
      </div>
    </div>
  );
};

export default AddAdmin;

import React, { useState, useEffect } from "react";
import { useNavigate } from "react-router-dom"; // Import useNavigate
import "bootstrap/dist/css/bootstrap.min.css";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faStar } from "@fortawesome/free-solid-svg-icons";
import {  toast } from "react-toastify";
const Comment = () => {
  const [comments, setComments] = useState([]);
  const [searchQuery, setSearchQuery] = useState(""); // State for search input
  const [visibleReplies, setVisibleReplies] = useState({});
  const [dateFrom, setDateFrom] = useState(""); // State for date filter from
  const [dateTo, setDateTo] = useState(""); // State for date filter to
  const navigate = useNavigate(); // Khởi tạo useNavigate

  // Kiểm tra token khi component được mount
  useEffect(() => {
    const token = sessionStorage.getItem("authToken");
    console.log("Token:", token); 
    if (!token) {
      console.error("Token không tồn tại. Vui lòng đăng nhập lại.");
      navigate("/adminlogin"); // Chuyển hướng đến trang đăng nhập
    }
  }, [navigate]);

  const handleDeleteComment = async (commentId) => {
    const token = sessionStorage.getItem("authToken");
    try {
      const response = await fetch(`https://ducps34770.id.vn/api/admin/binhluan/${commentId}`, {
        method: "DELETE",
        headers: {
          Authorization: `Bearer ${token}`,
          "Content-Type": "application/json",
        },
      });

      if (response.ok) {
        toast.success("Xóa bình luận thành công!");
        // Cập nhật lại danh sách bình luận
        setComments(comments.filter(comment => comment.id_bl !== commentId));
      } else {
        throw new Error("Lỗi khi xóa bình luận, vui lòng thử lại.");
      }
    } catch (error) {
      toast.error(`Lỗi: ${error.message}`);
    }
  };
  // Fetch comments from API
  useEffect(() => {
    const fetchComments = async () => {
      const token = sessionStorage.getItem("authToken"); // Lấy token từ sessionStorage
      console.log("Token:", token); 
      try {
        const response = await fetch(
          "https://ducps34770.id.vn/api/admin/binhluan",
          {
            method: "GET",
            headers: {
              Authorization: `Bearer ${token}`,
              "Content-Type": "application/json",
            },
          }
        );
        // console.log("Response status:", response.status); // Kiểm tra status
        // console.log("Response body:", await response.text());
        if (!response.ok) {
          throw new Error(`HTTP error! status: ${response.status}`);
        }

        const data = await response.json();
        setComments(data); // Set comments to the fetched data
      } catch (error) {
        console.error("Error fetching comments:", error);
      }
    };
    fetchComments();
  }, []);

  // Toggle the reply form visibility
  const toggleReplyForm = (commentId) => {setVisibleReplies((prev) => ({
      ...prev,
      [commentId]: !prev[commentId],
    }));
  };

  // Handle search change
  const handleSearchChange = (event) => {
    setSearchQuery(event.target.value);
  };

  // Handle date filters
  const handleDateFromChange = (event) => {
    setDateFrom(event.target.value);
  };

  const handleDateToChange = (event) => {
    setDateTo(event.target.value);
  };

  // Handle reply submission
  // Handle reply submission
// Handle reply submission
const handleReplySubmit = async (e, commentId) => {
  e.preventDefault(); // Ngăn chặn việc gửi form mặc định

  const userInfo = JSON.parse(sessionStorage.getItem("userInfo"));
  console.log("User  Info:", userInfo); // Kiểm tra đối tượng userInfo

  if (!userInfo || !userInfo.id) {
    toast.error("Không tìm thấy thông tin người dùng. Vui lòng đăng nhập lại.");
    navigate("/adminlogin"); // Chuyển hướng đến trang đăng nhập
    return;
  }

  const replyContent = e.target.replyContent.value; // Lấy nội dung trả lời

  const replyData = {
    id_bl: commentId, // ID của bình luận
    phanhoi: replyContent, // Nội dung phản hồi
    id_nd: userInfo.id, // Lấy id người dùng từ đối tượng userInfo
  };

  console.log("Reply Data:", replyData); // Kiểm tra dữ liệu gửi đi

  try {
    const response = await fetch("http://localhost:8000/api/admin/binhluan", {
      method: "POST",
      headers: {
        Authorization: `Bearer ${sessionStorage.getItem("authToken")}`,
        "Content-Type": "application/json",
      },
      body: JSON.stringify(replyData), // Gửi dữ liệu dưới dạng JSON
    });

    if (response.ok) {
      const data = await response.json();
      console.log("Trả lời thành công:", data);
      toast.success("Trả lời bình luận thành công!");
      // Cập nhật lại danh sách bình luận hoặc thực hiện hành động khác ở đây
    } else {
      throw new Error("Lỗi khi gửi trả lời, vui lòng thử lại.");
    }
  } catch (error) {
    toast.error(`Lỗi: ${error.message}`);
  }
};

  // Filter comments based on search query and date range
  const filteredComments = comments.filter((comment) => {
    // Filter by search query
    const matchesSearchQuery =
      comment.ten_nd.toLowerCase().includes(searchQuery.toLowerCase()) ||
      comment.noiDung.toLowerCase().includes(searchQuery.toLowerCase());

    // Filter by date range
    const commentDate = new Date(comment.ngayDang);
    const isDateInRange =
      (!dateFrom || commentDate >= new Date(dateFrom)) &&
      (!dateTo || commentDate <= new Date(dateTo));

    return matchesSearchQuery && isDateInRange;
  });

  return (
    <div className="col-md-10 p-4">
      {/* Cards */}
      <div className="row mb-4">
        {/* Total Comments */}
        <div className="col-lg-3 col-md-6 mb-4">
          <form action="/binhluan" method="GET">
            <input type="hidden" name="filter" value="all" /> <div className="card text-white bg-success">
              <div className="card-body d-flex justify-content-between align-items-center">
                <div>
                  <h5 className="card-title">Tổng Bình Luận</h5>
                  <p className="card-text">Bình luận</p>
                </div>
                <i className="fa-regular fa-comments"></i>
              </div>
              <div className="row mb-2">
                <div className="mt-2 text-end">
                  <button type="submit" className="btn btn-light">
                    Xem Bình Luận
                  </button>
                </div>
              </div>
            </div>
          </form>
        </div>

        {/* Comments with Replies */}
        <div className="col-lg-3 col-md-6 mb-4">
          <form action="/binhluan" method="GET">
            <input type="hidden" name="filter" value="replied" />
            <div className="card text-white bg-danger">
              <div className="card-body d-flex justify-content-between align-items-center">
                <div>
                  <h5 className="card-title">Đã Trả Lời</h5>
                  <p className="card-text">Bình luận</p>
                </div>
                <i className="fa-regular fa-comments"></i>
              </div>
              <div className="row mb-2">
                <div className="mt-2 text-end">
                  <button type="submit" className="btn btn-light">
                    Xem Bình Luận
                  </button>
                </div>
              </div>
            </div>
          </form>
        </div>

        {/* Unreplied Comments */}
        <div className="col-lg-3 col-md-6 mb-4">
          <form action="/binhluan" method="GET">
            <input type="hidden" name="filter" value="unreplied" />
            <div className="card text-white bg-info">
              <div className="card-body d-flex justify-content-between align-items-center">
                <div>
                  <h5 className="card-title">Chưa trả lời</h5>
                  <p className="card-text">Bình luận</p>
                </div>
                <i className="fa-regular fa-comments"></i>
              </div>
              <div className="row mb-2">
                <div className="mt-2 text-end">
                  <button type="submit" className="btn btn-light">
                    Xem Bình Luận
                  </button>
                </div>
              </div>
            </div>
          </form>
        </div>

        {/* Review Count */}
        <div className="col-lg-3 col-md-6 mb-4">
          <form action="/binhluan/show/0" method="GET">
            <div className="card text-white bg-warning">
              <div className="card-body d-flex justify-content-between align-items-center">
                <div>
                  <h5 className="card-title">Lượt đánh giá</h5>
                  <p className="card-text">Bình luận</p>
                </div><i className="bi bi-star fs-1"></i>
              </div>
              <div className="row mb-2">
                <div className="mt-2 text-end">
                  <button type="submit" className="btn btn-light">
                    Xem Bình Luận
                  </button>
                </div>
              </div>
            </div>
          </form>
      
        </div>
      </div>

      {/* Date and Search Filters */}
      <form
        action="/binhluan"
        method="GET"
        className="row mb-4 align-items-end"
      >
        <div className="col-lg-4">
          <label htmlFor="ngay_from" className="form-label">
            Chọn Ngày Bắt Đầu:
          </label>
          <input
            type="date"
            name="ngay_from"
            className="form-control"
            id="ngay_from"
            value={dateFrom}
            onChange={handleDateFromChange} // Handle date change
          />
        </div>
        <div className="col-lg-4">
          <label htmlFor="ngay_to" className="form-label">
            Chọn Ngày Kết Thúc:
          </label>
          <div className="d-flex align-items-end">
            <input
              type="date"
              name="ngay_to"
              className="form-control"
              id="ngay_to"
              value={dateTo}
              onChange={handleDateToChange} // Handle date change
            />
            <button type="submit" className="btn btn-primary ms-2">
              Xem
            </button>
          </div>
        </div>

        {/* Search Input */}
        <div className="col-lg-4 d-flex justify-content-end align-items-end">
          <div className="d-flex">
            <input
              type="text"
              className="form-control"
              placeholder="Tìm kiếm"
              value={searchQuery}
              onChange={handleSearchChange} // Handle search input change
            />
            <button type="submit" className="btn btn-primary ms-2">
              Tìm
            </button>
          </div>
        </div>
      </form>

      {/* Comments Display */}
      <div className="comment-container">
        <div className="comment">
          <div className="row mb-4">
            <div className="comment-section">
              <div className="list-group">
                {filteredComments.map((comment) => (
                  <div className="list-group-item" key={comment.id_bl}>
                    <div className="row">
                      <div className="col-md-1 d-flex align-items-center">
                        <img
                          src={comment.hinh_bl || "../default-avatar.jpg"}
                          className="img-comment"
                          alt="Avatar"
                        />
                      </div>
                      <div className="col-md-9">
                        <div className="comment-header">
                          <h5>{comment.ten_nd}</h5><small>
                            {new Date(comment.ngayDang).toLocaleString()}
                          </small>
                        </div>
                        <p>{comment.noiDung}</p>
                        <div className="col-lg-3 col-md-6 mb-4">
                          {[...Array(comment.danhgia)].map((_, index) => (
                            <FontAwesomeIcon
                              key={index}
                              icon={faStar}
                              className="text-warning"
                            />
                          ))}
                        </div>

                        <button
                          className="btn btn-sm btn-primary"
                          onClick={() => toggleReplyForm(comment.id_bl)}
                        >
                          Trả lời
                        </button>
                        <button
                          className="btn btn-sm btn-danger ms-2"
                          onClick={() => handleDeleteComment(comment.id_bl)}
                        >
                          Xóa
                        </button>
                        {visibleReplies[comment.id_bl] && (
                          <form
                            onSubmit={(e) =>
                              handleReplySubmit(e, comment.id_bl)
                            }
                            className="mt-3"
                          >
                            <textarea
                              name="replyContent"
                              className="form-control"
                              placeholder="Nhập trả lời..."
                              rows="3"
                              required
                            ></textarea>
                            <button
                              type="submit"
                              className="btn btn-primary mt-2"
                            >
                              Gửi trả lời
                            </button>
                          </form>
                        )}
                      </div>
                    </div>
                  </div>
                ))}
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Comment;
import React, { useState } from "react";
import { useNavigate } from "react-router-dom";
import "bootstrap/dist/css/bootstrap.min.css";
import "bootstrap-icons/font/bootstrap-icons.css";
import "bootstrap/dist/js/bootstrap.bundle.min.js";
import "../../assets/css/style.css";

const AddVoucher = () => {
  const [voucherCode, setVoucherCode] = useState("");
  const [price, setPrice] = useState("");
  const [quantity, setQuantity] = useState("");
  const [startDate, setStartDate] = useState("");
  const [endDate, setEndDate] = useState("");
  const navigate = useNavigate();

  const handleAddVoucher = async (e) => {
    e.preventDefault();
    try {
      const response = await fetch(
        "https://ducps34770.id.vn/api/admin/vouchers",
        {
          method: "POST",
          headers: {
            "Content-Type": "application/json",
            Authorization: `Bearer ${sessionStorage.getItem("authToken")}`, // Thêm token vào header
          },
          body: JSON.stringify({
            ma_giam_gia: voucherCode,
            so_tien_giam: price,
            gioi_han_su_dung: quantity,
            an_hien: 1, // hoặc 0 nếu muốn voucher không hiển thị
            ngay_bat_dau: startDate,
            ngay_het_han: endDate,
          }),
        }
      );

      if (response.ok) {
        alert("Voucher đã được thêm thành công!");
        navigate("/voucher");
      } else {
        const errorData = await response.json();
        alert(
          `Đã xảy ra lỗi khi thêm voucher: ${
            errorData.message || "Vui lòng thử lại."
          }`
        );
      }
    } catch (error) {
      console.error("Error adding voucher:", error);
      alert("Đã xảy ra lỗi. Vui lòng kiểm tra console để biết thêm chi tiết.");
    }
  };

  return (
    <div className="col-md-10 p-4">
      <div className="comment">
        <div className="row mb-4">
          <div className="container mt-4">
            <div className="card shadow" style={{ borderRadius: "10px" }}>
              <div
                className="card-header text-white"
                style={{
                  backgroundColor: "#ffcc00",
                  borderTopLeftRadius: "10px",
                  borderTopRightRadius: "10px",
                }}
              >
                <h4 className="font-weight-bold mb-0">Thêm voucher</h4>
              </div>
              <div className="card-body">
                <form onSubmit={handleAddVoucher}>
                  <div className="mb-3">
                    <label htmlFor="voucherCode" className="form-label">
                      Mã Voucher
                    </label>
                    <input
                      type="text"
                      className="form-control"
                      id="voucherCode"
                      value={voucherCode}
                      onChange={(e) => setVoucherCode(e.target.value)}
                      required
                    />
                  </div>
                  <div className="mb-3">
                    <label htmlFor="price" className="form-label">
                      Giá
                    </label>
                    <input
                      type="text"
                      className="form-control"
                      id="price"
                      value={price}
                      onChange={(e) => setPrice(e.target.value)}
                      required
                    />
                  </div>
                  <div className="mb-3">
                    <label htmlFor="quantity" className="form-label">
                      Số Lượng
                    </label>
                    <input
                      type="number"
                      className="form-control"
                      id="quantity"
                      value={quantity}
                      onChange={(e) => setQuantity(e.target.value)}
                      required
                    />
                  </div>
                  <div className="mb-3">
                    <label htmlFor="startDate" className="form-label">
                      Ngày Bắt Đầu
                    </label>
                    <input
                      type="date"
                      className="form-control"
                      id="startDate"
                      value={startDate}
                      onChange={(e) => setStartDate(e.target.value)}
                      required
                    />
                  </div>
                  <div className="mb-3">
                    <label htmlFor="endDate" className="form-label">
                      Ngày Kết Thúc
                    </label>
                    <input
                      type="date"
                      className="form-control"
                      id="endDate"
                      value={endDate}
                      onChange={(e) => setEndDate(e.target.value)}
                      required
                    />
                  </div>
                  <button type="submit" className="btn btn-primary">
                    Thêm Voucher
                  </button>
                  <button
                    type="button"
                    className="btn btn-secondary"
                    onClick={() => navigate("/voucheradmin")}
                  >
                    Hủy
                  </button>
                </form>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default AddVoucher;

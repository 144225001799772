import React, { useState } from "react";
import { useNavigate } from "react-router-dom";
import "bootstrap/dist/css/bootstrap.min.css";
import "bootstrap-icons/font/bootstrap-icons.css";
import "bootstrap/dist/js/bootstrap.bundle.min.js";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";

const AddBaiViet = () => {
  const [postTitle, setPostTitle] = useState("");
  const [author, setAuthor] = useState("");
  const [postDate, setPostDate] = useState("");
  const [postContent, setPostContent] = useState("");
  const [category, setCategory] = useState("SEO");
  const [postImage, setPostImage] = useState(null);
  const navigate = useNavigate();

  const handleAddPost = async (e) => {
    e.preventDefault();

    // Lấy dữ liệu userInfo từ sessionStorage và parse thành đối tượng JSON
    const userInfo = JSON.parse(sessionStorage.getItem("userInfo"));
    console.log("User  Info:", userInfo); // Kiểm tra đối tượng userInfo

    if (!userInfo || !userInfo.id) {
      toast.error(
        "Không tìm thấy thông tin người dùng. Vui lòng đăng nhập lại."
      );
      return;
    }

    // Tạo một đối tượng FormData để gửi dữ liệu
    const postData = new FormData();
    postData.append("tieu_de", postTitle);
    postData.append("tac_gia", author);
    postData.append("ngay_dang", postDate);
    postData.append("noi_dung", postContent);
    postData.append("id_nd", userInfo.id); // Lấy id người dùng từ đối tượng userInfo
    if (postImage) {
      postData.append("hinh", postImage); // Gửi hình ảnh
    }

    console.log("Post Data:", postData); // Kiểm tra dữ liệu gửi đi

    try {
      const response = await fetch(
        "https://ducps34770.id.vn/api/admin/baiviet",
        {
          method: "POST",
          body: postData, // Gửi dữ liệu dưới dạng FormData
        }
      );

      if (response.ok) {
        toast.success("Bài viết đã được thêm thành công!");
        navigate("/BaiViet");
      } else {
        throw new Error("Lỗi khi thêm bài viết, vui lòng thử lại.");
      }
    } catch (error) {
      toast.error(`Lỗi: ${error.message}`);
    }
  };

  return (
    <div className="col-md-10">
      <div className="ol-md-10 p-4">
        <div className="row mb-4">
          <div className="container mt-4">
            <div className="card shadow" style={{ borderRadius: "10px" }}>
              <div
                className="card-header text-white"
                style={{
                  backgroundColor: "#ffcc00",
                  borderTopLeftRadius: "10px",
                  borderTopRightRadius: "10px",
                }}
              >
                <h4 className="font-weight-bold mb-0">Thêm bài viết</h4>
              </div>
              <div className="card-body">
                <form onSubmit={handleAddPost}>
                  <div className="mb-3">
                    <label htmlFor="postTitle" className="form-label">
                      {" "}
                      Tiêu Đề Bài Viết
                    </label>
                    <input
                      type="text"
                      className="form-control"
                      id="postTitle"
                      value={postTitle}
                      onChange={(e) => setPostTitle(e.target.value)}
                      placeholder="Nhập tiêu đề bài viết"
                      required
                    />
                  </div>

                  <div className="mb-3">
                    <label htmlFor="author" className="form-label">
                      Tác Giả
                    </label>
                    <input
                      type="text"
                      className="form-control"
                      id="author"
                      value={author}
                      onChange={(e) => setAuthor(e.target.value)}
                      placeholder="Nhập tên tác giả"
                      required
                    />
                  </div>

                  <div className="mb-3">
                    <label htmlFor="postDate" className="form-label">
                      Ngày Đăng
                    </label>
                    <input
                      type="date"
                      className="form-control"
                      id="postDate"
                      value={postDate}
                      onChange={(e) => setPostDate(e.target.value)}
                      required
                    />
                  </div>

                  <div className="mb-3">
                    <label htmlFor="postContent" className="form-label">
                      Nội Dung Bài Viết
                    </label>
                    <textarea
                      className="form-control"
                      id="postContent"
                      value={postContent}
                      onChange={(e) => setPostContent(e.target.value)}
                      rows="5"
                      placeholder="Nhập nội dung bài viết"
                      required
                    ></textarea>
                  </div>

                  <div className="mb-3">
                    <label htmlFor="category" className="form-label">
                      Danh Mục
                    </label>
                    <select
                      className="form-select"
                      id="category"
                      value={category}
                      onChange={(e) => setCategory(e.target.value)}
                    >
                      <option value="SEO">SEO</option>
                      <option value="Angular">Angular</option>
                      <option value="React">React</option>
                      <option value="Khác">Khác</option>
                    </select>
                  </div>

                  <div className="mb-3">
                    <label htmlFor="postImage" className="form-label">
                      Hình Ảnh Bài Viết{" "}
                    </label>
                    <input
                      type="file"
                      className="form-control"
                      id="postImage"
                      accept="image/*"
                      onChange={(e) => setPostImage(e.target.files[0])}
                    />
                  </div>

                  <button type="submit" className="btn btn-primary">
                    Thêm Bài Viết
                  </button>
                  <button
                    type="button"
                    className="btn btn-secondary"
                    onClick={() => navigate("/BaiViet")}
                  >
                    Hủy
                  </button>
                </form>
              </div>
            </div>
          </div>
        </div>
      </div>
      <ToastContainer />
    </div>
  );
};

export default AddBaiViet;

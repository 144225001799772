import React, { useState, useEffect } from "react";
import axios from "axios";
import { useParams } from "react-router-dom";
import "bootstrap/dist/css/bootstrap.min.css";
import "bootstrap-icons/font/bootstrap-icons.css";
import "@fortawesome/fontawesome-free/css/all.min.css";
import "bootstrap/dist/js/bootstrap.bundle.min.js";
import "../../assets/css/style.css";

const EditAdmin = () => {
  const { id } = useParams(); // Lấy id từ params
  const [userData, setUserData] = useState({
    userImage: null,
    userName: "",
    first_email: "",
    userPhone: "",
    userAddress: "",
    userRole: "0", // Giá trị mặc định là Admin
    password: "",
  });

  useEffect(() => {
    // Lấy thông tin người dùng từ API khi id thay đổi
    const fetchUserData = async () => {
      try {
        const response = await axios.get(
          `https://ducps34770.id.vn/api/admin/user/${id}`,
          {
            headers: {
              Authorization: `Bearer ${sessionStorage.getItem("authToken")}`, // Thêm token vào header
            },
          }
        );
        const user = response.data.user;
        setUserData({
          userImage: user.image, // Lưu tên file ảnh
          userName: user.name,
          first_email: user.first_email,
          userPhone: user.phone,
          userAddress: user.address,
          userRole: user.role.toString(), // Giả sử API trả về role là số
          password: "", // Không hiển thị mật khẩu trong form
        });
      } catch (error) {
        console.error("Error fetching user data:", error);
      }
    };

    fetchUserData();
  }, [id]);

  const handleInputChange = (e) => {
    const { name, value, files } = e.target;
    if (name === "userImage" && files.length > 0) {
      setUserData({ ...userData, userImage: files[0].name });
    } else {
      setUserData({ ...userData, [name]: value });
    }
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    const data = {
      name: userData.userName,
      first_email: userData.first_email,
      phone: userData.userPhone,
      address: userData.userAddress,
      role: userData.userRole, // Gửi giá trị role là 0, 1, hoặc 2
      status: true,
      password: userData.password,
      image: userData.userImage, // Gửi tên file ảnh
    };

    console.log("Dữ liệu gửi đi:", data);

    try {
      const response = await axios.put(
        `https://ducps34770.id.vn/api/admin/user/${id}`,
        data,
        {
          headers: {
            "Content-Type": "application/json",
            Authorization: `Bearer ${sessionStorage.getItem("authToken")}`, // Thêm token vào header
          },
        }
      );
      console.log("User updated successfully:", response.data);
    } catch (error) {
      console.error("Error updating user:", error.response?.data || error);
    }
  };

  return (
    <div className="col-md-10 p-4">
      <div className="card shadow" style={{ borderRadius: "10px" }}>
        <div
          className="card-header text-white"
          style={{
            backgroundColor: "#ffcc00",
            borderTopLeftRadius: "10px",
            borderTopRightRadius: "10px",
          }}
        >
          <h4 className="font-weight-bold mb-0">Chỉnh sửa người dùng</h4>
        </div>
        <div className="card-body">
          <form onSubmit={handleSubmit}>
            <div className="mb-3">
              <label htmlFor="userImage" className="form-label">
                Hình Ảnh
              </label>
              <input
                type="file"
                className="form-control"
                name="userImage"
                onChange={handleInputChange}
                accept="image/*"
              />
              {userData.userImage && (
                <div className="mt-2">
                  <img
                    src={`http://127.0.0.1:8000/storage/${userData.userImage}`} // Hiển thị ảnh từ backend
                    alt="User"
                    style={{
                      width: "100px",
                      height: "100px",
                      objectFit: "cover",
                    }}
                  />
                </div>
              )}
            </div>
            <div className="mb-3">
              <label htmlFor="userName" className="form-label">
                Tên Người Dùng
              </label>
              <input
                type="text"
                className="form-control"
                name="userName"
                value={userData.userName}
                onChange={handleInputChange}
                required
              />
            </div>
            <div className="mb-3">
              <label htmlFor="first_email" className="form-label">
                Email (Phần trước @)
              </label>
              <input
                type="text"
                className="form-control"
                name="first_email"
                value={userData.first_email}
                onChange={handleInputChange}
                required
              />
            </div>
            <div className="mb-3">
              <label htmlFor="userPhone" className="form-label">
                Số Điện Thoại
              </label>
              <input
                type="tel"
                className="form-control"
                name="userPhone"
                value={userData.userPhone}
                onChange={handleInputChange}
                required
              />
            </div>
            <div className="mb-3">
              <label htmlFor="userAddress" className="form-label">
                Địa Chỉ
              </label>
              <input
                type="text"
                className="form-control"
                name="userAddress"
                value={userData.userAddress}
                onChange={handleInputChange}
                required
              />
            </div>
            <div className="mb-3">
              <label htmlFor="userRole" className="form-label">
                Vai Trò
              </label>
              <select
                className="form-select"
                name="userRole"
                value={userData.userRole}
                onChange={handleInputChange}
                required
              >
                <option value="0">Admin</option>
                <option value="1">User</option>
                <option value="2">Nhân viên</option>
              </select>
            </div>
            <div className="mb-3">
              <label htmlFor="password" className="form-label">
                Mật Khẩu
              </label>
              <input
                type="password"
                className="form-control"
                name="password"
                value={userData.password}
                onChange={handleInputChange}
                placeholder="Nhập mật khẩu mới nếu muốn thay đổi"
              />
            </div>
            <button type="submit" className="btn btn-success me-2">
              Lưu Thay Đổi
            </button>
            <button
              type="button"
              className="btn btn-secondary"
              onClick={() => window.history.back()}
            >
              Hủy
            </button>
          </form>
        </div>
      </div>
    </div>
  );
};

export default EditAdmin;

import React, { useEffect, useState } from "react";
import "../../assets/css/style.css";
import "bootstrap/dist/css/bootstrap.min.css";
import "bootstrap-icons/font/bootstrap-icons.css";
import "bootstrap/dist/js/bootstrap.bundle.min.js"; // Bootstrap JavaScript
import { Link, useLocation, useNavigate } from "react-router-dom";

const BaiViet = () => {
  const [baiviets, setBaiviets] = useState([]);
  const [baivietCount, setBaivietCount] = useState(0);
  const [baivietNowCount, setBaivietNowCount] = useState(0);
  const [baivietYesterdayCount, setBaivietYesterdayCount] = useState(0);
  const [baivietMonthCount, setBaivietMonthCount] = useState(0);
  const [searchTerm, setSearchTerm] = useState(""); // State để lưu giá trị tìm kiếm
  const [showDeleteModal, setShowDeleteModal] = useState(false); // State để điều khiển việc hiển thị modal
  const [baivietToDelete, setBaivietToDelete] = useState(null); // Lưu trữ bài viết sẽ bị xóa
  const [authToken, setAuthToken] = useState(""); // State để lưu token

  const navigate = useNavigate();
  const location = useLocation();
  const queryParams = new URLSearchParams(location.search);
  const filter = queryParams.get("filter");

  // Lấy token từ sessionStorage khi component mount
  useEffect(() => {
    const token = sessionStorage.getItem("authToken");
    if (token) {
      setAuthToken(token); // Lưu token vào state
    } else {
      console.error("Auth token không tồn tại trong sessionStorage.");
    }
  }, []);

  // Hàm tìm kiếm
  const handleSearchChange = (e) => {
    setSearchTerm(e.target.value); // Cập nhật giá trị tìm kiếm
  };

  const handleSearchSubmit = (e) => {
    e.preventDefault();
    // Cập nhật URL với tham số tìm kiếm
    if (searchTerm) {
      navigate(`/baiviet?filter=${searchTerm}`); // Điều hướng đến URL mới với filter tìm kiếm
    } else {
      navigate("/baiviet"); // Nếu không có tìm kiếm, quay lại trang mặc định
    }
  };

  useEffect(() => {
    const fetchBaiviets = async (filters = {}) => {
      try {
        const queryParams = new URLSearchParams(filters).toString();
        const response = await fetch(
          `https://ducps34770.id.vn/api/admin/baiviet?${queryParams}`,
          {
            headers: {
              Authorization: `Bearer ${authToken}`, // Gửi token trong header
            },
          }
        );
        if (!response.ok) {
          throw new Error("Network response was not ok");
        }
        const data = await response.json();
        setBaiviets(data);
        setBaivietCount(data.length);
        setBaivietNowCount(
          data.filter(
            (b) =>
              new Date(b.ngay_dang).toDateString() === new Date().toDateString()
          ).length
        );
        setBaivietYesterdayCount(
          data.filter((b) => {
            const yesterday = new Date();
            yesterday.setDate(yesterday.getDate() - 1);
            return (
              new Date(b.ngay_dang).toDateString() === yesterday.toDateString()
            );
          }).length
        );
        setBaivietMonthCount(
          data.filter(
            (b) => new Date(b.ngay_dang).getMonth() === new Date().getMonth()
          ).length
        );
      } catch (error) {
        console.error("Error fetching data:", error);
      }
    };

    if (authToken) {
      fetchBaiviets({ filter });
    }
  }, [filter, authToken]);

  const handleDelete = async (id) => {
    setBaivietToDelete(id);
    setShowDeleteModal(true); // Hiển thị modal xác nhận xóa
  };

  const confirmDelete = async () => {
    if (baivietToDelete) {
      try {
        const response = await fetch(
          `https://ducps34770.id.vn/api/admin/baiviet/${baivietToDelete}`,
          {
            method: "DELETE",
            headers: {
              "Content-Type": "application/json",
              Authorization: `Bearer ${authToken}`, // Gửi token trong header
            },
          }
        );
        if (response.ok) {
          setBaiviets(baiviets.filter((b) => b.id_bv !== baivietToDelete));
          setShowDeleteModal(false); // Đóng modal sau khi xóa thành công
        } else {
          console.error("Failed to delete the article.");
        }
      } catch (error) {
        console.error("Error deleting article:", error);
      }
    }
  };

  return (
    <div className="col-md-10 p-4">
      {/* Filter Cards */}
      <div className="row mb-4">
        <div className="col-lg-3 col-md-6 mb-4">
          <div className="card text-white btn-orange">
            <div className="card-body d-flex justify-content-between align-items-center">
              <div>
                <h5 className="card-title">Tất Cả Bài Viết</h5>
                <p className="card-text">{baivietCount} Bài Mới</p>
              </div>
              <i className="fa-solid fa-newspaper"></i>
            </div>
            <div className="row mb-2">
              <div className="mt-2 text-end">
                <Link to="/baiviet">
                  <button className="btn btn-light">Lọc</button>
                </Link>
              </div>
            </div>
          </div>
        </div>

        <div className="col-lg-3 col-md-6 mb-4">
          <div className="card text-white bg-success">
            <div className="card-body d-flex justify-content-between align-items-center">
              <div>
                <h5 className="card-title">Bài Viết Trong Ngày</h5>
                <p className="card-text">{baivietNowCount} Bài Mới</p>
              </div>
              <i className="fa-solid fa-newspaper"></i>
            </div>
            <div className="row mb-2">
              <div className="mt-2 text-end">
                <Link to="/baiviet?filter=today">
                  <button className="btn btn-light">Lọc</button>
                </Link>
              </div>
            </div>
          </div>
        </div>

        <div className="col-lg-3 col-md-6 mb-4">
          <div className="card text-white bg-warning">
            <div className="card-body d-flex justify-content-between align-items-center">
              <div>
                <h5 className="card-title">Bài Viết Hôm Qua</h5>
                <p className="card-text">{baivietYesterdayCount} Bài Viết</p>
              </div>
              <i className="fa-solid fa-newspaper"></i>
            </div>
            <div className="row mb-2">
              <div className="mt-2 text-end">
                <Link to="/baiviet?filter=yesterday">
                  <button className="btn btn-light">Lọc</button>
                </Link>
              </div>
            </div>
          </div>
        </div>

        <div className="col-lg-3 col-md-6 mb-4">
          <div className="card text-white bg-info">
            <div className="card-body d-flex justify-content-between align-items-center">
              <div>
                <h5 className="card-title">Bài Viết Trong Tháng</h5>
                <p className="card-text">{baivietMonthCount} Bài Mới</p>
              </div>
              <i className="fa-solid fa-newspaper"></i>
            </div>
            <div className="row mb-2">
              <div className="mt-2 text-end">
                <Link to="/baiviet?filter=this_month">
                  <button className="btn btn-light">Lọc</button>
                </Link>
              </div>
            </div>
          </div>
        </div>
      </div>

      {/* Tìm kiếm */}
      <form onSubmit={handleSearchSubmit} className="row mb-4 align-items-end">
        {/* Hàng 1: Chọn Ngày Bắt Đầu, Chọn Ngày Kết Thúc và nút Xem */}
        <div className="col-lg-3 col-md-6">
          <label htmlFor="ngay_from" className="form-label">
            Chọn Ngày Bắt Đầu:
          </label>
          <input
            type="date"
            name="ngay_from"
            className="form-control"
            id="ngay_from"
          />
        </div>

        <div className="col-lg-3 col-md-6">
          <label htmlFor="ngay_to" className="form-label">
            Chọn Ngày Kết Thúc:
          </label>
          <input
            type="date"
            name="ngay_to"
            className="form-control"
            id="ngay_to"
          />
        </div>

        <div className="col-lg-2 col-md-6 d-flex align-items-center">
          <button type="submit" className="btn btn-primary">
            Xem
          </button>
        </div>

        {/* Hàng 2: Tìm kiếm và Thêm Bài Viết Mới */}
        <div className="col-lg-4 col-md-6 d-flex justify-content-end align-items-center">
          <input
            type="text"
            className="form-control"
            placeholder="Tìm kiếm"
            value={searchTerm}
            onChange={handleSearchChange}
            style={{ maxWidth: "200px" }} // Giới hạn chiều rộng của thanh tìm kiếm
          />
          <button type="submit" className="btn btn-primary btn-sm ms-2">
            Tìm
          </button>
          <Link to="/AddBaiViet" className="ms-2">
            <button type="button" className="btn btn-primary btn-sm">
              Thêm bài viết mới
            </button>
          </Link>
        </div>
      </form>

      {/* Articles Table */}
      <div className="comment-container">
        <div className="comment">
          <div className="row mb-4">
            <div className="container mt-4">
              <div className="card-body">
                <table className="table table-hover table-responsive">
                  <thead className="table-primary">
                    <tr>
                      <th>#</th>
                      <th>Tiêu Đề Bài Viết</th>
                      <th>Tác Giả</th>
                      <th>Hình Ảnh</th>
                      <th>Ngày Đăng</th>
                      <th>Thao Tác</th>
                    </tr>
                  </thead>
                  <tbody>
                    {baiviets.map((baiviet, index) => (
                      <tr key={baiviet.id_bv}>
                        <td>{index + 1}</td>
                        <td>{baiviet.tieu_de}</td>
                        <td>{baiviet.tac_gia}</td>
                        <td>
                          <img
                            src={baiviet.hinh_bv}
                            alt={baiviet.tieu_de}
                            style={{ width: "100px", height: "auto" }}
                          />
                        </td>
                        <td>
                          {new Date(baiviet.ngay_dang).toLocaleString("vi-VN", {
                            day: "2-digit",
                            month: "2-digit",
                            year: "numeric",
                            hour: "2-digit",
                            minute: "2-digit",
                          })}
                        </td>
                        <td>
                          <Link to={`/EditBaiviet/${baiviet.id_bv}`}>
                            <button className="btn btn-warning">Sửa</button>
                          </Link>
                          <button
                            className="btn btn-danger"
                            onClick={() => handleDelete(baiviet.id_bv)}
                          >
                            Xóa
                          </button>
                        </td>
                      </tr>
                    ))}
                  </tbody>
                </table>
              </div>
            </div>
          </div>
        </div>
      </div>

      {/* Modal Xác Nhận Xóa */}
      {showDeleteModal && (
        <div className="modal show" tabIndex="-1" style={{ display: "block" }}>
          <div className="modal-dialog modal-dialog-centered">
            <div className="modal-content">
              <div className="modal-header">
                <h5 className="modal-title">Xác nhận xóa</h5>
                <button
                  type="button"
                  className="btn-close"
                  onClick={() => setShowDeleteModal(false)}
                ></button>
              </div>
              <div className="modal-body">
                <p>Bạn có chắc chắn muốn xóa bài viết này không?</p>
              </div>
              <div className="modal-footer">
                <button
                  type="button"
                  className="btn btn-secondary"
                  onClick={() => setShowDeleteModal(false)}
                >
                  Hủy
                </button>
                <button
                  type="button"
                  className="btn btn-danger"
                  onClick={confirmDelete}
                >
                  Xóa
                </button>
              </div>
            </div>
          </div>
        </div>
      )}
    </div>
  );
};

export default BaiViet;

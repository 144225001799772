import React, { useState, useEffect } from "react";
import "../../assets/css/style.css";
import "bootstrap/dist/css/bootstrap.min.css";
import "bootstrap-icons/font/bootstrap-icons.css";
import "bootstrap/dist/js/bootstrap.bundle.min.js";
import { useNavigate } from "react-router-dom";

const AddSanPham = () => {
  const [nhasanxuat, setNhaSanXuat] = useState([]);
  const [loaiSanPham, setLoaiSanPham] = useState([]); // Thêm state cho loại sản phẩm
  const [formData, setFormData] = useState({
    ten_sp: "",
    gia_sp: "",
    giaSale: "",
    moTa: "",
    id_loaisp: "1", // Giá trị mặc định
    nsx: "1", // Giá trị mặc định
    anHien: "0", // Giá trị mặc định
    soLuong: "",
    loai_go: "",
    kich_thuoc: "",
    mau_sac: "",
    bao_hanh: "",
    luotXem: 0,
    hot: "0", // Giá trị mặc định
    danhgia: "",
    binhluan: "",
    luot_mua: 0,
    thong_tin: "",
    hinh: null,
    hinh_1: null,
    hinh_2: null,
  });
  const navigate = useNavigate();

  const authToken = sessionStorage.getItem("authToken"); // Lấy authToken từ sessionStorage

  const handleChange = (e) => {
    const { name, value, type, files } = e.target;

    if (type === "file") {
      setFormData((prevFormData) => ({
        ...prevFormData,
        [name]: files[0], // Lưu trực tiếp File object
      }));
    } else {
      setFormData((prevFormData) => ({
        ...prevFormData,
        [name]: value,
      }));
    }
  };

  useEffect(() => {
    const fetchNhaSanXuat = async () => {
      try {
        const response = await fetch(
          "https://ducps34770.id.vn/api/admin/nhasanxuat",
          {
            headers: {
              Authorization: `Bearer ${authToken}`, // Thêm token vào header
            },
          }
        );
        if (response.ok) {
          const data = await response.json();
          setNhaSanXuat(data);
        } else {
          console.error(
            "Lỗi khi lấy dữ liệu nhà sản xuất:",
            response.statusText
          );
        }
      } catch (error) {
        console.error("Lỗi kết nối:", error);
      }
    };

    fetchNhaSanXuat();
  }, [authToken]);

  useEffect(() => {
    const fetchLoaiSanPham = async () => {
      try {
        const response = await fetch(
          "https://ducps34770.id.vn/api/admin/danhmuc",
          {
            headers: {
              Authorization: `Bearer ${authToken}`,
            },
          }
        );
        if (response.ok) {
          const data = await response.json();
          setLoaiSanPham(data);
        } else {
          console.error("Lỗi khi lấy loại sản phẩm:", response.statusText);
        }
      } catch (error) {
        console.error("Lỗi kết nối:", error);
      }
    };

    fetchLoaiSanPham();
  }, [authToken]);

  const handleSubmit = async (e) => {
    e.preventDefault();

    const form = new FormData();

    // Duyệt qua formData để thêm vào FormData
    Object.keys(formData).forEach((key) => {
      if (formData[key]) {
        form.append(key, formData[key]);
      }
    });

    // Kiểm tra dữ liệu trước khi gửi
    for (let pair of form.entries()) {
      console.log(`${pair[0]}: ${pair[1]}`);
    }

    try {
      const response = await fetch(
        "https://ducps34770.id.vn/api/admin/sanpham",
        {
          method: "POST",
          body: form,
          headers: {
            Authorization: `Bearer ${authToken}`, // Auth header (nếu có)
          },
        }
      );

      if (response.ok) {
        const data = await response.json();
        console.log("Thêm sản phẩm thành công:", data);
        alert("Sản phẩm đã được thêm");
        navigate("/productslist");
      } else {
        console.error("Lỗi khi thêm sản phẩm:", response.statusText);
      }
    } catch (error) {
      console.error("Lỗi kết nối:", error);
    }
  };

  return (
    <form className="col-md-10 p-4" onSubmit={handleSubmit}>
      <div className="comment">
        <div className="row mb-4">
          <div className="container mt-4">
            <div className="card shadow" style={{ borderRadius: "10px" }}>
              <div
                className="card-header text-white"
                style={{
                  backgroundColor: "#ffcc00",
                  borderTopLeftRadius: "10px",
                  borderTopRightRadius: "10px",
                }}
              >
                <h4 className="font-weight-bold mb-0">Thêm sản phẩm</h4>
              </div>
              <div className="card-body">
                <div className="row">
                  {/* Left Column */}
                  <div className="col-md-6">
                    <div className="mb-3">
                      <label htmlFor="productName" className="form-label">
                        Tên sản phẩm
                      </label>
                      <input
                        type="text"
                        className="form-control"
                        id="productName"
                        name="ten_sp"
                        required
                        onChange={handleChange}
                      />
                    </div>
                    <div className="mb-3">
                      <label htmlFor="price" className="form-label">
                        Giá
                      </label>
                      <input
                        type="text"
                        className="form-control"
                        id="price"
                        name="gia_sp"
                        required
                        onChange={handleChange}
                      />
                    </div>
                    <div className="mb-3">
                      <label htmlFor="woodType" className="form-label">
                        Loại gỗ
                      </label>
                      <input
                        type="text"
                        className="form-control"
                        id="woodType"
                        name="loai_go"
                        required
                        onChange={handleChange}
                      />
                    </div>
                    <div className="mb-3">
                      <label htmlFor="color" className="form-label">
                        Màu sắc
                      </label>
                      <input
                        type="text"
                        className="form-control"
                        id="color"
                        name="mau_sac"
                        required
                        onChange={handleChange}
                      />
                    </div>
                    <div className="mb-3">
                      <label htmlFor="description" className="form-label">
                        Mô tả
                      </label>
                      <textarea
                        className="form-control"
                        id="description"
                        name="moTa"
                        rows="3"
                        required
                        onChange={handleChange}
                      ></textarea>
                    </div>
                    <div className="mb-3">
                      <label htmlFor="additionalInfo" className="form-label">
                        Thông tin
                      </label>
                      <textarea
                        className="form-control"
                        id="additionalInfo"
                        name="thong_tin"
                        rows="3"
                        value={formData.thong_tin}
                        onChange={handleChange}
                      ></textarea>
                    </div>
                    <div className="mb-3">
                      <label htmlFor="manufacturer" className="form-label">
                        Chọn nhà sản xuất
                      </label>
                      <select
                        className="form-control"
                        id="manufacturer"
                        name="nsx"
                        defaultValue="1" // Giá trị mặc định
                        required
                        onChange={handleChange}
                      >
                        {nhasanxuat.map((nsx) => (
                          <option key={nsx.id_nsx} value={nsx.id_nsx}>
                            {nsx.ten_nsx}
                          </option>
                        ))}
                      </select>
                    </div>
                    <div className="mb-3">
                      <label htmlFor="status" className="form-label">
                        Trạng thái sản phẩm
                      </label>
                      <select
                        className="form-select"
                        id="status"
                        name="anHien"
                        defaultValue="0" // Giá trị mặc định
                        required
                        onChange={handleChange}
                      >
                        <option value="1">Hiện sản phẩm</option>
                        <option value="0">Ẩn sản phẩm</option>
                      </select>
                    </div>
                  </div>

                  {/* Right Column */}
                  <div className="col-md-6">
                    <div className="mb-3">
                      <label htmlFor="stock" className="form-label">
                        Số lượng trong kho
                      </label>
                      <input
                        type="number"
                        className="form-control"
                        id="stock"
                        name="soLuong"
                        required
                        onChange={handleChange}
                      />
                    </div>
                    <div className="mb-3">
                      <label htmlFor="discount" className="form-label">
                        Giá khuyến mãi
                      </label>
                      <input
                        type="text"
                        className="form-control"
                        id="discount"
                        name="giaSale"
                        onChange={handleChange}
                      />
                    </div>
                    <div className="mb-3">
                      <label htmlFor="size" className="form-label">
                        Kích thước
                      </label>
                      <input
                        type="text"
                        className="form-control"
                        id="size"
                        name="kich_thuoc"
                        onChange={handleChange}
                      />
                    </div>
                    <div className="mb-3">
                      <label htmlFor="warranty" className="form-label">
                        Bảo hành
                      </label>
                      <input
                        type="text"
                        className="form-control"
                        id="warranty"
                        name="bao_hanh"
                        onChange={handleChange}
                      />
                    </div>
                    <div className="mb-3">
                      <label htmlFor="image" className="form-label">
                        Hình ảnh
                      </label>
                      <input
                        type="file"
                        className="form-control"
                        id="image"
                        name="hinh"
                        onChange={handleChange}
                      />
                    </div>
                    <div className="mb-3">
                      <label htmlFor="image1" className="form-label">
                        Hình ảnh 1
                      </label>
                      <input
                        type="file"
                        className="form-control"
                        id="image1"
                        name="hinh_1"
                        onChange={handleChange}
                      />
                    </div>
                    <div className="mb-3">
                      <label htmlFor="image2" className="form-label">
                        Hình ảnh 2
                      </label>
                      <input
                        type="file"
                        className="form-control"
                        id="image2"
                        name="hinh_2"
                        onChange={handleChange}
                      />
                    </div>

                    <div className="mb-3">
                      <label htmlFor="productType" className="form-label">
                        Chọn loại sản phẩm
                      </label>
                      <select
                        className="form-select"
                        id="productType"
                        name="id_loaisp"
                        defaultValue="1"
                        required
                        onChange={handleChange}
                      >
                        {loaiSanPham.map((loai) => (
                          <option key={loai.id_loaisp} value={loai.id_loaisp}>
                            {loai.loai}
                          </option>
                        ))}
                      </select>
                    </div>

                    <div className="mb-3">
                      <label htmlFor="productType" className="form-label">
                        Có Hot không ?
                      </label>
                      <select
                        className="form-select"
                        id="productHot"
                        name="hot"
                        defaultValue="0" // Giá trị mặc định
                        required
                        onChange={handleChange}
                      >
                        <option value="0">Hot</option>
                        <option value="1">Không</option>
                      </select>
                    </div>

                    <button type="submit" className="btn btn-primary">
                      Thêm sản phẩm
                    </button>
                    <button
                      type="button"
                      className="btn btn-secondary"
                      onClick={() => {
                        /* Reset or navigate away */
                      }}
                    >
                      Hủy
                    </button>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </form>
  );
};

export default AddSanPham;

import React, { useEffect, useState } from "react";

import { Link, useNavigate } from "react-router-dom";

import axios from "axios";

const QuanlyDM = () => {
  const [categories, setCategories] = useState([]);

  const [loading, setLoading] = useState(true);

  const [searchTerm, setSearchTerm] = useState("");

  const [showDeleteModal, setShowDeleteModal] = useState(false);

  const [manufacturerToDelete, setManufacturerToDelete] = useState(null);

  const navigate = useNavigate();

  useEffect(() => {
    const fetchCategories = async () => {
      try {
        const response = await axios.get(
          "https://ducps34770.id.vn/api/admin/danhmuc",

          {
            headers: {
              Authorization: `Bearer ${sessionStorage.getItem("authToken")}`, // Add token to headers
            },
          }
        );

        setCategories(response.data);
      } catch (error) {
        console.error("Lỗi khi lấy danh mục:", error);
      } finally {
        setLoading(false);
      }
    };

    fetchCategories();
  }, []);

  const handleDelete = (id, name) => {
    setManufacturerToDelete({ id, name });

    setShowDeleteModal(true);
  };

  const confirmDelete = async () => {
    if (manufacturerToDelete) {
      const { id, name } = manufacturerToDelete;

      try {
        await axios.delete(`https://ducps34770.id.vn/api/admin/danhmuc/${id}`, {
          headers: {
            Authorization: `Bearer ${sessionStorage.getItem("authToken")}`, // Add token to headers
          },
        });

        setCategories(
          categories.filter((category) => category.id_loaisp !== id)
        );

        alert(`${name} đã được xóa.`);

        setShowDeleteModal(false);

        setManufacturerToDelete(null);
      } catch (error) {
        console.error("Lỗi khi xóa danh mục:", error);
      }
    }
  };

  const filteredCategories = categories.filter((category) =>
    category.loai.toLowerCase().includes(searchTerm.toLowerCase())
  );

  if (loading) return <p>Đang tải...</p>;

  return (
    <div className="col-md-10 p-4">
      <div className="card mb-4">
        <div className="card-header d-flex justify-content-between align-items-center">
          <h5>Danh mục sản phẩm</h5>

          <Link to="/AddDanhMuc">
            <button className="btn btn-primary">Thêm danh mục mới</button>
          </Link>
        </div>

        <div className="card-body">
          <div className="card-header d-flex justify-content-between align-items-center mb-3">
            <h6>
              Tổng số danh mục hiện có:{" "}
              <span id="totalCategories">{filteredCategories.length}</span>
            </h6>

            <div className="col-lg-4 col-md-6 d-flex">
              <input
                type="text"
                className="form-control"
                placeholder="Tìm kiếm"
                value={searchTerm}
                onChange={(e) => setSearchTerm(e.target.value)}
              />

              <button className="btn btn-primary ms-2">Tìm</button>
            </div>
          </div>

          <div className="comment-container">
            <table className="table table-striped">
              <thead>
                <tr>
                  <th>ID</th>

                  <th>Hình ảnh</th>

                  <th>Tên danh mục</th>

                  <th>Hành động</th>
                </tr>
              </thead>

              <tbody>
                {filteredCategories.map((category) => (
                  <tr key={category.id_loaisp}>
                    <td>{category.id_loaisp}</td>

                    <td>
                      <img
                        src={category.hinh}
                        alt="Product"
                        className="small-product-img"
                        style={{ width: "150px", height: "150px" }}
                      />
                    </td>

                    <td>{category.loai}</td>

                    <td>
                      <Link to={`/EditDanhMuc/${category.id_loaisp}`}>
                        <button className="btn btn-warning btn-sm">
                          Chỉnh sửa
                        </button>
                      </Link>

                      <button
                        className="btn btn-danger btn-sm"
                        onClick={() =>
                          handleDelete(category.id_loaisp, category.loai)
                        }
                      >
                        Xóa
                      </button>
                    </td>
                  </tr>
                ))}
              </tbody>
            </table>
          </div>
        </div>
      </div>

      {/* Modal Xác Nhận Xóa */}

      {showDeleteModal && (
        <div className="modal show" tabIndex="-1" style={{ display: "block" }}>
          <div className="modal-dialog modal-dialog-centered">
            <div className="modal-content">
              <div className="modal-header">
                <h5 className="modal-title">Xác nhận xóa</h5>

                <button
                  type="button"
                  className="btn-close"
                  onClick={() => setShowDeleteModal(false)}
                ></button>
              </div>

              <div className="modal-body">
                <p>
                  Bạn có chắc chắn muốn xóa danh mục{" "}
                  <strong>{manufacturerToDelete?.name}</strong>?
                </p>
              </div>

              <div className="modal-footer">
                <button
                  type="button"
                  className="btn btn-secondary"
                  onClick={() => setShowDeleteModal(false)}
                >
                  Hủy
                </button>

                <button
                  type="button"
                  className="btn btn-danger"
                  onClick={confirmDelete}
                >
                  Xóa
                </button>
              </div>
            </div>
          </div>
        </div>
      )}
    </div>
  );
};

export default QuanlyDM;

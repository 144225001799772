import { useState, useEffect } from "react";

const PhanHoi = () => {
  const [feedbackList, setFeedbackList] = useState([]);
  const [filteredFeedback, setFilteredFeedback] = useState([]);
  const [searchTerm, setSearchTerm] = useState(""); // Tên khách hàng tìm kiếm
  const [filterStatus, setFilterStatus] = useState("all"); // Trạng thái lọc: all, unprocessed, processed
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState(null);
  const [successMessage, setSuccessMessage] = useState(""); // Thông báo thành công

  // Fetch feedback data from API
  useEffect(() => {
    setLoading(true);
    fetch("https://ducps34770.id.vn/api/admin/phanhoi")
      .then((response) => response.json())
      .then((data) => {
        setFeedbackList(data);
        setFilteredFeedback(data); // Set initial filtered feedback
        setLoading(false);
      })
      .catch((error) => {
        setError("Error fetching feedback");
        setLoading(false);
        console.error("Error fetching feedback:", error);
      });
  }, []);

  // Apply filters based on search term and status
  const applyFilters = () => {
    let filtered = feedbackList;

    // Filter by status
    if (filterStatus !== "all") {
      filtered = filtered.filter(
        (item) => item.da_xu_ly === (filterStatus === "processed" ? 1 : 0)
      );
    }

    // Filter by search term
    if (searchTerm) {
      filtered = filtered.filter((item) =>
        item.ho_ten.toLowerCase().includes(searchTerm.toLowerCase())
      );
    }

    setFilteredFeedback(filtered);
  };

  // Handle search term change
  const handleSearchChange = (e) => {
    setSearchTerm(e.target.value);
  };

  // Handle status filter change
  const handleStatusChange = (status) => {
    setFilterStatus(status);
  };

  // Reapply filters whenever search term or status changes
  useEffect(() => {
    applyFilters();
  }, [searchTerm, filterStatus, feedbackList]);

  // Handle form submit for search
  const handleSearch = (e) => {
    e.preventDefault();
    applyFilters();
  };

  // Mark feedback as processed
  const markAsProcessed = (id_ph) => {
    // Gửi yêu cầu đến API để đánh dấu phản hồi là đã xử lý
    fetch(`https://ducps34770.id.vn/api/admin/phanhoi/${id_ph}`, {
      method: "PUT",
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${sessionStorage.getItem("authToken")}`,
      },
    })
      .then((response) => {
        if (response.ok) {
          // Cập nhật lại danh sách phản hồi
          setFeedbackList((prev) =>
            prev.map((item) =>
              item.id_ph === id_ph ? { ...item, da_xu_ly: 1 } : item
            )
          );
          setSuccessMessage("Phản hồi đã được đánh dấu là đã xử lý."); // Thông báo thành công
        } else {
          console.error("Lỗi khi đánh dấu phản hồi:", response.statusText);
        }
      }) .catch((error) => {
        console.error("Lỗi kết nối:", error);
      });
  };

  return (
    <div className="col-md-10 p-4">
      {/* Loading State */}
      {loading && <div className="alert alert-info">Đang tải dữ liệu...</div>}
      {error && <div className="alert alert-danger">{error}</div>}
      {successMessage && (
        <div className="alert alert-success">{successMessage}</div>
      )}

      {/* Feedback Statistics Cards */}
      <div className="row mb-4">
        {[
          {
            title: "Tổng Số Phản Hồi",
            value: feedbackList.length,
            filter: "            total",
            bgClass: "bg-info",
          },
          {
            title: "Phản hồi chưa xử lý",
            value: filteredFeedback.filter((item) => item.da_xu_ly === 0)
              .length,
            filter: "unprocessed",
            bgClass: "bg-success",
          },
          {
            title: "Phản hồi đã xử lý",
            value: filteredFeedback.filter((item) => item.da_xu_ly === 1)
              .length,
            filter: "processed",
            bgClass: "bg-danger",
          },
        ].map((card, idx) => (
          <div className="col-lg-3 col-md-6 mb-4" key={idx}>
            <div className={`card text-white ${card.bgClass}`}>
              <div className="card-body d-flex justify-content-between align-items-center">
                <div>
                  <h5 className="card-title">{card.title}</h5>
                  <p className="card-text">{card.value} phản hồi</p>
                </div>
                <i className="bi bi-box-seam fs-1"></i>
              </div>
              <button
                type="button"
                className="btn btn-light"
                onClick={() => handleStatusChange(card.filter)}
              >
                Xem phản hồi
              </button>
            </div>
          </div>
        ))}
      </div>

      {/* Search and Status Filter Form */}
      <form className="row mb-4 align-items-end" onSubmit={handleSearch}>
        <div className="col-lg-4 col-md-6 d-flex">
          <input
            type="text"
            className="form-control"
            placeholder="Nhập tên khách hàng"
            value={searchTerm}
            onChange={handleSearchChange}
          />
          <button type="submit" className="btn btn-primary">
            Tìm
          </button>
        </div>
        <div className="col-lg-4 col-md-6">
          <select
            className="form-select"
            value={filterStatus}
            onChange={(e) => setFilterStatus(e.target.value)}
          >
            <option value="all">Tất cả</option>
            <option value="unprocessed">Chưa xử lý</option>
            <option value="processed">Đã xử lý</option>
          </select>
        </div>
      </form>

      {/* Feedback Table */}
      <div className="card"> <div className="card-header">Danh sách phản hồi</div>
        <div className="card-body">
          <table className="table table-hover table-responsive">
            <thead className="table-primary">
              <tr>
                <th>#</th>
                <th>Tên Khách Hàng</th>
                <th>Email</th>
                <th>Nội dung</th>
                <th>Trạng Thái</th>
                <th>Ngày Nhận</th>
                <th>Hành Động</th>
              </tr>
            </thead>
            <tbody>
              {filteredFeedback.length > 0 ? (
                filteredFeedback.map((item, index) => (
                  <tr key={item.id_ph}>
                    <td>{index + 1}</td>
                    <td>{item.ho_ten}</td>
                    <td>{item.email}</td>
                    <td>{item.loi_nhan}</td>
                    <td>{item.da_xu_ly === 1 ? "Đã xử lý" : "Chưa xử lý"}</td>
                    <td>{new Date(item.ngay_gui).toLocaleDateString()}</td>
                    <td>
                      {item.da_xu_ly === 0 && (
                        <button
                          className="btn btn-primary"
                          onClick={() => {
                            markAsProcessed(item.id_ph);
                            // Cập nhật lại danh sách phản hồi sau khi đánh dấu
                            applyFilters(); // Áp dụng lại bộ lọc để cập nhật bảng
                          }}
                        >
                          Hoàn thành
                        </button>
                      )}
                    </td>
                  </tr>
                ))
              ) : (
                <tr>
                  <td colSpan="7" className="text-center">
                    Không có phản hồi nào
                  </td>
                </tr>
              )}
            </tbody>
          </table>
        </div>
      </div>
    </div>
  );
};

export default PhanHoi;
import React, { useEffect, useState } from "react";
import "bootstrap/dist/css/bootstrap.min.css";
import "bootstrap-icons/font/bootstrap-icons.css";
import { Link } from "react-router-dom";

const Voucher = () => {
  const [vouchers, setVouchers] = useState([]); // Lưu trữ danh sách voucher
  const [voucherCount, setVoucherCount] = useState({
    existing: 0,
    expired: 0,
    used: 0,
    hidden: 0,
  }); // Lưu trữ số lượng voucher theo từng trạng thái
  const [showDeleteModal, setShowDeleteModal] = useState(false); // Điều khiển việc hiển thị modal xóa
  const [voucherToDelete, setVoucherToDelete] = useState(null); // Lưu trữ voucher sẽ bị xóa
  const [searchTerm, setSearchTerm] = useState(""); // Lưu trữ giá trị tìm kiếm
  const [loading, setLoading] = useState(true); // Trạng thái tải

  useEffect(() => {
    fetchVouchers(); // Lấy danh sách voucher khi component mount
  }, []);

  useEffect(() => {
    fetchVoucherCounts(); // Cập nhật số lượng voucher khi danh sách voucher thay đổi
  }, [vouchers]);

  // Lấy danh sách voucher từ API
  const fetchVouchers = async () => {
    setLoading(true); // Đặt trạng thái tải là true
    try {
      const token =
        sessionStorage.getItem("authToken") ||
        localStorage.getItem("authToken");

      const response = await fetch("https://ducps34770.id.vn/api/admin/vouchers", {
        method: "GET",
        headers: {
          Authorization: `Bearer ${token}`, // Gắn token vào header
          "Content-Type": "application/json",
        },
      });

      if (!response.ok) {
        throw new Error("Network response was not ok");
      }

      const data = await response.json();
      setVouchers(data.vouchers);
    } catch (error) {
      console.error("Lỗi khi lấy danh sách voucher:", error);
      alert("Có lỗi xảy ra khi tải danh sách voucher."); // Phản hồi cho người dùng
    } finally {
      setLoading(false); // Đặt trạng thái tải là false
    }
  };

  // Tính toán số lượng voucher theo từng trạng thái
  const fetchVoucherCounts = () => {
    const existing = vouchers.filter(
      (voucher) =>
        voucher.an_hien === 1 &&
        new Date(voucher.ngay_het_han) > new Date() &&
        voucher.da_su_dung < voucher.gioi_han_su_dung
    ).length;

    const expired = vouchers.filter(
      (voucher) => new Date(voucher.ngay_het_han) <= new Date()
    ).length;

    const used = vouchers.filter(
      (voucher) => voucher.da_su_dung >= voucher.gioi_han_su_dung
    ).length;

    const hidden = vouchers.filter((voucher) => voucher.an_hien === 0).length;

    setVoucherCount({ existing, expired, used, hidden });
  };

  // Hàm xóa voucher
  const handleDelete = async (id) => {
    const confirmDelete = window.confirm(
      "Bạn có chắc chắn muốn xóa voucher này?"
    );
    if (confirmDelete) {
      try {
        const token =
          sessionStorage.getItem("authToken") ||
          localStorage.getItem("authToken");

        await fetch(`https://ducps34770.id.vn/api/admin/vouchers/${id}`, {
          method: "DELETE",
          headers: {
            Authorization: `Bearer ${token}`, // Gắn token vào header
            "Content-Type": "application/json",
          },
        });
        alert("Voucher đã được xóa thành công");
        fetchVouchers(); // Cập nhật lại danh sách voucher sau khi xóa
      } catch (error) {
        console.error("Lỗi khi xóa voucher:", error);
        alert("Có lỗi xảy ra khi xóa voucher."); // Phản hồi cho người dùng
      }
    }
  };

  // Hàm lọc danh sách voucher theo mã giảm giá
  const filteredVouchers = vouchers.filter((voucher) =>
    voucher.ma_giam_gia.toLowerCase().includes(searchTerm.toLowerCase())
  );

  return (
    <div className="col-md-10 p-4">
      {/* Cards */}
      <div className="row mb-4">
        {/* Voucher Có Thể Dùng */}
        <div className="col-lg-3 col-md-6 mb-4">
          <div className="card text-white bg-success">
            <div className="card-body d-flex justify-content-between align-items-center">
              <div>
                <h5 className="card-title">Voucher Có Thể Dùng</h5>
                <p className="card-text">{voucherCount.existing} Voucher</p>
              </div>
              <i className="fa-solid fa-ticket"></i>
            </div>
            <div className="row mb-2">
              <div className="mt-2 text-end">
                <button className="btn btn-light">Xem Voucher</button>
              </div>
            </div>
          </div>
        </div>

        {/* Voucher Hết Hạn */}
        <div className="col-lg-3 col-md-6 mb-4">
          <div className="card text-white bg-danger">
            <div className="card-body d-flex justify-content-between align-items-center">
              <div>
                <h5 className="card-title">Voucher Hết Hạn Dùng</h5>
                <p className="card-text">
                  {voucherCount.expired} Voucher Hết Hạn
                </p>
              </div>
              <i className="fa-solid fa-ticket"></i>
            </div>
            <div className="row mb-2">
              <div className="mt-2 text-end">
                <button className="btn btn-light">Xem Voucher</button>
              </div>
            </div>
          </div>
        </div>

        {/* Voucher Đã Sử Dụng */}
        <div className="col-lg-3 col-md-6 mb-4">
          <div className="card text-white bg-info">
            <div className="card-body d-flex justify-content-between align-items-center">
              <div>
                <h5 className="card-title">Voucher Đã Sử Dụng</h5>
                <p className="card-text">{voucherCount.used} Voucher</p>
              </div>
              <i className="fa-solid fa-ticket"></i>
            </div>
            <div className="row mb-2">
              <div className="mt-2 text-end">
                <button className="btn btn-light">Xem Voucher</button>
              </div>
            </div>
          </div>
        </div>

        {/* Voucher Vô Hiệu Hóa */}
        <div className="col-lg-3 col-md-6 mb-4">
          <div className="card text-white bg-warning">
            <div className="card-body d-flex justify-content-between align-items-center">
              <div>
                <h5 className="card-title">Voucher Vô Hiệu Hóa</h5>
                <p className="card-text">{voucherCount.hidden} Voucher</p>
              </div>
              <i className="fa-solid fa-ticket"></i>
            </div>
            <div className="row mb-2">
              <div className="mt-2 text-end">
                <button className="btn btn-light">Xem Voucher</button>
              </div>
            </div>
          </div>
        </div>
      </div>

      {/* Nội dung chính */}
      <div>
        <div className="card-header d-flex justify-content-between align-items-center">
          <Link to="/AddVoucher">
            <button className="btn btn-primary">Thêm Voucher Mới</button>
          </Link>
          <form className="mb-4" onSubmit={(e) => e.preventDefault()}>
            <div className="input-group">
              <input
                type="text"
                className="form-control"
                placeholder="Nhập mã giảm giá"
                value={searchTerm}
                onChange={(e) => setSearchTerm(e.target.value)}
              />
              <button type="submit" className="btn btn-primary">
                Tìm Kiếm
              </button>
            </div>
          </form>
        </div>

        <div className="comment-container" id="commentContainer">
          <div className="comment">
            <div className="row mb-4">
              <div className="card-body">
                <table className="table table-hover table-responsive">
                  <thead className="table-primary">
                    <tr>
                      <th>#</th>
                      <th>Mã Voucher</th>
                      <th>Giới Hạn Dùng</th>
                      <th>Số Lần Đã Dùng</th>
                      <th>Giá Trị Giảm Giá</th>
                      <th>Ngày Bắt Đầu</th>
                      <th>Ngày Hết Hạn</th>
                      <th>Trạng Thái</th>
                      <th>Thao Tác</th>
                    </tr>
                  </thead>
                  <tbody>
                    {loading ? ( // Hiển thị trạng thái tải
                      <tr>
                        <td colSpan="9" className="text-center">
                          Đang tải dữ liệu...
                        </td>
                      </tr>
                    ) : filteredVouchers.length > 0 ? (
                      filteredVouchers.map((voucher, index) => (
                        <tr key={voucher.id_mgg}>
                          <td>{index + 1}</td>
                          <td>{voucher.ma_giam_gia}</td>
                          <td>{voucher.gioi_han_su_dung}</td>
                          <td>{voucher.da_su_dung}</td>
                          <td>{voucher.so_tien_giam}</td>
                          <td>{voucher.ngay_bat_dau}</td>
                          <td>{voucher.ngay_het_han}</td>
                          <td>
                            {voucher.an_hien === 1
                              ? "Đã Kích Hoạt"
                              : "Vô Hiệu Hóa"}
                          </td>
                          <td>
                            <Link to={`/vouchers/edit/${voucher.id_mgg}`}>
                              <button className="btn btn-sm btn-primary">
                                Chỉnh Sửa
                              </button>
                            </Link>
                            <button
                              className="btn btn-sm btn-danger ml-2"
                              onClick={() => {
                                setVoucherToDelete(voucher.id_mgg);
                                setShowDeleteModal(true);
                              }}
                            >
                              Xóa
                            </button>
                          </td>
                        </tr>
                      ))
                    ) : (
                      <tr>
                        <td colSpan="9" className="text-center">
                          Không tìm thấy voucher nào.
                        </td>
                      </tr>
                    )}
                  </tbody>
                </table>
              </div>
            </div>
          </div>
        </div>
      </div>

      {/* Modal xác nhận xóa voucher */}
      {showDeleteModal && (
        <div
          className="modal fade show"
          style={{ display: "block" }}
          tabIndex="-1"
          role="dialog"
        >
          <div className="modal-dialog" role="document">
            <div className="modal-content">
              <div className="modal-header">
                <h5 className="modal-title">Xác Nhận Xóa</h5>
                <button
                  type="button"
                  className="close"
                  onClick={() => setShowDeleteModal(false)}
                >
                  <span>&times;</span>
                </button>
              </div>
              <div className="modal-body">
                <p>Bạn có chắc chắn muốn xóa voucher này không?</p>
              </div>
              <div className="modal-footer">
                <button
                  type="button"
                  className="btn btn-secondary"
                  onClick={() => setShowDeleteModal(false)}
                >
                  Hủy
                </button>
                <button
                  type="button"
                  className="btn btn-danger"
                  onClick={() => {
                    handleDelete(voucherToDelete);
                    setShowDeleteModal(false);
                  }}
                >
                  Xóa
                </button>
              </div>
            </div>
          </div>
        </div>
      )}
    </div>
  );
};

export default Voucher;

import React, { useState, useEffect } from "react";
import { Link, useNavigate } from "react-router-dom";
import "bootstrap/dist/css/bootstrap.min.css";
import "bootstrap-icons/font/bootstrap-icons.css";
import "bootstrap/dist/js/bootstrap.bundle.min.js";
import "../../assets/css/style.css";

const Navbar = () => {
  const [isLoggedIn, setIsLoggedIn] = useState(false); // Trạng thái đăng nhập
  const navigate = useNavigate(); // Khởi tạo useNavigate

  // Kiểm tra khi component được render để xem người dùng đã đăng nhập chưa
  useEffect(() => {
    const userInfo = sessionStorage.getItem("userInfo");
    if (userInfo) {
      setIsLoggedIn(true); // Nếu có thông tin người dùng, coi như đã đăng nhập
    } else {
      setIsLoggedIn(false); // Nếu không có, coi như chưa đăng nhập
    }
  }, []);

  // Hàm đăng xuất
  const handleLogout = () => {
    localStorage.removeItem("userInfo"); // Xóa thông tin người dùng khỏi localStorage
    setIsLoggedIn(false); // Cập nhật trạng thái đăng nhập
    navigate("/adminlogin"); // Chuyển hướng về trang đăng nhập
    sessionStorage.removeItem("userInfo");
    sessionStorage.removeItem("userRole");
    sessionStorage.removeItem("authToken");
  };

  return (
    <nav className="navbar navbar-expand-lg navbar-light">
      <div className="container-fluid">
        <a className="navbar-brand" href="#">
          Admin Dashboard
        </a>
        <button
          className="navbar-toggler"
          type="button"
          data-bs-toggle="collapse"
          data-bs-target="#navbarSupportedContent"
          aria-controls="navbarSupportedContent"
          aria-expanded="false"
          aria-label="Toggle navigation"
        >
          <span className="navbar-toggler-icon"></span>
        </button>
        <div className="collapse navbar-collapse" id="navbarSupportedContent">
          <ul className="navbar-nav me-auto mb-2 mb-lg-0"></ul>

          <form className="d-flex align-items-center me-3">
            <input
              className="form-control me-2"
              type="search"
              placeholder="Tìm kiếm"
              aria-label="Search"
            />
            <button className="btn btn-outline-light" type="submit">
              Tìm
            </button>
          </form>

          <ul className="navbar-nav">
            <li className="nav-item dropdown">
              <a
                className="nav-link dropdown-toggle"
                href="#"
                role="button"
                data-bs-toggle="dropdown"
                aria-expanded="false"
              >
                <i className="bi bi-person-circle"></i> Tài Khoản
              </a>
              <ul className="dropdown-menu dropdown-menu-end">
                <li>
                  <Link className="dropdown-item" to="/useradmin">
                    Hồ Sơ
                  </Link>
                </li>
                {!isLoggedIn ? (
                  <li>
                    <Link className="dropdown-item" to="/adminlogin">
                      Đăng Nhập
                    </Link>
                  </li>
                ) : (
                  <li>
                    <button className="dropdown-item" onClick={handleLogout}>
                      Đăng Xuất
                    </button>
                  </li>
                )}
              </ul>
            </li>
          </ul>
        </div>
      </div>
    </nav>
  );
};

export default Navbar;

import React, { useState, useEffect } from "react";
import "bootstrap/dist/css/bootstrap.min.css";
import "bootstrap-icons/font/bootstrap-icons.css";
import "bootstrap/dist/js/bootstrap.bundle.min.js"; // Bootstrap JavaScript
import axios from "axios"; // For making API requests

const AdminDashboard = () => {
  const [startDate, setStartDate] = useState("");
  const [endDate, setEndDate] = useState("");
  const [revenueData, setRevenueData] = useState([]);
  const [totalRevenue, setTotalRevenue] = useState(0);
  const [totalSold, setTotalSold] = useState(0);
  const [unfinishedProducts, setUnfinishedProducts] = useState(0);
  const [completedProducts, setCompletedProducts] = useState(0);
  const [searchTerm, setSearchTerm] = useState(""); // State for the search term

  useEffect(() => {
    fetchRevenueData();
  }, []);

  const fetchRevenueData = async () => {
    try {
      const response = await axios.get(
        "https://ducps34770.id.vn/api/admin/doanhthu",
        {
          headers: {
            Authorization: `Bearer ${sessionStorage.getItem("token")}`, // Thêm token vào header
          },
        }
      );
      const data = response.data.doanhthu;

      setRevenueData(data);
      setTotalRevenue(data.reduce((acc, item) => acc + item.thanh_tien, 0));
      setTotalSold(data.reduce((acc, item) => acc + parseInt(item.soLuong), 0));
      setUnfinishedProducts(
        data.filter((item) => item.status === "unfinished").length
      );
      setCompletedProducts(
        data.filter((item) => item.status === "completed").length
      );
    } catch (error) {
      console.error("Error fetching revenue data:", error);
    }
  };

  const handleSubmit = (event) => {
    event.preventDefault();
    // Logic to filter by dates can be implemented here
    console.log("Start Date:", startDate, "End Date:", endDate);
    // Optionally, call the API again with the date range
  };

  const handleSearchChange = (event) => {
    setSearchTerm(event.target.value); // Update search term
  };

  // Filter the revenue data based on the search term
  const filteredRevenueData = revenueData.filter((item) =>
    item.ten_sp.toLowerCase().includes(searchTerm.toLowerCase())
  );

  // Hàm xuất hóa đơn PDF
  const handleExportInvoice = async () => {
    try {
      const response = await axios.get(
        "https://ducps34770.id.vn/api/admin/doanhthu/pdf",
        {
          headers: {
            Authorization: `Bearer ${sessionStorage.getItem("token")}`,
          },
          responseType: "blob", // Để nhận dữ liệu nhị phân
        }
      );

      // Tạo URL cho blob và kích hoạt tải xuống
      const url = window.URL.createObjectURL(new Blob([response.data]));
      const link = document.createElement("a");
      link.href = url;
      link.setAttribute("download", "doanh_thu.pdf"); // Tên tệp xuất
      document.body.appendChild(link);
      link.click();
      link.remove();
    } catch (error) {
      console.error("Error exporting invoice:", error);
    }
  };

  return (
    <div className="col-md-10 p-4">
      {/* Cards */}
      <div className="row mb-4">
        <div className="col-lg-3 col-md-6 mb-4">
          <div className="card text-white btn-orange">
            <div className="card-body d-flex justify-content-between align-items-center">
              <div>
                <h5 className="card-title">Tổng Doanh Thu Đã Bán</h5>
                <p className="card-text">
                  {new Intl.NumberFormat("vi-VN").format(totalRevenue)} VNĐ
                </p>
              </div>
              <i className="fa-solid fa-money-bill-wave"></i>
            </div>
          </div>
        </div>
        <div className="col-lg-3 col-md-6 mb-4">
          <div className="card text-white bg-success">
            <div className="card-body d-flex justify-content-between align-items-center">
              <div>
                <h5 className="card-title">Số Lượng Sản Phẩm Đã Bán Ra</h5>
                <p className="card-text">{totalSold} Sản Phẩm</p>
              </div>
              <i className="fa-solid fa-cart-shopping"></i>
            </div>
          </div>
        </div>
        <div className="col-lg-3 col-md-6 mb-4">
          <div className="card text-white bg-warning">
            <div className="card-body d-flex justify-content-between align-items-center">
              <div>
                <h5 className="card-title">Sản Phẩm Chưa Thanh Toán</h5>
                <p className="card-text">{unfinishedProducts}</p>
              </div>
              <i className="fa-solid fa-cube"></i>
            </div>
          </div>
        </div>
        <div className="col-lg-3 col-md-6 mb-4">
          <div className="card text-white bg-info">
            <div className="card-body d-flex justify-content-between align-items-center">
              <div>
                <h5 className="card-title">Sản Phẩm Đã Thanh Toán</h5>
                <p className="card-text">{completedProducts}</p>
              </div>
              <i className="fa-solid fa-cube"></i>
            </div>
          </div>
        </div>
      </div>

      {/* Date Filter Form */}
      <form onSubmit={handleSubmit} className="row mb-4 align-items-end">
        <div className="col-lg-3">
          <label htmlFor="ngay_from" className="form-label">
            Chọn Ngày Bắt Đầu:
          </label>
          <input
            type="date"
            name="ngay_from"
            className="form-control"
            id="ngay_from"
            value={startDate}
            onChange={(e) => setStartDate(e.target.value)}
          />
        </div>
        <div className="col-lg-3">
          <label htmlFor="ngay_to" className="form-label">
            Chọn Ngày Kết Thúc:
          </label>
          <input
            type="date"
            name="ngay_to"
            className="form-control"
            id="ngay_to"
            value={endDate}
            onChange={(e) => setEndDate(e.target.value)}
          />
        </div>
        <div className="col-lg-3">
          <button type="submit" className="btn btn-primary">
            Xem
          </button>
        </div>
        <div className="col-lg-3 d-flex justify-content-end align-items-center">
          <div className="d-flex">
            <input
              type="text"
              className="form-control"
              placeholder="Tìm kiếm"
              value={searchTerm}
              onChange={handleSearchChange}
            />
            <button type="submit" className="btn btn-primary ms-2">
              Tìm
            </button>
          </div>
        </div>
      </form>

      <div className="row mt-4">
        <div className="col-md-12">
          <div className="comment-container">
            <div className="card-body">
              <table className="table table-responsive" id="productTable">
                <thead className="table-primary">
                  <tr>
                    <th>Hình Ảnh</th>
                    <th>Tên Sản Phẩm</th>
                    <th>Số Lượng Bán</th>
                    <th>Đơn Giá</th>
                    <th>Doanh Thu</th>
                  </tr>
                </thead>
                <tbody>
                  {filteredRevenueData.map((item, index) => (
                    <tr key={index}>
                      <td>
                        <img
                          className="small-product-img"
                          src={item.hinh}
                          alt={item.ten_sp}
                          width="100"
                          height="100"
                        />
                      </td>
                      <td>{item.ten_sp}</td>
                      <td>
                        {new Intl.NumberFormat("vi-VN").format(item.soLuong)}
                      </td>
                      <td>
                        {new Intl.NumberFormat("vi-VN").format(item.gia_sp)} VNĐ
                      </td>
                      <td>
                        {new Intl.NumberFormat("vi-VN").format(item.thanh_tien)}{" "}
                        VNĐ
                      </td>
                    </tr>
                  ))}
                </tbody>
              </table>
            </div>
          </div>
          <div className="row mt-3">
            <div className="col-md-12 text-end">
              <h5 className="text-success fw-bold">
                Tổng Doanh Thu:{" "}
                <span className="text-primary">
                  {new Intl.NumberFormat("vi-VN").format(totalRevenue)} VNĐ
                </span>
              </h5>
              {/* Nút Xuất Hóa Đơn */}
              <button
                className="btn btn-secondary mt-2"
                onClick={handleExportInvoice}
              >
                <i className="bi bi-file-earmark-text"></i> Xuất Hóa Đơn Doanh
                Thu
              </button>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default AdminDashboard;

import React, { useEffect, useState } from "react";
import { useParams } from "react-router-dom";

const KeyKhoa = () => {
  const { userId } = useParams(); // Get userId from the URL
  const [user, setUser] = useState(null);
  // Fetch user data from the backend when the component mounts
  useEffect(() => {
    const fetchUserData = async () => {
      try {
        const token =
          sessionStorage.getItem("authToken") ||
          localStorage.getItem("authToken");

        const response = await fetch(
          `https://ducps34770.id.vn/api/admin/user/${userId}`,
          {
            method: "GET",
            headers: {
              Authorization: `Bearer ${token}`,
              "Content-Type": "application/json",
            },
          }
        );

        if (!response.ok) {
          throw new Error("Failed to fetch user data");
        }

        const data = await response.json();
        setUser(data);
      } catch (error) {
        console.error("Error fetching user data:", error);
      }
    };

    fetchUserData();
  }, [userId]);

  const handleSubmit = async (event) => {
    event.preventDefault();

    const customerName = event.target.editCustomerName.value;
    const lockReason = event.target.lockReason.value;
    const lockStatus = event.target.lockStatus.value;

    console.log({
      customerName,
      lockReason,
      lockStatus,
    });

    // Send the form data to your backend (update the user lock status)
    try {
      const token =
        sessionStorage.getItem("authToken") ||
        localStorage.getItem("authToken");

      const response = await fetch(
        `http://127.0.0.1:8000/api/admin/user/${userId}`,
        {
          method: "PATCH",
          headers: {
            Authorization: `Bearer ${token}`,
            "Content-Type": "application/json",
          },
          body: JSON.stringify({
            lock_reason: lockReason,
            lock_status: lockStatus === "locked" ? 0 : 1, // Assuming 0 = locked, 1 = active
          }),
        }
      );

      if (!response.ok) {
        throw new Error("Failed to update user lock status");
      }

      alert("Tài khoản đã được cập nhật thành công!");
    } catch (error) {
      console.error("Error updating user lock status:", error);
      alert("Có lỗi xảy ra khi cập nhật trạng thái tài khoản.");
    }
  };

  return (
    <form className="col-md-10 p-4" onSubmit={handleSubmit}>
      <div className="comment">
        <div className="row mb-4">
          <div className="container mt-4">
            <div className="card shadow" style={{ borderRadius: "10px" }}>
              <div className="card-header">
                <h4 className="mb-0">Khóa Tài Khoản Khách Hàng</h4>
              </div>
              <div className="card-body">
                <input type="hidden" name="editCustomerName" />
                {user ? (
                  <>
                    <div className="mb-3">
                      <label htmlFor="editCustomerName" className="form-label">
                        Tên Khách Hàng
                      </label>
                      <input
                        type="text"
                        className="form-control"
                        id="editCustomerName"
                        name="editCustomerName"
                        readOnly
                        value={user.name}
                      />
                    </div>
                    <div className="mb-3">
                      <label htmlFor="lockReason" className="form-label">
                        Lý Do Khóa
                      </label>
                      <select
                        className="form-select"
                        id="lockReason"
                        name="lockReason"
                        required
                      >
                        <option value="">Chọn lý do</option>
                        <option value="spam">Spam hoặc hành vi đáng ngờ</option>
                        <option value="violation">Vi phạm điều khoản</option>
                        <option value="request">Yêu cầu từ khách hàng</option>
                        <option value="other">Khác</option>
                      </select>
                    </div>
                    <div className="mb-3">
                      <label htmlFor="lockStatus" className="form-label">
                        Trạng Thái Tài Khoản
                      </label>
                      <select
                        className="form-select"
                        id="lockStatus"
                        name="lockStatus"
                        required
                      >
                        <option value="locked">Khóa Tài Khoản</option>
                        <option value="active">Kích hoạt lại</option>
                      </select>
                    </div>
                    <button type="submit" className="btn btn-warning">
                      Xác Nhận Khóa
                    </button>
                    <a href="userAdmin.html">
                      <button type="button" className="btn btn-secondary">
                        Hủy
                      </button>
                    </a>
                  </>
                ) : (
                  <p>Đang tải dữ liệu...</p>
                )}
              </div>
            </div>
          </div>
        </div>
      </div>
    </form>
  );
};

export default KeyKhoa;

import React, { useState, useEffect } from "react";
import { useParams, useNavigate } from "react-router-dom";

const EditUser = () => {
  const { id } = useParams(); // Lấy ID người dùng từ URL
  const [user, setUser] = useState(null);
  const [formData, setFormData] = useState({
    name: "",
    email: "",
    phone: "",
    role: "",
    status: "",
    address: "",
    image: "", // Đường dẫn ảnh cũ
    password: "", // Mật khẩu
  });
  const [imagePreview, setImagePreview] = useState(null); // Hiển thị ảnh preview
  const navigate = useNavigate();
  const [loading, setLoading] = useState(false);

  // Fetch dữ liệu người dùng
  useEffect(() => {
    const fetchUserData = async () => {
      const token =
        sessionStorage.getItem("authToken") ||
        localStorage.getItem("authToken");

      try {
        const response = await fetch(
          `https://ducps34770.id.vn/api/admin/user/${id}`,
          {
            method: "GET",
            headers: {
              Authorization: `Bearer ${token}`,
              "Content-Type": "application/json",
            },
          }
        );

        if (!response.ok) {
          throw new Error("Error fetching user data");
        }

        const data = await response.json();
        setUser(data);
        setFormData({
          name: data.name,
          email: data.email,
          phone: data.phone,
          role: data.role,
          status: data.status,
          address: data.address || "",
          image: data.hinh || "", // Cập nhật đường dẫn ảnh cũ
          password: "", // Đặt mật khẩu trống ban đầu
        });
        setImagePreview(data.image); // Hiển thị ảnh cũ
      } catch (error) {
        console.error("Error fetching user:", error);
      }
    };

    fetchUserData();
  }, [id]);

  // Xử lý thay đổi input
  const handleChange = (e) => {
    const { name, value } = e.target;
    setFormData({ ...formData, [name]: value });
  };

  // Xử lý chọn ảnh
  const handleImageChange = (e) => {
    const file = e.target.files[0];
    if (file) {
      const reader = new FileReader();
      reader.onloadend = () => {
        setImagePreview(reader.result); // Hiển thị preview từ file
      };
      reader.readAsDataURL(file);
    }
  };

  // Gửi dữ liệu
  const handleSubmit = async (e) => {
    setLoading(true); // Hiển thị loading
    e.preventDefault();
    const token =
      sessionStorage.getItem("authToken") || localStorage.getItem("authToken");

    // Tạo đối tượng FormData để gửi lên server
    const formDataToSend = new FormData();
    formDataToSend.append("name", formData.name);
    formDataToSend.append("email", formData.email);
    formDataToSend.append("phone", formData.phone);
    formDataToSend.append("role", formData.role);
    formDataToSend.append("status", formData.status);
    formDataToSend.append("address", formData.address);

    // Nếu có mật khẩu mới, thêm vào FormData
    if (formData.password) {
      formDataToSend.append("password", formData.password);
    }

    // Nếu có hình ảnh mới, thêm vào FormData
    if (imagePreview) {
      const fileInput = document.querySelector('input[name="hinh"]');
      if (fileInput && fileInput.files[0]) {
        formDataToSend.append("hinh", fileInput.files[0]);
      }
    } else {
      // Nếu không thay đổi hình, gửi lại hình cũ
      formDataToSend.append("hinhcu", formData.image);
    }

    try {
      const response = await fetch(
        `https://ducps34770.id.vn/api/admin/user/${id}`,
        {
          method: "PUT",
          headers: {
            Authorization: `Bearer ${token}`,
          },
          body: formDataToSend, // Gửi FormData thay vì JSON
        }
      );

      if (!response.ok) {
        throw new Error("Error updating user");
      }

      alert("Người dùng đã được cập nhật thành công!");
      navigate("/userdashboard");
    } catch (error) {
      console.error("Error updating user:", error);
      alert("Có lỗi xảy ra khi cập nhật người dùng.");
    } finally {
      setLoading(false); // Tắt loading
    }
  };

  if (!user) {
    return <p>Loading...</p>;
  }

  return (
    <div className="col-md-10 p-4">
      <div className="card shadow" style={{ borderRadius: "10px" }}>
        <div
          className="card-header text-white"
          style={{
            backgroundColor: "#ffcc00",
            borderTopLeftRadius: "10px",
            borderTopRightRadius: "10px",
          }}
        >
          <h4 className="font-weight-bold mb-0">Chỉnh sửa người dùng</h4>
        </div>
        <div className="card-body">
          <form onSubmit={handleSubmit}>
            <div className="row">
              <div className="col-md-6 mb-3">
                <label htmlFor="userImage" className="form-label">
                  Hình Ảnh
                </label>
                <input
                  type="file"
                  className="form-control"
                  name="hinh"
                  onChange={handleImageChange}
                />
                {imagePreview && (
                  <img
                    src={imagePreview}
                    alt="Preview"
                    className="img-thumbnail mt-2"
                    style={{ width: "150px", height: "150px" }}
                  />
                )}
              </div>
              <div className="col-md-6 mb-3">
                <label htmlFor="userName" className="form-label">
                  Tên Người Dùng
                </label>
                <input
                  type="text"
                  className="form-control"
                  name="name"
                  value={formData.name}
                  onChange={handleChange}
                  required
                />
              </div>
            </div>
            <div className="row">
              <div className="col-md-6 mb-3">
                <label htmlFor="email" className="form-label">
                  Email
                </label>
                <input
                  type="email"
                  className="form-control"
                  name="email"
                  value={formData.email}
                  onChange={handleChange}
                  required
                  readOnly
                />
              </div>
              <div className="col-md-6 mb-3">
                <label htmlFor="phone" className="form-label">
                  Số Điện Thoại
                </label>
                <input
                  type="tel"
                  className="form-control"
                  name="phone"
                  value={formData.phone}
                  onChange={handleChange}
                  required
                  readOnly
                />
              </div>
            </div>
            <div className="mb-3">
              <label htmlFor="address" className="form-label">
                Địa Chỉ
              </label>
              <input
                type="text"
                className="form-control"
                name="address"
                value={formData.address}
                onChange={handleChange}
                required
              />
            </div>
            <div className="mb-3">
              <label htmlFor="role" className="form-label">
                Vai Trò
              </label>
              <select
                className="form-select"
                name="role"
                value={formData.role}
                onChange={handleChange}
                required
              >
                <option value="1">Người dùng</option>
                <option value="2">Nhân viên</option>
                <option value="3">Shipper</option>
              </select>
            </div>
            <div className="mb-3">
              <label htmlFor="status" className="form-label">
                Trạng Thái
              </label>
              <select
                className="form-select"
                name="status"
                value={formData.status}
                onChange={handleChange}
                required
              >
                <option value="0">Hoạt động</option>
                <option value="1">Không hoạt động</option>
              </select>
            </div>
            <div className="mb-3">
              <label htmlFor="password" className="form-label">
                Mật Khẩu (Nhập nếu muốn thay đổi)
              </label>
              <input
                type="password"
                className="form-control"
                name="password"
                value={formData.password}
                onChange={handleChange}
              />
            </div>
            <button
              type="submit"
              className="btn btn-warning w-100"
              disabled={loading}
            >
              {loading ? "Đang xử lý..." : "Cập nhật thông tin"}
            </button>
          </form>
        </div>
      </div>
    </div>
  );
};

export default EditUser;

import React from "react";
import { useNavigate } from "react-router-dom"; // Sử dụng useNavigate để điều hướng
import "../../assets/css/style.css";
import "bootstrap/dist/css/bootstrap.min.css";
import "bootstrap-icons/font/bootstrap-icons.css";
import "bootstrap/dist/js/bootstrap.bundle.min.js";

const DeleteBest = () => {
  const navigate = useNavigate();

  // Hàm xử lý khi nhấn nút Xóa
  const handleDelete = () => {
    // Thêm logic xóa tài khoản tại đây (gọi API hoặc thực hiện hành động khác)
    console.log("Tài khoản đã bị xóa");
    navigate("/UserAdmin"); // Điều hướng quay lại trang quản lý tài khoản sau khi xóa
  };

  // Hàm xử lý khi nhấn nút Hủy
  const handleCancel = () => {
    navigate("/UserAdmin"); // Điều hướng quay lại trang quản lý tài khoản nếu hủy
  };

  return (
    <form className="col-md-10 p-4">
      <div className="comment">
        <div className="row mb-4">
          <div className="container mt-4">
            <div className="card shadow" style={{ borderRadius: "10px" }}>
              <div
                className="card-header text-white"
                style={{
                  backgroundColor: "#ffcc00",
                  borderTopLeftRadius: "10px",
                  borderTopRightRadius: "10px",
                }}
              >
                <h4 className="font-weight-bold mb-0">Xóa tài khoản</h4>
              </div>
              <div className="card-body">
                <p>Bạn có chắc chắn muốn xóa tài khoản này không?</p>
                <button
                  type="button"
                  className="btn btn-sm btn-danger"
                  onClick={handleDelete}
                >
                  Xóa
                </button>
                <button
                  type="button"
                  className="btn btn-secondary"
                  onClick={handleCancel}
                >
                  Hủy
                </button>
              </div>
            </div>
          </div>
        </div>
      </div>
    </form>
  );
};

export default DeleteBest;

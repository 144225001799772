import React, { useState } from "react";

import "bootstrap/dist/css/bootstrap.min.css";

import "bootstrap-icons/font/bootstrap-icons.css";

import "bootstrap/dist/js/bootstrap.bundle.min.js";

import axios from "axios";

import "../../assets/css/style.css";

const AddDanhMuc = () => {
  const [order, setOrder] = useState("");

  const [categoryName, setCategoryName] = useState("");

  const [image, setImage] = useState(null); // Store image file

  const [loading, setLoading] = useState(false);

  const [message, setMessage] = useState(""); // State to store message

  const [messageType, setMessageType] = useState(""); // Message type (success/error)

  const handleAdd = async (e) => {
    e.preventDefault();

    setLoading(true);

    // Create FormData to send the image file

    const formData = new FormData();

    formData.append("thu_tu", order);

    formData.append("loai", categoryName);

    formData.append("hinh", image); // Send image file

    try {
      const response = await axios.post(
        "https://ducps34770.id.vn/api/admin/danhmuc",

        formData, // Send FormData

        {
          headers: {
            "Content-Type": "multipart/form-data", // Set content type to multipart

            Authorization: `Bearer ${sessionStorage.getItem("authToken")}`, // Add token to headers
          },
        }
      );

      if (response.status === 200) {
        window.alert("Danh mục đã được thêm thành công!");

        window.location.href = "/QuanlyDM"; // Redirect after successful addition
      }
    } catch (error) {
      console.error("Lỗi khi thêm danh mục:", error);

      setMessage("Có lỗi xảy ra khi thêm danh mục.");

      setMessageType("error"); // Set error message type
    } finally {
      setLoading(false);
    }
  };

  const handleImageChange = (e) => {
    const file = e.target.files[0];

    if (file) {
      setImage(file); // Store image file in state
    }
  };

  return (
    <form className="col-md-10 p-4" onSubmit={handleAdd}>
      <div className="comment">
        <div className="row mb-4">
          <div className="container mt-4">
            <div className="card shadow" style={{ borderRadius: "10px" }}>
              <div
                className="card-header text-white"
                style={{
                  backgroundColor: "#ffcc00",

                  borderTopLeftRadius: "10px",

                  borderTopRightRadius: "10px",
                }}
              >
                <h4 className="font-weight-bold mb-0">Thêm danh mục</h4>
              </div>

              <div className="card-body">
                {/* Display message */}

                {message && (
                  <div
                    className={`alert ${
                      messageType === "success"
                        ? "alert-success"
                        : "alert-danger"
                    }`}
                    role="alert"
                  >
                    {message}
                  </div>
                )}

                <div className="mb-3">
                  <label htmlFor="order" className="form-label">
                    Thứ tự
                  </label>

                  <input
                    type="text"
                    className="form-control"
                    id="order"
                    placeholder="Nhập thứ tự"
                    value={order}
                    onChange={(e) => setOrder(e.target.value)}
                  />
                </div>

                <div className="mb-3">
                  <label htmlFor="categoryName" className="form-label">
                    Tên danh mục
                  </label>

                  <input
                    type="text"
                    className="form-control"
                    id="categoryName"
                    placeholder="Nhập loại danh mục"
                    value={categoryName}
                    onChange={(e) => setCategoryName(e.target.value)}
                  />
                </div>

                <div className="mb-3">
                  <label htmlFor="categoryImage" className="form-label">
                    Hình ảnh
                  </label>

                  <input
                    type="file"
                    className="form-control"
                    id="categoryImage"
                    onChange={handleImageChange}
                  />
                </div>

                <div className="modal-footer">
                  <button
                    type="button"
                    className="btn btn-secondary"
                    onClick={() => (window.location.href = "/QuanlyDM")}
                  >
                    Đóng
                  </button>

                  <button
                    type="submit"
                    className="btn btn-success"
                    disabled={loading}
                  >
                    {loading ? "Đang thêm..." : "Thêm"}
                  </button>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </form>
  );
};

export default AddDanhMuc;

import React, { useState, useEffect } from "react";
import "bootstrap/dist/css/bootstrap.min.css";
import "../../assets/css/style.css";
import { Link } from "react-router-dom";
import axios from "axios";

// Cấu hình axios để thêm token vào header
axios.interceptors.request.use((config) => {
  const token = sessionStorage.getItem("authToken"); // Lấy token từ sessionStorage
  if (token) {
    config.headers.Authorization = `Bearer ${token}`; // Thêm token vào header
  }
  return config;
});

// Component for the Order Table
const OrderTable = ({ orders }) => {
  const handleViewOrder = (orderId) => {
    // Lưu trạng thái đơn hàng vào localStorage
    const order = orders.find((order) => order.id_dh === orderId);
    localStorage.setItem("currentOrder", JSON.stringify(order));
  };

  return (
    <div className="comment-container">
      <div className="card-header">
        <div className="card-body">
          <table className="table table-hover table-responsive">
            <thead className="table-primary">
              <tr>
                <th>#</th>
                <th>Tên Khách Hàng</th>
                <th>Mã Đơn</th>
                <th>SDT</th>
                <th>Địa chỉ</th>
                <th>Thanh Toán</th>
                <th>Trạng Thái</th>
                <th>Hình Thức </th>
                <th>Ngày Đặt</th>
                <th>Ghi Chú</th>
                <th>Hành Động</th>
              </tr>
            </thead>
            <tbody>
              {orders.map((order) => (
                <tr key={order.id_dh}>
                  <td>{order.id_dh}</td>
                  <td>{order.tenNguoiNhan}</td>
                  <td>{order.maDon}</td>
                  <td>{order.soDienThoai}</td>
                  <td
                    className="table-cell"
                    style={{
                      marginTop: "200px",
                      maxWidth: "130px",
                      whiteSpace: "nowrap",
                      overflow: "hidden",
                      textOverflow: "ellipsis",
                      padding: "8px",
                    }}
                  >
                    {order.diaChi}
                  </td>
                  <td>
                    <span
                      className={`badge ${
                        order.thanhToan === 0 ? "bg-danger" : "bg-success"
                      }`}
                    >
                      {order.thanhToan === 0
                        ? "Chưa thanh toán"
                        : "Đã thanh toán"}
                    </span>
                  </td>
                  <td>
                    <span
                      className={`badge ${
                        order.trangThai === 1 ? "bg-success" : "bg-warning"
                      }`}
                    >
                      {order.trangThai === 1 ? "Đã Xác Nhận" : "Chưa Xác Nhận"}
                    </span>
                  </td>
                  <td>{order.Hinh_thuc === 0 ? "COD" : "VNPAY"}</td>
                  <td>{new Date(order.ngayMua).toLocaleDateString()}</td>
                  <td
                    style={{
                      maxWidth: "150px",
                      overflow: "hidden",
                      textOverflow: "ellipsis",
                      whiteSpace: "nowrap",
                    }}
                  >
                    {order.ghiChu}
                  </td>

                  <td>
                    <span className="col-lg-4 d-flex align-items-end">
                      <Link
                        to={`/Chitietdonhang/${order.id_dh}`} // Truyền id đơn hàng vào đường dẫn
                        className="btn btn-sm btn-primary"
                        onClick={() => handleViewOrder(order.id_dh)} // Lưu đơn hàng vào localStorage khi bấm Xem
                      >
                        Xem
                      </Link>
                    </span>
                  </td>
                </tr>
              ))}
            </tbody>
          </table>
        </div>
      </div>
    </div>
  );
};

// Component for the Date Filter Form and Search
const DateFilterForm = ({ onFilter, onSearch }) => {
  const [ngayFrom, setNgayFrom] = useState("");
  const [ngayTo, setNgayTo] = useState("");
  const [searchTerm, setSearchTerm] = useState("");

  const handleSubmit = (e) => {
    e.preventDefault();
    onFilter({ ngayFrom, ngayTo });
  };

  const handleSearchChange = (e) => {
    setSearchTerm(e.target.value);
    onSearch(e.target.value); // Trigger search filter
  };

  return (
    <form onSubmit={handleSubmit} className="row mb-4 align">
      <div className="col-lg-4">
        <label htmlFor="ngay_from" className="form-label">
          Chọn Ngày Bắt Đầu:
        </label>
        <input
          type="date"
          name="ngay_from"
          className="form-control"
          id="ngay_from"
          value={ngayFrom}
          onChange={(e) => setNgayFrom(e.target.value)}
        />
      </div>
      <div className="col-lg-4">
        <label htmlFor="ngay_to" className="form-label">
          Chọn Ngày Kết Thúc:
        </label>
        <div className="d-flex align-items-end">
          <input
            type="date"
            name="ngay_to"
            className="form-control"
            id="ngay_to"
            value={ngayTo}
            onChange={(e) => setNgayTo(e.target.value)}
          />
          <button type="submit" className="btn btn-primary ms-2">
            Xem
          </button>
        </div>
      </div>

      <div className="col-lg-4 d-flex justify-content-end align-items-end">
        <div className="d-flex">
          <input
            type="text"
            className="form-control"
            placeholder="Tìm kiếm"
            value={searchTerm}
            onChange={handleSearchChange}
          />
          <button type="submit" className="btn btn-primary ms-2">
            Tìm
          </button>
        </div>
      </div>
    </form>
  );
};

// Main component for the order management page
const Cart = () => {
  const [filteredOrders, setFilteredOrders] = useState([]);
  const [allOrders, setAllOrders] = useState([]);
  const [donHangs, setDonHangs] = useState([]);
  const [filteredDonHangs, setFilteredDonHangs] = useState([]);

  useEffect(() => {
    // Fetch data from the API when the component mounts
    axios
      .get("https://ducps34770.id.vn/api/admin/donhang")
      .then((response) => {
        setAllOrders(response.data); // Save the original orders
        setFilteredOrders(response.data); // Update state with fetched orders
        setDonHangs(response.data); // Save the original orders for filtering by other conditions
        setFilteredDonHangs(response.data); // Initially show all orders
      })
      .catch((error) => {
        console.error("There was an error fetching the orders!", error);
      });
  }, []);

  // Combined filtering logic for orders based on different criteria
  const handleFilter = (filterType) => {
    let filteredData;
    switch (filterType) {
      case "all":
        filteredData = allOrders;
        break;
      case "pending":
        filteredData = allOrders.filter((order) => order.trangThai === 0); // Pending orders
        break;
      case "cancel":
        filteredData = allOrders.filter((order) => order.trangThai === 2); // Canceled orders
        break;
      case "unpaid":
        filteredData = allOrders.filter((order) => order.thanhToan === 0); // Unpaid orders
        break;
      default:
        filteredData = allOrders;
    }
    setFilteredOrders(filteredData); // Update the filtered orders state
  };

  // Filtering orders by date range
  const handleDateFilter = ({ ngayFrom, ngayTo }) => {
    const filtered = allOrders.filter((order) => {
      const orderDate = new Date(order.ngayMua);
      const fromDate = new Date(ngayFrom);
      const toDate = new Date(ngayTo);

      return (
        (ngayFrom ? orderDate >= fromDate : true) &&
        (ngayTo ? orderDate <= toDate : true)
      );
    });
    setFilteredOrders(filtered);
  };

  // Search filter based on a search term
  const handleSearch = (searchTerm) => {
    if (searchTerm.trim() === "") {
      setFilteredOrders(allOrders); // Show all orders if no search term
    } else {
      const filtered = allOrders.filter(
        (order) =>
          order.tenNguoiNhan.toLowerCase().includes(searchTerm.toLowerCase()) ||
          order.maDon.toLowerCase().includes(searchTerm.toLowerCase()) ||
          order.soDienThoai.toString().includes(searchTerm)
      );
      setFilteredOrders(filtered);
    }
  };

  return (
    <div className="col-md-10 p-4">
      <div className="row mb-4">
        {/* Statistics cards for orders */}
        <div className="col-lg-3 col-md-6 mb-4">
          <div className="card text-white btn-orange">
            <div className="card-body d-flex justify-content-between align-items-center">
              <div>
                <h5 className="card-title">Tổng Đơn Hàng</h5>
                <p className="card-text">{donHangs.length} Đơn Hàng</p>
              </div>
              <i className="fa-solid fa-money-bill-wave"></i>
            </div>
            <div className="row mb-2">
              <div className="mt-2 text-end">
                <button
                  type="button"
                  className="btn btn-light btn-sm"
                  onClick={() => handleFilter("all")}
                >
                  Xem Đơn Hàng
                </button>
              </div>
            </div>
          </div>
        </div>

        <div className="col-lg-3 col-md-6 mb-4">
          <div className="card text-white bg-success">
            <div className="card-body d-flex justify-content-between align-items-center">
              <div>
                <h5 className="card-title">Đơn Chưa Xác Nhận</h5>
                <p className="card-text">
                  {
                    filteredDonHangs.filter(
                      (donHang) => donHang.trangThai === 1
                    ).length
                  }{" "}
                  Đơn Hàng
                </p>
              </div>
              <i className="fa-solid fa-clock"></i>
            </div>
            <div className="row mb-2">
              <div className="mt-2 text-end">
                <button
                  type="button"
                  className="btn btn-light btn-sm"
                  onClick={() => handleFilter("pending")}
                >
                  Xem Đơn Hàng
                </button>
              </div>
            </div>
          </div>
        </div>

        {/* Unpaid orders */}
        <div className="col-lg-3 col-md-6 mb-4">
          <div className="card text-white bg-warning">
            <div className="card-body d-flex justify-content-between align-items-center">
              <div>
                <h5 className="card-title">Đơn Chưa Thanh Toán</h5>
                <p className="card-text">
                  {
                    filteredDonHangs.filter(
                      (donHang) => donHang.thanhToan === 0
                    ).length
                  }{" "}
                  Đơn Hàng
                </p>
              </div>
              <i className="fa-solid fa-credit-card"></i>
            </div>
            <div className="row mb-2">
              <div className="mt-2 text-end">
                <button
                  type="button"
                  className="btn btn-light btn-sm"
                  onClick={() => handleFilter("unpaid")}
                >
                  Xem Đơn Hàng
                </button>
              </div>
            </div>
          </div>
        </div>

        {/* Canceled orders */}
        <div className="col-lg-3 col-md-6 mb-4">
          <div className="card text-white bg-danger">
            <div className="card-body d-flex justify-content-between align-items-center">
              <div>
                <h5 className="card-title">Đơn Hủy</h5>
                <p className="card-text">
                  {
                    filteredDonHangs.filter(
                      (donHang) => donHang.trangThai === 0
                    ).length
                  }{" "}
                  Đơn Hàng
                </p>
              </div>
              <i className="fa-solid fa-xmark"></i>
            </div>
            <div className="row mb-2">
              <div className="mt-2 text-end">
                <button
                  type="button"
                  className="btn btn-light btn-sm"
                  onClick={() => handleFilter("cancel")}
                >
                  Xem Đơn Hàng
                </button>
              </div>
            </div>
          </div>
        </div>
      </div>

      {/* Filter section */}
      <DateFilterForm onFilter={handleDateFilter} onSearch={handleSearch} />

      {/* Table displaying the filtered orders */}
      <OrderTable orders={filteredOrders} />
    </div>
  );
};

export default Cart;

import React, { useState, useEffect } from "react";
import "bootstrap/dist/css/bootstrap.min.css";
import "bootstrap-icons/font/bootstrap-icons.css";
import "bootstrap/dist/js/bootstrap.bundle.min.js";
import { Link } from "react-router-dom";
import "../../assets/css/style.css";

const UserAdmin = () => {
  const [loading, setLoading] = useState(true);
  const [userInfo, setUserInfo] = useState(null); // Thêm state cho thông tin người dùng đã đăng nhập

  useEffect(() => {
    // Lấy thông tin người dùng từ localStorage
    const storedUserInfo = JSON.parse(sessionStorage.getItem("userInfo"));
    if (storedUserInfo) {
      setUserInfo(storedUserInfo); // Lưu thông tin người dùng vào state
      setLoading(false);
    } else {
      console.error("Không tìm thấy thông tin người dùng.");
      setLoading(false);
    }
  }, []); // Chạy chỉ 1 lần khi component được mount

  return (
    <div className="col-md-10 p-4">
      <div className="row mb-4">
        <div className="col-lg-3 col-md-6 mb-3">
          <div
            className="card text-white bg-success"
            style={{ cursor: "pointer" }}
          >
            <div className="card-body">
              <h5 className="card-title">Quản trị viên</h5>
              <p className="card-text">2 Quản trị viên</p>
            </div>
          </div>
        </div>
        <div className="col-lg-3 col-md-6 mb-3">
          <div
            className="card text-white bg-warning"
            style={{ cursor: "pointer" }}
          >
            <div className="card-body">
              <h5 className="card-title">Admin</h5>
              <p className="card-text">2 Admin</p>
            </div>
          </div>
        </div>
        <div className="card-header d-flex justify-content-between align-items-center">
          <h5>Quản Lý Người Dùng</h5>
          <Link to="/addadmin">
            <button className="btn btn-success">Thêm Admin</button>
          </Link>
        </div>
      </div>

      <div className="comment-container">
        <div className="comment">
          <div className="card-body">
            {loading ? (
              <p>Đang tải dữ liệu...</p>
            ) : userInfo ? (
              <table className="table table-hover table-responsive">
                <thead className="table-primary">
                  <tr>
                    <th>ID</th>

                    <th>Hình Ảnh</th>
                    <th>Tên</th>
                    <th>Email</th>
                    <th>Số Điện Thoại</th>
                    <th>Địa Chỉ</th>
                    <th>Vai Trò</th>
                    <th>Thao Tác</th>
                  </tr>
                </thead>
                <tbody>
                  <tr>
                    <td>{userInfo.id}</td>
                    <td>
                      <img
                        src={userInfo.image || "./assets/images/user.jpg"}
                        width="40"
                        height="40"
                        className="rounded-circle me-2"
                        alt="User"
                      />
                    </td>
                    <td>{userInfo.name}</td>
                    <td>{userInfo.email}</td>
                    <td>{userInfo.phone}</td>
                    <td>{userInfo.address}</td>
                    <td>{userInfo.role === 0 ? "Admin" : "User"}</td>
                    <td>
                      <Link
                        to={`/editadmin/${userInfo.id}`}
                        className="btn btn-sm btn-primary"
                      >
                        Chỉnh Sửa
                      </Link>
                      <Link to="/DeleteBest" className="btn btn-sm btn-danger">
                        Xóa
                      </Link>
                    </td>
                  </tr>
                </tbody>
              </table>
            ) : (
              <p>Không có thông tin người dùng.</p>
            )}
          </div>
        </div>
      </div>
    </div>
  );
};

export default UserAdmin;
